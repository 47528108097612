import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import React from 'react'

const PasswordBlock = ({
    isFormFieldValid,
    handleChange,
    password,
    getFormErrorMessage
}) => {
  return (
    <div className='mb-4 justify-content-center'>
        <p className="font-bold mb-2">mot de passe</p>
        <InputText placeholder='mot de passe'
        className={classNames({ 'p-invalid':  isFormFieldValid('password')})}
        onChange={handleChange('password')}
        value={password}
        />
        {getFormErrorMessage('password')}
    </div>
  )
}

export default PasswordBlock