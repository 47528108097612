import React,{ useEffect, useState, memo } from 'react'
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { v4 as uuidv4 } from 'uuid';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Formik, Field } from 'formik';
import { classNames } from 'primereact/utils';
import * as Yup from 'yup'
import { VariantService } from '../../../service/VariantService';


const VariantsBlock = ({options,variants,setVariants,productId}) => {
    const [selectedVariant, setSelectedVariant] = useState(null);

    // STRUCTURE VARIANT ARRAY
    const variantsArray=()=>{
        const variants = [];

        const generateVariant = (currentOption, currentVariant) => {
            if (currentOption === options.length) {
            variants.push(currentVariant);
            return;
            }
        
            for (let i = 0; i < options[currentOption].values.length; i++) {
            const newVariant = { ...currentVariant };
            switch (currentOption) {
                case 0:
                newVariant.option1 = { optionId: '', value: options[currentOption].values[i].name };
                break;
                case 1:
                newVariant.option2 = { optionId: '', value: options[currentOption].values[i].name };
                break;
                case 2:
                newVariant.option3 = { optionId: '', value: options[currentOption].values[i].name };
                break;
            }
                generateVariant(currentOption + 1, newVariant);
            }

        };
        
        generateVariant(0, {
            id: 0,
            option1: null,
            option2: null,
            option3: null,
            reference: 'null',
            quantityStock: 0,
            minOrderQuantity: 1,
            position:0,
        });
        
        pushIdToVariantrs(variants);
    }
    
    // PUSH UUID TO VARIANTS
    const pushIdToVariantrs=(vrts) => {
        let newArr=[...vrts]
        for(let variant in vrts){
           newArr[variant].id = uuidv4()
           newArr[variant].position = Number(variant)
        }
        setVariants(newArr)
    }

    useEffect(()=>{
        variantsArray()
    },[options])

    // ROW SELECT
    const onRowSelect=(e)=>{
        setSelectedVariant(e.value)
    }
    

    const footer = `nombre total de variantes est ${variants.length}`;

    const valuesBody=({option1,option2,option3})=>{
        
        const renderOptionValue=(option)=>{
            if(option !== null){
                const checkHex = option.value.charAt(0) 
                if(checkHex === '#'){
                    return <div style={{backgroundColor:option.value,height:15,width:15,borderRadius:15/2}} />
                }else{
                    return <div> 
                            <p className='font-medium'>{`${option.value}`}</p>
                        </div>
                }
            }else return 
        }

        return(
            <div className='flex align-items-center'>
                {renderOptionValue(option1)}
                {option2 !== null && <div className='px-2'> <p>{`/`}</p> </div>}
                {renderOptionValue(option2)}
                {(option3 !== null && option2 !== null) && <div className='px-2'> <p>{`/`}</p> </div>}
                {renderOptionValue(option3)}
            </div>
        )
    } 

    const textEditor = (options) => {
        return <InputText type="text" 
        value={options.value} className='p-inputtext-sm w-full'
        onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const numberEditor = (options,type) => {
        return <InputText type="number" keyfilter={type === "price" ? "pnum" : "hex"}
        value={options.value} className='p-inputtext-sm w-full'
        onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    // EDIT ROW
    const onRowEditComplete = (e) => {
        let _variants = [...variants];
        let { newData, index } = e;

        if(newData.quantityStock === "") newData.quantityStock = 0
        if(newData.minOrderQuantity === "") newData.minOrderQuantity = 1

        _variants[index] = newData;
        setVariants(_variants);  
    };


  return (
    <>
    {
        (options.length !== 0 && variants.length !== 0)
        && <div className='card relative'>
            <p className='font-bold'>Variantes</p>
            
            <div className='flex my-4'>
                <p style={{color:'#6D7175'}} className='font-normal text-md'>{'Sélectionner'}</p>
                <div onClick={()=>setSelectedVariant(variants)} className='ml-3 ' style={{cursor:'pointer'}} ><p className='filterBar font-normal text-md'>{'Toutes'}</p></div>
               <div onClick={()=>setSelectedVariant([])} className='ml-3 ' style={{cursor:'pointer'}}><p className='ml-3 filterBar font-normal text-md'>{'Aucune'}</p></div>
               { (selectedVariant && selectedVariant.length !== 0) && <BulkEdit selectedVariant={selectedVariant} setVariants={setVariants} variants={variants} /> }
            </div>

            
             <DataTable
             rowHover
             stripedRows
             editMode="row"
             onRowEditComplete={onRowEditComplete}
             scrollable
             selection={selectedVariant} 
             selectionMode="checkbox"
             
             onSelectionChange={onRowSelect} 
             dataKey={'_id'}
             scrollHeight="400px"
             value={variants}
             footer={footer} 
             size="small"
             responsiveLayout="scroll">
                    <Column frozen selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                    <Column headerStyle={{fontWeight: 300}} header="variant" body={valuesBody} ></Column>
                    <Column style={{minWidth:'120px'}} headerStyle={{fontWeight: 300}} editor={(options) => textEditor(options)} field="reference" header="reference" ></Column>
                    <Column style={{minWidth:'120px'}} headerStyle={{fontWeight: 300}} editor={(options) => numberEditor(options,'quantity')} field="quantityStock" header="stock"></Column>
                    <Column style={{minWidth:'120px'}} headerStyle={{fontWeight: 300}} editor={(options) => numberEditor(options,'quantity')} field="minOrderQuantity" header="qte minimal" ></Column>
                    {/* <Column style={{minWidth:'120px'}} headerStyle={{fontWeight: 300}} editor={(options) => numberEditor(options,'price')} field="priceProduct" header="prix original" ></Column>
                    <Column style={{minWidth:'120px'}} headerStyle={{fontWeight: 300}} editor={(options) => numberEditor(options,'price')} field="discountPrice" header="prix de solde" ></Column> */}
                    <Column rowEditor style={{ minWidth: '6rem' }} alignFrozen="right" frozen bodyStyle={{ textAlign: 'center' }}></Column>
                </DataTable>
        </div>
    }
    </>
   
  )
}

export default memo(VariantsBlock)

const BulkEdit=({selectedVariant,setVariants,variants})=>{

    const [visible, setVisible] = useState(false);

    const initialValues = {
        quantityStock: 0,
        minOrderQuantity: 1,
    }

    const validationSchema = Yup.object().shape({
        quantityStock: Yup.number().required('champ obligatoire'),
        minOrderQuantity: Yup.number().required('champ obligatoire'),
    })

    //create products
    const onSubmit=(values)=>{
        editSelectedVariants(values)
    }

    // BULK EDIT TYPE CREATE
    const editSelectedVariants=(values)=>{
        let _variants = [...variants];
        for(let i in _variants){
            for(let j in selectedVariant){
                if(_variants[i].id === selectedVariant[j].id){
                    _variants[i].quantityStock = values.quantityStock
                    _variants[i].minOrderQuantity = values.minOrderQuantity
                }
            }
          setVisible(false)
        }
         setVariants(_variants);
    }


    return (
        <>
        <div onClick={()=>setVisible(true)} className='ml-3 ' style={{cursor:'pointer'}}><p className='ml-3 filterBar font-normal text-md'>
            {`Modifier ${selectedVariant.length} variants`}
        </p></div>
        <Dialog 
        header={`Modifier ${selectedVariant.length} variants`} 
        draggable={false}
        visible={visible} 
        style={{ width: '50vw' }} 
        onHide={() => setVisible(false)} >
           
           
        <Formik 
        enableReinitialize={true}
        initialValues={initialValues} 
        validationSchema={validationSchema}
        onSubmit={onSubmit} >
        {({ handleChange, 
            handleSubmit,
            isSubmitting,
            handleBlur,
            values, 
            errors, 
            touched, 
            setFieldValue 
        }) =>
        {
            const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
            const getFormErrorMessage = (name) => {
                return isFormFieldValid(name) && <small className="p-error mt-2">{errors[name]}</small>;
            };
            return(
                <div className='grid'>

                    <div className='col-12'>
                    <div className='w-full flex flex-column'>
                        <p className="capitalize mb-2">Quantité stock</p>
                        <InputText 
                        keyfilter="hex"
                        value={values.quantityStock}
                        onChange={handleChange('quantityStock')}
                        placeholder='quantité'
                        onBlur={handleBlur('quantityStock')}
                        className={classNames({ 'p-invalid':  isFormFieldValid('quantityStock')})}
                        />
                        {getFormErrorMessage('quantityStock')}
                    </div>
                    </div>

                    <div className='col-12'>
                    <div className='w-full flex flex-column'>
                        <p className="capitalize mb-2">Quantité minimal</p>
                        <InputText keyfilter="hex"
                        value={values.minOrderQuantity}
                        onChange={handleChange('minOrderQuantity') } 
                        placeholder='quantité minimal'
                        onBlur={handleBlur('minOrderQuantity')}
                        className={classNames({ 'p-invalid':  isFormFieldValid('minOrderQuantity')})}
                        />
                        {getFormErrorMessage('minOrderQuantity')}
                    </div>
                    </div>

                    {/* REMOVE PRICE FROM VARIANTS */}
                    {/* <div className='col-12'>
                    <div className='w-full flex flex-column'>
                        <p className="capitalize mb-2">Prix original</p>
                        <InputText keyfilter="pnum"
                        value={values.priceProduct}
                        onChange={handleChange('priceProduct') } 
                        placeholder='prix original'
                        onBlur={handleBlur('priceProduct')}
                        className={classNames({ 'p-invalid':  isFormFieldValid('priceProduct')})}
                        />
                        {getFormErrorMessage('priceProduct')}
                    </div>
                    </div>

                    <div className='col-12'>
                    <div className='w-full flex flex-column'>
                        <p className="capitalize mb-2">Prix de solde</p>
                        <InputText keyfilter="pnum"
                        onChange={handleChange('discountPrice') } 
                        value={values.discountPrice}
                        placeholder='prix de solde'
                        onBlur={handleBlur('discountPrice')}
                        className={classNames({ 'p-invalid':  isFormFieldValid('discountPrice')})}
                        />
                        {getFormErrorMessage('discountPrice')}
                    </div>
                    </div> */}

                    <div className='w-full flex justify-content-end align-items-center mt-2'>
                        <Button label="annuler" onClick={() => setVisible(false)} className="mr-2 p-button-text p-button-danger p-button-sm" />
                        <Button label="appliquer sur tous" onClick={handleSubmit} className="p-button-sm"/>
                    </div>
                </div>
            )
        }}
        </Formik>


        </Dialog>
        </>
    )
}

