import Api from "../utils/Api";


export class LivreursService {

  // GET NON DELETED LIVREUR 
  async getLivreurs() {
    let response  = {}
    try {
      const livreurs = await Api.get(`livreur/admin`)
      response.data = livreurs.data
      return response
    } catch (error) {
      console.log("error : ",error)
      response.error = error
      return response
    }
  }


  // UPDATE LIVREUR
  async updateLivreur (_id,data) {
    let response = {}
    try {
      const updatedLivreur = await Api.put(`livreur/update/${_id}`,data)
      response.data = updatedLivreur.data
      return response
    } catch (error) {
      console.log("error : ",error.message)
      response.error = error
      return response
    }
  }

  // CREATE LIVREUR
  async createLivreur (data){
    let response = {}
    try {
      const res = await Api.post(`livreur/create`,data)
      response.data = res.data
      return response
    } catch (error) {
      response.error = error
      return response
    }
  }

  // DELETE LIVREUR
  async deleteLivreur (_id){
    let response = {}
    try {
      const res = await Api.delete(`livreur/delete/${_id}`)
      response.data = res.data
      return response
    } catch (error) {
      response.error = error
      return response
    }
  }



}