import React, { useState, useEffect, useRef } from 'react';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { CategoryService } from '../service/CategoryService';
import { InputSwitch } from 'primereact/inputswitch';
import AddCategory from '../components/Categories/AddCategory';
import EditCategory from '../components/Categories/EditCategory';
import ImageTemplate from '../components/orders/ImageTemplate';
import NoData from '../components/NoData';

const Categories = () => {
    const categoryService = new CategoryService();
    const [categories, setCategories] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getData()
    }, []);

    async function getData(){
        const response = await categoryService.getCategories()
        if(response.data){
            const tree = categoryService.buildTree(response.data)
            console.log(tree)
            setCategories(tree)
            setLoading(false)
        } else {
            console.log(response.error);
            setLoading(false)
        }
    }

    const updateStatusCategoty = async(_id, active) => {
        const response = await categoryService.updateCategory(_id,{active})
        if(response.data){
            getData()
        } else {
            console.log(response.error);
        }
    }

    const createCategory = async (data) => {
        const response = await categoryService.createCategory(data)
        if(response.data){
            getData()
        } else {
            console.log(response.error);
        }
    }

    const updateCtagory = async (_id, data) => {
       
        const response = await categoryService.updateCategory(_id, data)
        if(response.data){
            getData()
        } else {
            console.log(response.error);
        }
    }

    const statusBodyTemplate = (rowData) => {
        return (
            <InputSwitch checked={rowData.active} onChange={(e) => 
                updateStatusCategoty(rowData._id,e.value)
            } />
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <EditCategory updateCtagory={updateCtagory} rowData={rowData} />
            </div>
        );
    }
    const categoryNameBodyTemplate = (rowData) => {
        return (
            <span>{rowData.nameCategory}</span>
        )
    }

    return (
        <div className="grid crud-demo">
                <div className="col-12">
                    <div>

                    <div className='mb-4 flex alignItems-center justify-content-between'>
                        <div className='flex align-items-center justify-content-center'>
                            <p className='text-2xl font-semibold'>Catégorie</p>
                        </div>
                        <AddCategory createCategory={createCategory}/>
                    </div>

                    <div className='card'>
                    <TreeTable 
                    
                        value={categories} 
                        tableStyle={{ minWidth: '50rem' }}
                        loading={loading}
                        paginator
                        dataKey="_id" 
                        rows={25} 
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Afficher {first} à {last} de {totalRecords} catégories"
                        emptyMessage={<NoData label='Aucune catégorie disponible'/>}
                    >
                        <Column field="nameCategory" header="name" expander body={categoryNameBodyTemplate} sortable></Column>
                        <Column field="photo" header="image" body={(rowData)=><ImageTemplate photos={[rowData.photo.url]}/>}></Column>
                        <Column field="active" header="status" body={statusBodyTemplate} sortable></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </TreeTable>
                    </div>
                    </div>
                </div>
            </div>
    )
}


export default React.memo(Categories);

