import React from 'react'
import { Button } from 'primereact/button'


const OptionsMenu = (
  { setToggleMenu,
    rowData,
    toggleMenu,
    children}) => {
      
  return (
        <div>
          <Button 
          onClick={() => setToggleMenu(rowData._id === toggleMenu ? null : rowData._id )}
          icon={toggleMenu === rowData._id ? 'pi pi-times' : 'pi pi-bars'} className='p-button-sm p-button-text p-button-secondary p-2' />
          {children}
       </div>
      )
}

export default OptionsMenu