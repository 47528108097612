import React from 'react'
import {Chips} from 'primereact/chips'

const TagsBlock = ({tags,setFieldValue}) => {


  return (
    <div className='card p-fluid justify-content-center'>
    <p className='font-bold mb-4'>Tags</p>
        <p>insérer les tags de produit</p>
        <Chips
            value={tags} 
            name='tags'
            onChange={(e) => setFieldValue('tags', e.value)} separator="," />
    </div>

  )
}

export default TagsBlock