import { atom } from "recoil";

export const totalRecordsAtom = atom({
  key: "totalRecordsAtom",
  default: 0,
})

export const selectedOrdersToShipAtom = atom({
  key: "selectedOrdersToShipAtom",
  default: [],
})
