import React,{ useState, useEffect, useRef } from 'react'
import BreadCrump from '../../components/BreadCrump'
import { Button } from 'primereact/button';
import { TreeSelect } from 'primereact/treeselect';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { ProductService } from '../../service/ProductService';
import { VariantService } from '../../service/VariantService';
import { OptionsService } from '../../service/OptionsService';
import { ImageService } from '../../service/ImageService';
import { FileUpload } from 'primereact/fileupload';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';

//------------------------------ FORM ---------------------------------------------- 
import OptionsBlock from '../../components/products/Add_product/OptionsBlock';
import PriceBlock from '../../components/products/Add_product/PriceBlock';
import StockBlock from '../../components/products/Add_product/StockBlock';
import TagsBlock from '../../components/products/Add_product/TagsBlock';
import CustomInput from '../../components/CustomInput';
import CustomTextArea from '../../components/CustomTextArea';
import CustomSwitch from '../../components/CustomSwitch';
import { CategoryService } from '../../service/CategoryService';
import { BrandsService } from '../../service/BrandsService';
import { Dropdown } from 'primereact/dropdown';


const AddProduct = () => {

    const productService = new ProductService()
    const optionsService = new OptionsService()
    const variantService = new VariantService()
    const categoryService = new CategoryService()
    const brandsService = new BrandsService()
    const imageService = new ImageService()
    
    const breadCrumpItems = {
        href:'products',
        parentName:'produits',
        currentPage:'ajouter nouveau produit'
    }
    const marginBottom = 25
    const toast = useRef(null);
    const navigate = useNavigate()

    const [categories, setCategories] = useState([])
    const [brands, setBrands] = useState([])
    const [options,setOptions] = useState([]) // OPTIONS
    const [variants,setVariants] = useState([]) // VARIANTS
    const [familyDiscount, setFamilyDiscount] = useState(null)
    
    const [totalImages, setTotalImages] = useState(0)
    const [loading, setloading] = useState(false) 
    const [loadingCategories, setLoadingCategories] = useState(false)
    const [loadingBrands, setLoadingBrands] = useState(false)


    const initialValues = {
        codeArticle: '',
        codeFamille: '',
        nameProduct: '',
        mainCategory: '',
        brand: '',
        priceProduct: '',
        photos: [],
        tags:[],
        description:'',
        quantityStock: '',
        minOrderQuantity: '',
        maxOrderQuantity: '',
        active: true,
        hasVariant: false
    }

    const validationSchema = Yup.object().shape({
        codeArticle: Yup.string().required("code article obligatoire"),
        codeFamille:  Yup.string().required("code famille (Atlas) obligatoire"),
        nameProduct: Yup.string().required('nom obligatoire'),
        mainCategory: Yup.string().required("catégorie obligatoire"),
        brand: Yup.string(),
        priceProduct: Yup.number().required('prix obligatoire'),
        description: Yup.string().required('description obligatoire'),
        quantityStock: Yup.number()
                        .integer('sans virgule')
                        .required('quantité stock obligatoire'),
        minOrderQuantity: Yup.number()
                            .integer('sans virgule'),
        maxOrderQuantity: Yup.number()
                            .integer('sans virgule'),
        active: Yup.bool(),
        hasVariant: Yup.bool(),
    })

    useEffect(() => {
        getCategories()
        getBrands()
    }, [])

    const getCategories = async () => {
        const queryParams = "active=true"
        const response = await categoryService.getCategories(queryParams)
        if(response.data){
            const tree = categoryService.buildTree(response.data)
            console.log(tree)
            setCategories(tree)
            setLoadingCategories(false)
        } else {
            console.log(response.error);
            setLoadingCategories(false)
        }
    }

    const getBrands = async () => {
        const queryParameters = `active=true`
        const response = await brandsService.getAllBrands(queryParameters)
        if(response.data){
            setBrands(response.data)
            setLoadingBrands(false)
        } else {
            console.log(response.error);
            setLoadingBrands(false)
        }
    }
    
    //-----------handle template images-------------//

    // when an image added
    const onTemplateSelect = (e, setFieldValue, photos) => {
        console.log(e.files[0].objectURL)
        let newPhotos = [...photos]
        newPhotos.push(e.files[0].objectURL)
        setFieldValue("photos", newPhotos)
        setTotalImages((_totalImages) => _totalImages + 1)
    }

    // when an image removed
    const onTemplateRemove = (e, setFieldValue, photos) => {
        const removedPhoto = e.file.objectURL
        let newPhotos = [...photos]
        const indexRemovedPhoto = newPhotos.findIndex((photo) => photo == removedPhoto)
        if (indexRemovedPhoto != -1){
            newPhotos.splice(indexRemovedPhoto, 1)
        }
        setFieldValue("photos", newPhotos)
        setTotalImages((_totalImages) => _totalImages - 1)
    }

    // when all images removed
    const onTemplateClear = (e, setFieldValue) => {
        setFieldValue("photos", [])
        setTotalImages(0)
    }

    // CALCULATE OPTION TOTAL QUANTITY
    const calculateTotalOptionValues=()=>{
        let newArr=[...options]
        let indexVal
        variants.map((_var,index)=>{
            if(_var.option1){
                indexVal =  newArr[0].values.findIndex((v) => v.name === _var.option1.value)
                if(indexVal !== -1 ){
                    newArr[0].values[indexVal].quantityStock += Number(_var.quantityStock)
                }}
            
              if(_var.option2){
                indexVal =  newArr[1].values.findIndex((v) => v.name === _var.option2.value)
                
             if(indexVal !== -1 ){
              newArr[1].values[indexVal].quantityStock += Number(_var.quantityStock)
            }}

            
        })
        setOptions(newArr)
    }

    // PUSH OPTION ID TO EACH VARIANT
    const pushIdToVariant=(responseOpt)=>{
        let opt1, opt2, opt3
        let newArr = [...variants]
        
        newArr.map((_var,index)=>{
            if(_var.option1){
                if(typeof opt1 === 'undefined')
                opt1 = responseOpt.find(op => op.numOption === 1)
                newArr[index].option1.optionId = opt1._id
            }
            if(_var.option2){
                if(typeof opt2 === 'undefined')
                opt2 = responseOpt.find(op => op.numOption === 2)
                newArr[index].option2.optionId = opt2._id
            }
        })
        setVariants(newArr)
    }

    //create products
    async function onSubmit(values, actions){
        if(values.hasVariant && options.length === 0){
            toast.current.show({severity:'warn', summary: 'Warning', detail:'ajouter des options', life: 3000});
        } else {
            let blobs = [], firebaseUrl = []
            if(values.photos.length > 0){
                blobs = values.photos
            }
            if(blobs.length > 0){
                for(let i =0; i < blobs.length; i++ ){
                    const blobUrl = await fetch(blobs[i]).then(r => r.blob()); //get blob url
                    const imageUrlFirebase = await imageService.uploadImage(blobUrl,`products/${values.nameProduct}-${uuidv4()}`) // upload to firebase and get url
                    firebaseUrl.push(imageUrlFirebase.data)
                }
            }
            let _familyDiscount = []
            if(familyDiscount != null)
                 _familyDiscount = familyDiscount.map(({family, percentage}) => ({family, percentage}))

            values.photos = firebaseUrl
            values.discountPercentage = _familyDiscount
            await _addProduct(values)
        }
    }


    async function _addProduct(values){
        const {
            codeArticle,codeFamille,nameProduct,mainCategory,brand,priceProduct,
            discountPercentage,photos,tags,description,quantityStock,
            minOrderQuantity,maxOrderQuantity,active,hasVariant
        } = values
        const payloadCreateProduct = {
            codeArticle,
            codeFamille,
            nameProduct,
            mainCategory,
            brand,
            priceProduct,
            photos,
            tags,
            description,
            quantityStock,
            minOrderQuantity: minOrderQuantity == "" ? null : minOrderQuantity,
            maxOrderQuantity: maxOrderQuantity == "" ? null : maxOrderQuantity,
            active,
            hasVariant
        }
        setloading(true)        
        const response = await productService.addProduct(payloadCreateProduct)
        if(response.data){
            if(!hasVariant){
                const singleVariant=[
                    {
                        product: response.data._id,
                        reference: codeArticle,
                        quantityStock: quantityStock,
                        minOrderQuantity: minOrderQuantity,
                        maxOrderQuantity: maxOrderQuantity,
                    }
                ]
                console.log('singleVariant',singleVariant)
                await addVariant(singleVariant)
            } else {
                calculateTotalOptionValues()
                for(let i in options){
                  options[i].product = response.data._id 
                }
                const optionsRes = await optionsService.addOption(options) 
                if(optionsRes.data){
                  pushIdToVariant(optionsRes.data) 
                }else{
                  toast.current.show({severity:'error', summary: 'Error', detail:'erreur options essayer plus tard', life: 3000});
                }
                

                for(let i in variants){
                  variants[i].product = response.data._id
                  variants[i].discountPercentage = discountPercentage
                }
                await addVariant(variants)
                navigate('/products')
                console.log('variants',variants)
            }
        } else {
            setloading(false)
            toast.current.show({severity:'error', summary: 'Error', detail:'erreur essayer plus tard', life: 3000});
            console.log(response.error)
        }
    }


    async function addVariant(variant){
        const response = await variantService.addVariant(variant)
        if(response.data){
            console.log('variant added')
            navigate('/products')
        }else{
            console.log(response.error)
            toast.current.show({severity:'error', summary: 'Error', detail:'erreur ajout variant', life: 3000});
        }
        return response
    }




    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {chooseButton}
                {cancelButton}
            </div>
        );
    } 


  return (
      <>
    <Toast ref={toast} />
      <div 
      className='flex flex-column sm:flex-row align-items-center justify-content-between' >
        <BreadCrump breadCrumpItems={breadCrumpItems} />
      </div>

      <div className='p-fluid grid'>
        <Formik 
        enableReinitialize={true}
        initialValues={initialValues} 
        validationSchema={validationSchema}
        onSubmit={onSubmit} >
        {({ handleChange, 
            handleSubmit,
            isSubmitting,
            handleBlur,
            values, 
            errors, 
            touched, 
            setFieldValue 
        }) =>
        {
            const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
            const getFormErrorMessage = (name) => {
                return isFormFieldValid(name) && <small className="p-error mt-2">{errors[name]}</small>;
            };

            return(
            <>
            <div className='col-12 md:col-7'>
            <div className='card'>

            {/* SKU BLOCK */}
            {!values.hasVariant
            &&<CustomInput
            label='code article'
            placeholder='code article (atlas)'
            handleChange={handleChange}
            value={values.codeArticle}
            name='codeArticle'
            isFormFieldValid={isFormFieldValid}
            getFormErrorMessage={getFormErrorMessage}
            />}

            <CustomInput
            label='code famille'
            placeholder='code famille (atlas)'
            handleChange={handleChange}
            value={values.codeFamille}
            name='codeFamille'
            isFormFieldValid={isFormFieldValid}
            getFormErrorMessage={getFormErrorMessage}
            />

            {/* NAME BLOCK */}
            <CustomInput
            label='titre de produit'
            placeholder='titre'
            handleChange={handleChange}
            value={values.nameProduct}
            name='nameProduct'
            isFormFieldValid={isFormFieldValid}
            getFormErrorMessage={getFormErrorMessage}
            />

            {/* DESCRIPTION BLOCK */}
            <CustomTextArea
             label='description de produit'
             placeholder='description'
             handleChange={handleChange}
             value={values.description}
             name='description'
             isFormFieldValid={isFormFieldValid}
             getFormErrorMessage={getFormErrorMessage}
            />

            {/* CATEGORY */}
            <div className='mb-3'>
            <p className="mb-2 font-bold">Catégorie</p>   
                <TreeSelect
                    value={values.mainCategory} 
                    onChange={handleChange("mainCategory")} 
                    options={categories} 
                    disabled={loadingCategories}
                    className="md:w-20rem w-full" 
                    placeholder={loadingCategories ? "loading" : "selectionner catégorie"}
                    filter
                />
            </div>
            {getFormErrorMessage("mainCategory")}

            {/* BRAND */}
            <div className='mt-4'>
                <p className="mb-2 font-bold">marque</p>
                <div className='flex align-items-center'>
                    <Dropdown
                        filter
                        value={values.brand} 
                        onChange={handleChange('brand')}
                        placeholder={loadingBrands ? "loading" : "sélectionner marque"}
                        disabled={loadingBrands}
                        options={brands}
                        optionLabel="brandName" 
                        optionValue="_id"
    
                    />
             
                </div>
            </div>
            {getFormErrorMessage("brand")}

            </div>

            {/* TAGS BLOCK */}
            <TagsBlock tags={values.tags} setFieldValue={setFieldValue} />

            <div className='card'>
                <p className='font-bold mb-2' >Média</p>
                    <FileUpload name="demo[]" url="https://primefaces.org/primereact/showcase/upload.php" 
                        multiple
                        headerTemplate={headerTemplate}
                        accept="image/*" 
                        maxFileSize={2000000}
                        onSelect={(e) => onTemplateSelect(e, setFieldValue, values.photos)}
                        onRemove={(e) => onTemplateRemove(e, setFieldValue, values.photos)}
                        onClear={(e) => onTemplateClear(e, setFieldValue)} 
                        // onError={onTemplateClear} 
                        emptyTemplate={<p className="m-0">Faites glisser et déposez les images ici pour les télécharger.</p>} />
            </div>  

            {/* OPTIONS BLOCK */}
            <OptionsBlock 
            hasVariant={values.hasVariant} 
            handleChange={handleChange}
            variants={variants} 
            setVariants={setVariants} 
            options={options} 
            setOptions={setOptions} />

            </div>
            
            <div className='col-12 md:col-5'>
                

            {getFormErrorMessage("mainCategory")}

            {/* STOCK BLOCK */}
            {!values.hasVariant
            &&<StockBlock
                values={values}
                handleChange={handleChange}
                isFormFieldValid={isFormFieldValid}
                getFormErrorMessage={getFormErrorMessage}
            />}
                
            {/* PRICE BLOCK */}
            {!values.hasVariant
            &&<PriceBlock 
            values={values}
            handleChange={handleChange}
            isFormFieldValid={isFormFieldValid}
            getFormErrorMessage={getFormErrorMessage}
            familyDiscount={familyDiscount}
            setFamilyDiscount={setFamilyDiscount}
            />}
                      
            {/* STATUS BLOCK */} 
            <div className="card">   
            <CustomSwitch
                label='status de produit'
                name='active'
                active={values.active} 
                handleChange={handleChange}/>
            </div>  
                                    
            </div>  

            <div
            className='mb-3 flex justify-content-end w-full'>
            <Button 
                label='sauvegarder'
                loading={loading}
                onClick={handleSubmit} 
                className='w-auto p-button-success' 
                icon="pi pi-check"/>
            </div>
            </>
            )
        }}
        </Formik>
      </div>
    </>
  )
}

export default AddProduct