import React, { useRef,useEffect, useState } from 'react'
import { Toast } from 'primereact/toast'
import BreadCrump from '../../components/BreadCrump';
import { useParams } from 'react-router-dom';
import { OrderService } from '../../service/OrderService';
import { CustomersService } from '../../service/CustomersService';
import { LoadingComponent } from '../../components/LoadingComponents';
import { statusCheck } from './statusColor';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import ImageTemplate from '../../components/orders/ImageTemplate';


const OrderDetail = () => {
  const ordersService = new OrderService()
  const customersService = new CustomersService()
  const [order,setOrder] = useState({})
  const [customer,setCustomer] = useState({}) 
  const [orderStatusHistory, setOrderStatusHistory]= useState([])
  const [livreur, setLivreur]= useState(null)
  const [loading,setLoading] = useState(true)
  const dt = useRef(null);

  console.log('order',order)

  const breadCrumpItems={
    href:'orders',
    parentName:'commandes',
    currentPage:'détails de la commande'
  }

  const toast = useRef(null);
  let { id } = useParams()
  
  const getData = async () => {
    const response = await ordersService.getOrderDetail(id)
    if(response.data){
      setOrder(response?.data)
      getCustomerInfo(response?.data?.customer)
      getOrderStatusHistory()
    } else {
      toast.current.show({severity:'error', summary: 'Error', detail:'erreur get order detail essayer plus tard', life: 3000})
      console.log(response.error)
    }
  }

  const getCustomerInfo = async (customerId) => {
    const response = await customersService.getCustomerDetail(customerId)
    if(response.data){
      setLoading(false)
      setCustomer(response.data)
    } else {
      setLoading(false)
      toast.current.show({severity:'error', summary: 'Error', detail:'erreur get customer essayer plus tard', life: 3000})
      console.log(response.error)
    }
  }

  const getOrderStatusHistory = async () => {
    const response = await ordersService.getOrderStatusHistory(id)
    if(response.data){
      setOrderStatusHistory(response.data)
      const livreur =  response.data.find(item => item.status === 'ÉXPEDIÉ')
      if(livreur) setLivreur(livreur.livreur)
    } else {
      console.log(response.error)
    }
  }

  useEffect(()=>{
    getData()
  },[id])

  
  console.log('order status history',orderStatusHistory)

  // ORDER DATE
  const orderDate=(dt)=>{
    const date = dt.split('T')[0]
    return date
  } 


  const productNameTemplate=(rowData) => {
      const { product, variant } = rowData
      return(
          <>
          {
              product !== null
              ? <>
              <p style={{fontSize:12}}>{product?.nameProduct}{ displayVariants(variant)}</p>
              </>
              : <p style={{color:'#f00'}}>{"ce produit n'existe plus"}</p>
          }
          </>
      )
  }

  function displayVariants(variant){
    const {option1, option2, option3} = variant
    let text = ""
    if(option1 != null){
      if(option1.value.startsWith('#'))
        text+= displayColor(option1.value) + ' '
      else 
        text+= option1.value + ' '
    }
    if(option2 != null){
      if(option2.value.startsWith('#'))
        text+= displayColor(option2.value) + ' '
      else 
        text+= option2.value + ' '
    }  
    if(option3 != null){
      if(option3.value.startsWith('#'))
        text+= displayColor(option3.value)
      else 
        text+= option3.value
    }  
    if(text != "")
      text = ` ( ${text})`
    return text
  }
  
  function displayColor (colorValue){
    let colorName
    switch(colorValue){
      case "#252525" : colorName  ="noir" ; break ;
      case "#f2f7ff" : colorName =" blanc" ; break ;
      case "#ff00b7" : colorName = "rose" ; break ;
      case "#f4ffbf" : colorName = "beige" ; break ;
      case "#704201" : colorName = "marron" ; break ;
      case "#847e60" : colorName = "taupe" ; break ;
      case "#715a3a" : colorName = "camel" ; break ;
      case "#6f7272" : colorName = "gris" ; break ;
      case "#e68805" : colorName = "orange" ; break ;
      case "#00ad17" : colorName = "vert" ; break ;
      case "#f6fa00" : colorName = "jaune" ; break ;
      case "#f00000" : colorName = "rouge" ; break ;
      case "#0e00ad" : colorName = "bleu" ; break ;
    }
    return colorName
  }

  

  if(loading){
    return <LoadingComponent/>
  }

  return (
    <>
    <Toast ref={toast} />
    <div 
    className='flex flex-column sm:flex-row align-items-center justify-content-between' >
      <BreadCrump breadCrumpItems={breadCrumpItems} />
    </div>

    <div className='grid'>

    <div className='col-12 md:col-8'>
    <div className='grid'>
      <div className='col-12'>
        <div className='card' style={{border:`0.5px solid ${statusCheck(order?.status)}`}}>

          <div className='w-full flex justify-content-between'>
            <div className='w-full flex align-items-center'>
              <div className='mr-2'><p className='text-black-alpha-90'>N ° de commande</p></div>
              <div><p className='font-semibold underline text-primary'>{order?.numOrder}</p></div>
            </div>

            <div style={{backgroundColor:statusCheck(order?.status),borderRadius:12,width:'80px'}} 
            className='flex align-items-center justify-content-center pr-2 pl-2'>
              <p className='text-sm font-medium lowercase' style={{color:'#fff'}}>{order?.status}</p>
          </div>

          </div>

          <div className='mt-3 flex align-items-center justify-content-between flex-wrap mt-5'>
            
            <div className='flex flex-column align-items-start mr-2'>
              <div><p className='font-bold text-black-alpha-90'>date</p></div>
              <div><p>{orderDate(order?.createdAt)}</p></div>
            </div>

            <div className='flex flex-column align-items-start mr-2'>
              <div><p className='font-bold text-black-alpha-90'>ICE</p></div>
              <div><p>{customer?.ISE}</p></div>
            </div>

            <div className='flex flex-column align-items-start mr-2'>
              <div><p className='font-bold text-black-alpha-90'>entreprise</p></div>
              <div><p>{customer?.nameEntreprise}</p></div>
            </div>

            <div className='flex flex-column align-items-start mr-2'>
              <div><p className='font-bold text-black-alpha-90'>client</p></div>
              <div><p>{customer?.personalName}</p></div>
            </div>

          </div>

          <div style={{border:'1px solid #eee',borderRadius:10}} className='p-3 mt-5'>
            <div>
              <p className='font-bold text-xl text-black-alpha-90 capitalize'>address</p>
            </div>
            <div><p>{customer?.address}</p></div>
            <div><p>{customer?.city}</p></div>
            <div className='mt-2 flex align-items-center justify-content-start'>
              <i className='pi pi-phone'></i>
              <p className='ml-2'>{customer?.phoneNumber}</p>
            </div>
          </div>

        </div>
      </div>
      
      <div className='col-12'>
        <div className='card'>
          <DataTable 
              size='small' 
              stripedRows 
              rowHover
              paginator
              ref={dt} 
              value={order?.orderDetail} 
              responsiveLayout="scroll"
              dataKey="id" 
              rows={5}
              filterDisplay="row"
              paginatorTemplate="PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown"
              currentPageReportTemplate="afficher {first} à {last} de {totalRecords} produits"
              rowsPerPageOptions={[5, 10, 25, 50]} 
              className="datatable-responsive"
              emptyMessage="aucun commande trouvée">
                  <Column field="product.nameProduct" header="produit" body={productNameTemplate} ></Column>
                  <Column field="product.photos" header="image" body={(rowData)=><ImageTemplate photos={rowData.product.photos} />}></Column>
                  <Column field="purchasePrice" header="prix unitaire"></Column>
                  <Column field="quantityOrdered" header="quantité" ></Column>
                  <Column field="totalPrice" header="prix total" ></Column>
          </DataTable>
        </div>
      </div>

    </div>
    </div>
      
    <div className='col-12 md:col-4'>
      <div className='grid'>

        <div className='col-12'>
          <div className='card'>
            <div><p className='font-bold text-xl text-black-alpha-90 capitalize'>détail</p></div>
            <div className='mt-3 flex flex-wrap align-items-center'>
              <div className='mr-2'><p>quantité:</p></div>
              <div><p>{order?.quantityTotal}</p></div>
            </div>
            <div className='flex flex-wrap align-items-center'>
              <div className='mr-2'><p>livraison:</p></div>
              <div><p>gratuite</p></div>
            </div>
            <div className='flex flex-wrap align-items-center'>
              <div className='mr-2'><p>total:</p></div>
              <div><p>{order?.totalPriceOrder}dh TTC</p></div>
            </div>
          </div>
        </div>

        {livreur!= null &&        
        <div className='col-12'>
          <div className='card'>
          <div><p className='font-bold text-xl text-black-alpha-90 capitalize'>Livreur</p></div>
            <div className='mt-3 flex flex-wrap align-items-center'>
              <div className='mr-2'><p>nom livreur:</p></div>
              <div><p>{livreur?.nameLivreur}</p></div>
            </div>
            <div className='flex flex-wrap align-items-center'>
              <div className='mr-2'><p>numéro de télephone:</p></div>
              <div><p>{livreur?.phoneNumber}</p></div>
            </div>
          </div>
        </div>
        }

        {orderStatusHistory.length > 0
        ?<div className='col-12'>
          <div className='card'>
          <div className='mb-3'><p className='font-bold text-xl text-black-alpha-90 capitalize'>Historique</p></div>
            {
              orderStatusHistory.map((item,index)=>{
                return(
                  <div className='flex flex-row flex-wrap align-items-center'>
                    <div><p>{item?.createdAt.split('T')[0]}</p></div>
                    <i className='mx-2 pi pi-chevron-right text-xs'></i>
                    <div><p>{item?.by ? item.by.name : "utilisateur supprimé" }</p></div>
                    <i className='mx-2 pi pi-chevron-right text-xs'></i>
                    <div><p className='font-bold' style={{color:statusCheck(item?.status)}} >{item?.status}</p></div>
                  </div>
                )
              })
            }
          </div>
        </div>
        :null}

      </div>
    </div>

    </div>
    </>
  )
}

export default OrderDetail