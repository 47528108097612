import React,{ useEffect, useState, useRef } from 'react'
import { Toast } from 'primereact/toast'
import { InputTextarea } from 'primereact/inputtextarea'
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from 'primereact/radiobutton';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { classNames } from 'primereact/utils';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { Button } from 'primereact/button';
import { ListBox } from 'primereact/listbox';
import { LoadingComponent } from '../components/LoadingComponents';
//----------------------- services --------------------------------------
import { TypesService } from '../service/TypesService'
import { ProductService } from '../service/ProductService'
import { NotificationService } from '../service/NotificationService';



const Notifications = () => {
  const productService = new ProductService()
  const typeService = new TypesService()
  const notificationService = new NotificationService()
  const toast = useRef(null);
  const [notificationType, setNotificationType] = useState('notifSimple');
  const [productName, setProductName] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [types,setTypes] = useState({
    loading: true,
    data: []
  })
  const [products,setProducts] = useState({
    loading: true,
    data: []
  })

  const [loadingSendNotif, setLoadingSendNotif] = useState(false)
  

  const initialValues = {
    notificationBody: '',
    notificationTitle: '',
    selectedType:null
  }

  const validationSchema = Yup.object().shape({
      notificationTitle: Yup.string().max(50,'le titre de la notification ne doit pas dépasser 50 caractères').required('champ obligatoire'),
      notificationBody: Yup.string().max(200,'le corps de la notification ne doit pas dépasser 200 caractères').required('champ obligatoire'),
      selectedType: Yup.array().min(1,"type obligatoire").required(),
  })

  // GET PRODUCTS
  const getProducts=async()=>{
    const response = await productService.getProductName(productName)
    if(response.data){
      setProducts({loading:false,data:response.data})
      console.log('response',response.data)
    }else{
      setProducts({loading:false})
      toast.current.show({severity:'error', summary: 'Error', detail:'erreur essayer plus tard', life: 3000})
      console.log(response.error)
    }
  }

  // GET TYPES
  const getTypes=async()=>{
    const response = await typeService.getCustomersTypes()
    if(response.data){
      setTypes({loading:false,data:response.data})
    }else{
      setTypes({loading:false})
      toast.current.show({severity:'error', summary: 'Error', detail:'erreur essayer plus tard', life: 3000})
      console.log(response.error)
    }
  }
  
  useEffect(()=>{
    getTypes()
  },[])

  useEffect(()=>{
    getProducts()
  },[productName])


  const onSubmit = async (values,actions) => {
    console.log(values)
    setLoadingSendNotif(true)
    let notificationData = {}
    let customerTypes
    if(values.selectedType != null)
      customerTypes = values.selectedType.map(({_id}) => _id )
    if(notificationType === 'notifProduit'){
      if(values.selectedType?.length > 0 && selectedProduct !== null){
        notificationData = {
          channel:"product", 
          title: values.notificationTitle,
          body: values.notificationBody,
          product : JSON.stringify(selectedProduct)

        }
        await notificationService.pushNotification(notificationData, customerTypes)
        toast.current.show({severity:'success', summary: 'success', detail:'notification success', life: 3000})
       }else toast.current.show({severity:'warn', summary: 'Warnning', detail:'selectionnez un produit', life: 3000})
       
    } else{
        notificationData = {
          channel:"simple", 
          title: values.notificationTitle,
          body: values.notificationBody,
        }
        await notificationService.pushNotification(notificationData, customerTypes)
        toast.current.show({severity:'success', summary: 'success', detail:'notification success', life: 3000})
    }
    setLoadingSendNotif(false)
  }
  
  const productOptionTemplate = ({nameProduct,photos}) => {
    return (
        <div className="flex align-items-center gap-2">
            { photos.length > 0
            ? <img alt={'img'} src={photos[0]} 
             style={{ width: '20px', height:'20px' }} />
            :
              <i className='pi pi-image'></i>
            }
            <div className='ml-2'>{nameProduct}</div>
        </div>
    );
  };


  if(types.loading){
    return <LoadingComponent />
  }
  return (
    <div className="grid crud-demo">
      <Toast ref={toast} />
      
      <div className="col-12">
      <div className='mb-4 flex alignItems-center'>
        <div className='flex align-items-center justify-content-center'>
          <p className='text-2xl font-semibold'>Notifications</p>
        </div>
      </div>

      <div className="card">

        <div className="grid">

        <div className="col-12 mb-4">
          <p className='font-semibold'>type de notification</p>
          <div className="flex flex-wrap gap-4">
              <div className="flex align-items-center mr-3">
                  <RadioButton inputId="ingredient1" name="notifSimple" value="notifSimple" 
                  onChange={(e) => {
                    setNotificationType(e.value)
                  }}
                  checked={notificationType === 'notifSimple'} />
                  <label htmlFor="ingredient1" className="ml-2">notification simple</label>
              </div>
              <div className="flex align-items-center">
                  <RadioButton inputId="ingredient2" name="notifProduit" value="notifProduit" 
                  onChange={(e) => {
                    setNotificationType(e.value)
                  }}
                  checked={notificationType === 'notifProduit'} />
                  <label htmlFor="ingredient2" className="ml-2">notification avec produit</label>
              </div>
          </div>
        </div>

        <div className="col-12 sm:col-6">
          <Formik 
              initialValues={initialValues} 
              validationSchema={validationSchema} 
              onSubmit={onSubmit}>
              {({ handleChange,
                  handleBlur, 
                  handleSubmit,
                  isSubmitting, 
                  setFieldValue,
                  values, 
                  errors, 
                  touched 
              })=>{
                
              const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
              const getFormErrorMessage = (name) => {
                  return isFormFieldValid(name) && <small className="mt-2 p-error">{errors[name]}</small>;
              };

              return(
                <>
                <div className='w-full flex flex-column'>

                <div className="mb-3 flex flex-column">
                  <p className="mb-3 font-semibold">sélectionner un où plusieurs type</p>
                    <MultiSelect 
                    value={values.selectedType} 
                    onChange={(e)=>handleChange('selectedType')(e)} 
                    options={types.data} optionLabel="customerType" 
                    placeholder="types" maxSelectedLabels={3} 
                    className={classNames({ 'p-invalid':  isFormFieldValid('selectedType')})} />
                  {getFormErrorMessage('selectedType')}
                </div>
                  
                  <div className="mb-3 flex flex-column">
                    <p className='font-semibold'>titre <span className='font-normal'>(max 50 caracter)</span></p>
                    <InputText
                    onChange={handleChange('notificationTitle')}
                    onBlur={handleBlur('notificationTitle')}
                    className={classNames({ 'p-invalid':  isFormFieldValid('notificationTitle')})}
                    />
                    {getFormErrorMessage('notificationTitle')}
                  </div>

                  <div className='flex flex-column'>
                    <p className='font-semibold'>corp <span className='font-normal'>(max 200 caracter)</span></p>
                    <InputTextarea  
                    rows='13' cols='50'
                    onChange={handleChange('notificationBody')}
                    onBlur={handleBlur('notificationBody')}
                    className={classNames({ 'p-invalid':  isFormFieldValid('notificationBody')})}
                    /> 
                    {getFormErrorMessage('notificationBody')}
                  </div>

                </div>
                <div className='w-12 flex justify-content-end'>
                  <Button
                  className='mt-3' 
                  label='envoyer'
                  loading = {loadingSendNotif}
                  disabled = {loadingSendNotif}
                  onClick={handleSubmit} 
                  />
                </div>
                </>
                )}}
          </Formik>
          </div>

          {notificationType === 'notifProduit' &&
           <div className='col-12 sm:col-6 '>
            <div className='w-full flex flex-column'>
            <p className="mb-3 font-semibold">sélectionner produit</p>
            <ListBox filter 
              onFilterValueChange={(e)=>setProductName(e.value)}
              filterValue={productName}
              value={selectedProduct} 
              listStyle={{ maxHeight: '270px' }}
              onChange={(e) => setSelectedProduct(e.value)} 
              options={products.data} 
              itemTemplate={productOptionTemplate}
              optionLabel="nameProduct" className='w-12' />
          </div>
          </div>
          }

          

        </div>
      </div>

    </div>
    </div>
  )
}

export default Notifications