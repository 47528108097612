import React, { useState, useEffect, useRef } from "react";
import { AdminService } from "../service/AdminService";
import AddAdmin from "../components/admin/AddAdmin";
import AdminBlock from "../components/admin/AdminBlock";
import { Toast } from "primereact/toast";
import { LoadingComponent } from "../components/LoadingComponents";

const Admins = () => {

  const adminService = new AdminService();
  const [admins, setAdmins] = useState(null);
  const [loading, setLoading] = useState(true);

  const _toast = useRef(null);

  async function getAdmins() {
    setLoading(true);
    const response = await adminService.getAllAdmins();
    if (response.data) {
      setAdmins(response.data);
    } else {
      console.log(response.error);
    }
    setLoading(false);
  }

  useEffect(() => {
    getAdmins();
  }, []);

  const createAdmin = async (data) => {
    const response = await adminService.createAdmin(data);
    if (response.data) {
      getAdmins();
    } else {
      console.log(response.error);
    }
  };

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <Toast ref={_toast} />
      <div className="grid crud-demo">
        <div className="col-12">
          <div>
            <div className="mb-4 flex alignItems-center justify-content-between">
              <div className="flex align-items-center justify-content-center">
                <p className="text-2xl font-semibold">Admins</p>
              </div>
              <AddAdmin createAdmin={createAdmin} />
            </div>
            <div className="grid">
              {admins &&
                admins.map((admin, index) => (
                  <AdminBlock key={index} admin={admin} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Admins;
