import React, { useEffect, useRef, useState } from "react";
import BreadCrump from "../../components/BreadCrump";
import { Toast } from "primereact/toast";
import { AdminService } from "../../service/AdminService";
import { useParams } from "react-router-dom";
import { LoadingComponent } from "../../components/LoadingComponents";
import RolesForm from "../../components/admin/components/RolesForm";

function AdminRoles() {
  const { getAdminInfos } = new AdminService();
  const toast = useRef(null);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [userInfos, setInfos] = useState(null);

  const breadCrumpItems = {
    href: "admins",
    parentName: "admin",
    currentPage: "modifier les roles",
  };

  async function getData() {
    try {
      const res = await getAdminInfos(id);
      if (res.data) {
        setInfos(res.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "erreur ressayez plus tard",
        life: 3000,
      });
    }
  }

  useEffect(() => {
    getData();
  }, [id]);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <Toast ref={toast} />
      <div className="flex flex-column sm:flex-row align-items-center justify-content-between">
        <BreadCrump breadCrumpItems={breadCrumpItems} />
      </div>

      <div className="grid">
        <RolesForm userInfos={userInfos} />
      </div>
    </>
  );
}

export default AdminRoles;
