import React, { useState, useEffect, useRef } from 'react';

import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { Button } from 'primereact/button';
import { LivreursService } from '../../service/LivreursService'
import { OrderService } from '../../service/OrderService';
import { AdminService } from '../../service/AdminService';
import { Link } from 'react-router-dom';

const AddLivreurOrder = 
    ({
        selectedStatus, 
        rowData, 
        hideDialog, 
        pushNotificationUpdateOrderStatus
    }) => {
    
    const livreursService = new LivreursService()
    const orderService = new OrderService()
    const adminService = new AdminService()

    const [livreurs, setLivreurs] = useState([])
    const [loading, setLoading] = useState(false)
    
    useEffect(() => {
        getLivreurs()
    }, [])

    async function getLivreurs(){
        setLoading(true)
        const response = await livreursService.getLivreurs()
        if(response.data){
            console.log("livreurs response", response.data)
            setLivreurs(response.data)
        } else {
            console.log(response.error);
        }
        setLoading(false)

    }
    const initialValues = {
        selectedLivreur: '',
    }
    
    const validationSchema = Yup.object().shape({
        selectedLivreur: Yup.array().min(1,"catégorie obligatoire").required(),
    })

    const onSubmit = async (values, actions) => {
        rowData.status = selectedStatus
        const response = await orderService.updateOrderStatus(rowData)
        if(response.data){
            const { _id } = await adminService.fetchAdminData() //get admin id 
            const { selectedLivreur } = values
            const data = {
                order: rowData._id,
                status: selectedStatus,
                livreur: selectedLivreur,
                by : _id // add that do this action
            }
            const selectedLivreurInformation = livreurs.find((livreur) => livreur._id == selectedLivreur)
            await createStatusHistory(data)
            await pushNotificationUpdateOrderStatus(rowData, selectedLivreurInformation)
        }

    }

    const createStatusHistory = async (data) => {
        const response = await orderService.createOrderStatusHistory(data)
        if(response.data){
            hideDialog()
        } else {
            console.log(response.error)
        }
    }

    return(
        <>
        {/* MODAL */}
        <Dialog 
            draggable={false} visible={true} 
            breakpoints={{'1900px': '60vw', '640px': '100vw'}}
            header="Affecter un livreur à la commande" 
            modal 
            className="p-fluid" onHide={hideDialog}>
            
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ handleChange,handleBlur, handleSubmit,isSubmitting, values, errors, touched, setFieldValue })=>{
               
                const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
                const getFormErrorMessage = (name) => {
                    return isFormFieldValid(name) && <small className="p-error">{errors[name]}</small>;
                };

                return(
                <>
                {
                loading 
                ? <div className='flex align-items-center justify-content-center'><ProgressSpinner /></div> 
                :
                <>
                {
                    livreurs?.length > 0
                    ?<>
                    <div className='mb-3'>
                        <p className="mb-2">Livreurs</p>   
                        <MultiSelect
                            filter
                            value={values.selectedLivreur} 
                            selectionLimit = {1}
                            showSelectAll = {false}
                            onChange={handleChange('selectedLivreur')}
                            options={livreurs}
                            optionLabel="nameLivreur" 
                            optionValue="_id"
                            placeholder="Choisissez un livreur" 
                            className={classNames({ 'p-invalid':  isFormFieldValid('selectedLivreur')})}  
                        />
                        {getFormErrorMessage('selectedLivreur')}
                    </div>
                    <div className='mt-4 flex align-items-center justify-content-end'>
                        <Button label="annuler" className='w-auto p-button-secondary p-button-sm p-button-outlined mr-2' icon="pi pi-times" 
                        onClick={hideDialog}/>

                        <Button onClick={handleSubmit} 
                        label={isSubmitting?'loading':'sauvegarder'}
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        className='w-auto p-button-sm p-button-success' 
                        icon="pi pi-check"  type='submit' />
                    </div>
                    </>

                    : 
                    
                    <div className='flex flex-column align-items-center justify-content-center bg-red-600'>        
                        <img src={'assets/layout/images/delivery-person.png'} width="60px" height="60px" />
                        <p className='font-bold text-md mt-2 lowercase'>{'aucune livreur'}</p>
                        <Link to='/livreurs' >ajouter nouveau livreur</Link>
                    </div>
                }
                </>
                
                }
                </>
                )
            }}
            </Formik>
            
            </Dialog>  
        
        </>
    )
}

export default AddLivreurOrder