import Api from "../utils/Api";

export class GiftService {

    async getGifts(){
        let response = {}
        const res = await Api.get(`gift/getgifts`)
        if(res.data){
            response.data = res.data
            return response
        } else {
            response.error = res.error
            return response
        }
    }

    async createGift (data){
        let response = {}
        const res = await Api.post("gift/create", data)
        if(res.data){
            response.data = res.data
            return response
        } else {
            response.error = res.error
            return response
        }
    } 

    async updateGift (id, data){
        let response = {}
        const res = await Api.put(`gift/update/${id}`, data)
        if(res.data){
            response.data = res.data
            return response
        } else {
            response.error = res.error
            return response
        }
    }

    async getPointsRules (){
        let response = {}
        const res = await Api.get(`pointsrules/getpointsrules`)
        if(res.data){
            response.data = res.data
            return response
        } else {
            response.error = res.error
            return response
        }
    }

    async updatePointsRules (id, data){
        let response = {}
        const res = await Api.put(`pointsrules/update/${id}`, data)
        if(res.data){
            response.data = res.data
            return response
        } else {
            response.error = res.error
            return response
        }
    }
}