import { Formik } from "formik";
import React, { useRef } from "react";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../CustomInput";
import { AdminService } from "../../../service/AdminService";

function UpdateAdminForm({ values }) {
  const { updateAdmin } = new AdminService();
  const navigate = useNavigate();
  const { name, email, _id } = values;
  const toast = useRef(null);

  const initialValues = {
    name: name,
    email: email,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("nom de livreur obligatoire"),
    email: Yup.string()
      .email("saisir un email valide")
      .required("numero de telephone obligatoire"),
  });

  const _updateAdmin = async (data) => {
    try {
      const response = await updateAdmin(_id, data);
      if (response.data) {
        navigate("/admins");
      } else {
        toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "ressayez plus tard",
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onSubmit = async (values, actions) => {
    await _updateAdmin(values);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          values,
          errors,
          touched,
          setFieldValue,
        }) => {
          const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
          const getFormErrorMessage = (name) => {
            return (
              isFormFieldValid(name) && (
                <small className="p-error">{errors[name]}</small>
              )
            );
          };
          return (
            <>
              {/* name admin */}
              <CustomInput
                label="nom et prénom"
                placeholder="nom et prénom"
                handleChange={handleChange}
                value={values.name}
                name="name"
                isFormFieldValid={isFormFieldValid}
                getFormErrorMessage={getFormErrorMessage}
              />

              {/* email */}
              <CustomInput
                label="émail"
                placeholder="émail"
                handleChange={handleChange}
                value={values.email}
                name="email"
                isFormFieldValid={isFormFieldValid}
                getFormErrorMessage={getFormErrorMessage}
              />

              <div className="mb-3 flex justify-content-end w-full">
                <Button
                  onClick={handleSubmit}
                  label={isSubmitting ? "loading" : "modifier"}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  className="w-auto p-button-sm p-button-warning"
                  icon="pi pi-pencil"
                  type="submit"
                />
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
}

export default UpdateAdminForm;
