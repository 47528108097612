export function getFirstTrueRoleOrStatistics(roles) {
  if (roles["statistics"] && roles["statistics"]["read"]) {
    return "/";
  } else {
    for (let role in roles) {
      let allTrue = true;
      for (let prop in roles[role]) {
        if (!roles[role][prop]) {
          allTrue = false;
          break;
        }
      }
      if (allTrue) {
        return "/" + role;
      }
    }
    return false;
  }
}
