import React,{ memo, useEffect, useRef, useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import CustomInput from '../../CustomInput'
import { Dialog } from 'primereact/dialog'
import { Divider } from 'primereact/divider'
import { FamilleService } from '../../../service/FamilleService'
import { DataTable } from 'primereact/datatable'
import NoData from '../../NoData'
import { Column } from 'primereact/column'


const PriceBlock = ({
  values,
  handleChange,
  isFormFieldValid,
  getFormErrorMessage,
  familyDiscount,
  setFamilyDiscount
}) => {
return (
  <div className='card'>
    <p className='font-bold mb-2'>Prix</p>
        <CustomInput
        label='Prix initial'
        bold={false}
        handleChange={handleChange}
        value={values.priceProduct}
        name='priceProduct'
        isFormFieldValid={isFormFieldValid}
        getFormErrorMessage={getFormErrorMessage}
        keyfilter='pnum'/>

        <Divider/>

        <DiscountModal familyDiscount={familyDiscount} setFamilyDiscount={setFamilyDiscount}/>
        
        {/* <CustomInput
        bold={false}
        label='Prix solde (prix de vente)'
        handleChange={handleChange}
        value={values.discountPrice}
        name='discountPrice'
        isFormFieldValid={isFormFieldValid}
        getFormErrorMessage={getFormErrorMessage}
        keyfilter='pnum'
        /> */}
  </div> 
)
}

export default memo(PriceBlock)

const DiscountModal=({familyDiscount, setFamilyDiscount})=>{

  const familleService = new FamilleService()
  const dt = useRef(null)
  const [visible, setVisible] = useState(false);
  const [loading, setloading] = useState(true);

  const getData = async() => {
    let _famillies = []
    const res = await familleService.getFamilyTypes()
    if(res.data){
      res.data.map((item)=>
      _famillies.push({family: item._id, nameFamily: item.nameFamily, percentage:0})
      )
      if(familyDiscount.length > 0){
        let family
        _famillies.map((item, index) => {
          family = familyDiscount.find((fm) => fm.family == item.family)
          if(family) _famillies[index].percentage = family.percentage
        })
      }
      setFamilyDiscount(_famillies)
      setloading(false)

    }else(
      alert('erreur ressayez plus tard')
    )
  }

  useEffect(()=>{
    if(visible) getData()
  },[visible])
 

  const numberEditor = (options) => {
    return <InputText keyfilter={"pnum"}
    value={options.value} className='p-inputtext-sm w-full'
    onChange={(e) => options.editorCallback(e.target.value)} />;
  };

    // EDIT ROW
    const onRowEditComplete = (e) => {
      let newFamilly = [...familyDiscount];
      let { newData, index } = e;
      if(newData.percentage == "")
        newData.percentage = 0

      newFamilly[index] = newData;
      console.log(newFamilly)
      setFamilyDiscount(newFamilly);
    };
 


  return (
      <> 
      <div 
      onClick={()=>setVisible(true)}
      style={{cursor:'pointer'}} className='w-full flex align-items-center justify-content-start'>
        <i className='pi pi-percentage' style={{color:'#6366F1'}}></i>
        <div><p style={{color:'#6366F1'}} className='ml-2'>{`appliquer remise`}</p> </div>
      </div>

      <Dialog
      header={`Applique remise par famille de client`} 
      draggable={false}
      visible={visible} 
      style={{ width: '80vw' }} 
      onHide={() => setVisible(false)} >
         
         <DataTable 
         editMode="row"
         onRowEditComplete={onRowEditComplete}
          size='small' 
          stripedRows ref={dt}  
          value={familyDiscount} 
          responsiveLayout="scroll"
          loading={loading} 
          rowHover 
          dataKey="family" 
          rows={10} 
          className="datatable-responsive"
          emptyMessage={<NoData label='Aucune famille disponible' />}>
                  <Column field="nameFamily" header="famille"></Column>
                  <Column  header="remise (%)" field="percentage" editor={(options) => numberEditor(options)}></Column>
                  <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
          </DataTable>

      </Dialog>
      </>
  )
}

