import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { statusCheck } from './orders/statusColor';
import { Button } from 'primereact/button';
//components
import NoData from '../components/NoData';
import ImageTemplate from '../components/orders/ImageTemplate';
import MarkAsDelivered from '../components/RedeemGifts/markAsDelivered';
import { RedeemGiftService } from '../service/RedeemGiftsService';


const RedeemGifts = () => {
    const redeemGiftService = new RedeemGiftService()
    const [redeemGifts, setRedeemGifts] = useState(null);
    const [loading, setLoading] = useState(true);


    const dt = useRef(null);

    async function getData(){
        setLoading(true)
        const response = await redeemGiftService.getRedeemGift()
        if(response.data){
            setRedeemGifts(response.data)
        } else {
            console.log(response.error);
        }
        setLoading(false)

    }
    

    useEffect(() => {
        getData()
    }, []);

    async function updateStatus (id, status){
        const response = await redeemGiftService.updatestatus(id, status)
        if(response.data){
            getData()
        } else {
            console.log(response.error)
        }
    }

    const dateTemplate=(rowData)=>{
        const date = new Date(rowData.createdAt).toLocaleDateString("fr")
        const time = new Date(rowData.createdAt).toLocaleTimeString("fr")
        return (
            <p>{date} {time}</p>
        )
    }


    const statusBodyTemplate=(rowData)=>{
        const severity = statusCheck(rowData.status)
        return(
            <div style={{backgroundColor:severity,borderRadius:12,display:'inline-block'}} className='pr-2 pl-2'>
                <p className='text-sm font-medium lowercase' style={{color:'#fff'}}>{rowData.status}</p>
            </div>
        )
    }



    return (
    <div className="grid crud-demo">
            <div className="col-12">
                <div>

                <div className='mb-4 flex alignItems-center justify-content-between'>
                    <div className='flex align-items-center justify-content-center'>
                        <p className='text-2xl font-semibold'>Cadeaux commandés</p>
                    </div>
                </div>

                <div className='card'>
                    <DataTable size='small' 
                    stripedRows 
                    ref={dt} 
                    value={redeemGifts} 
                    responsiveLayout="scroll"
                    loading={loading} 
                    rowHover
                    paginator
                    dataKey="id" 
                    rows={25} 
                    rowsPerPageOptions={[5, 10, 25, 50]} 
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Afficher {first} à {last} de {totalRecords} cadeaux commandés"
                    emptyMessage={<NoData label='Aucun cadeau commandés'/>}>
                            <Column field="gift.giftName" header="nom de cadeau"/>
                            <Column field="customer.nameEntreprise" header="nom de client"/>
                            <Column field="gift.photos" header="image" body={(rowData)=><ImageTemplate photos={[rowData?.gift?.photos[0]]}/>} /> 
                            <Column field="createdAt" header="date" body={dateTemplate} />
                            <Column field="status" style={{minWidth:'90px'}} header="statut" body={statusBodyTemplate} />
                            <Column body={(rowData) => 
                            rowData.status == "EN COURS" &&
                                <MarkAsDelivered rowData={rowData} updateStatus={updateStatus}/>} 
                            />

                    </DataTable>
                </div>  
                </div>
            </div>
        </div>
  )
}

export default RedeemGifts