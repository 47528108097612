import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { AdminService } from "../../service/AdminService";

const DeleteAdmin = ({ name, adminId }) => {
  const { deleteAdmin } = new AdminService();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const _toast = useRef(null);

  const confirmDelete = () => {
    setDeleteDialog(true);
  };

  const hideDeleteDialog = () => {
    setDeleteDialog(false);
  };

  const handleDelete = async () => {
    try {
      const res = await deleteAdmin(adminId);
      if (res.data) {
        hideDeleteDialog();
        window.location.reload();
      } else {
        _toast.error(res?.error?.response?.data?.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const deleteDialogFooter = (
    <>
      <Button
        label="non"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteDialog}
      />
      <Button
        label="oui"
        icon="pi pi-trash"
        className="p-button-text p-button-danger"
        onClick={handleDelete}
      />
    </>
  );

  return (
    <>
      <Button
        onClick={() => confirmDelete()}
        tooltip="supprimer"
        icon="pi pi-trash"
        className="p-button-text p-button-danger p-button-rounded"
      />

      <Dialog
        visible={deleteDialog}
        draggable={false}
        style={{ width: "450px" }}
        header="Confirmer"
        modal
        footer={deleteDialogFooter}
        onHide={hideDeleteDialog}
      >
        <div className="flex align-items-center">
          <i
            className="pi pi-exclamation-triangle"
            style={{ fontSize: "2rem" }}
          />
          <div className="ml-3 flex flex-column align-items-start justify-content-center">
            <span className="mt-2">
              Voulez-vous vraiment supprimer <b>{name}</b>?
            </span>
            <span>{"cette action est irréversible"}</span>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DeleteAdmin;
