import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import { Field, Formik } from 'formik';
import * as Yup from 'yup'
import { InputSwitch } from 'primereact/inputswitch';
import { TreeSelect } from 'primereact/treeselect';
import { v4 as uuidv4 } from 'uuid';
import { FileUpload } from 'primereact/fileupload';
import CustomSwitch from '../CustomSwitch';
import CustomInput from '../CustomInput';
import { CategoryService } from '../../service/CategoryService';
import { ImageService } from '../../service/ImageService';


const EditCategory = ({rowData, updateCtagory}) => {

    const { _id, nameCategory, parentCategory, photo, active } = rowData
    const imageService = new ImageService()
    const categoryService = new CategoryService()
    const [categories, setCategories] = useState([])
    const [loadingCategories, setLoadingCategories] = useState(false)
    const [deletedImage, setDeletedImage] = useState(null)
    const [selectedImage, setSelectedImage] = useState(null)
    const [dialogVisibility, setDialogVisibility] = useState(false);
    const [loading,setLoading] = useState(false);
    const hideDialog = () => setDialogVisibility(false)
    const openModal = () => setDialogVisibility(true)

    useEffect(() => {
        if(dialogVisibility){
            getCategories()
        }   

        return () => {
            setSelectedImage(null)
            setDeletedImage(null)        
        }
    },[dialogVisibility])

    const initialValues = {
        nameCategory,
        parentCategory: parentCategory == null ? "" : parentCategory,
        photo: photo.url,
        active,
    } 
    
    const validationSchema = Yup.object().shape({
        nameCategory: Yup.string().required('Nom de catégorie obligatoire'),
        parentCategory: Yup.string()
                        .strict(true)
                        .notOneOf([_id], "Veuillez sélectionner une autre catégorie comme parent"),
        photo: Yup.string().required('Image obligatoire'),
        active: Yup.boolean()
    })

    const getCategories = async () => {
        setLoadingCategories(true)
        let response = await categoryService.getCategories()
        if(response.data){
            const tree = categoryService.buildTree(response.data)
            setCategories(tree)
        }
        setLoadingCategories(false)
    }

    const onSubmit = async (values, actions) => {
        const { nameCategory, parentCategory, photo, active } = values
        const imageService = new ImageService()
        let url_image
        if(deletedImage){
            await imageService.deletImage(deletedImage)
            const blob = await fetch(photo).then(r => r.blob()); //get blob url
            url_image = await imageService.uploadImage(blob,`categories/${nameCategory}-${uuidv4()}`) // upload to firebase and get url
        }
        let data = {
            nameCategory, 
            parentCategory: parentCategory == "" ? null : parentCategory, 
            photo: {url: url_image ? url_image.data : photo}, 
            active
        }
        
        await updateCtagory(_id, data)
        setSelectedImage(null)
        setDeletedImage(null)
        hideDialog()
    }


    //when an image added
    const onTemplateSelect = (e, setFieldValue) => {
        const objectURL = e.files[0]?.objectURL
        setSelectedImage(objectURL)
        setFieldValue('photo',objectURL)
    }

    //when an image removed
    const onTemplateRemove = (setFieldValue) => {
        setSelectedImage(null)
        setFieldValue('photo', '')
    }

    //when all images removed
    const onTemplateClear = (setFieldValue) => {
        setSelectedImage(null)
        setFieldValue('photo', '')
    }

    

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {
                 selectedImage == null && chooseButton
                }
                {cancelButton}
            </div>
        )
    } 


  return (
    <>
    <Button icon="pi pi-pencil" className="p-button-sm p-button-rounded p-button-text p-button-warning" onClick={openModal} />
     {/* MODAL */}
     <Dialog draggable={false} visible={dialogVisibility} breakpoints={{'1900px': '60vw', '640px': '100vw'}}
            header="modifié catégorie" modal 
            className="p-fluid" onHide={hideDialog}>
            
            <Formik 
            initialValues={initialValues} 
            validationSchema={validationSchema} 
            onSubmit={onSubmit}>

            {({ handleChange,
                handleBlur, 
                handleSubmit,
                setFieldValue,
                isSubmitting, 
                values,
                errors, 
                touched })=>{
                    console.log(errors)
               
                const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
                const getFormErrorMessage = (name) => {
                    return isFormFieldValid(name) && <small className="p-error">{errors[name]}</small>;
                };

                return(
                <>
                <div className='mb-3'>

                <CustomInput
                    label='nom de catégorie'
                    placeholder='nom de catégorie'
                    handleChange={handleChange}
                    value={values.nameCategory}
                    name='nameCategory'
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                />

                <div className='mb-3'>
                    <p className="mb-2 font-bold">Catégorie parente</p>   
                <div className="flex align-items-center">
                <TreeSelect
                    value={values.parentCategory} 
                    onChange={handleChange("parentCategory")} 
                    options={categories} 
                    disabled={loadingCategories}
                    className="md:w-20rem w-full" 
                    placeholder={loadingCategories ? "loading" : "select catégorie"}
                    filter
                />
                    <Button
                        onClick={() => setFieldValue("parentCategory", '')}
                        icon="pi pi-trash"
                        className="ml-2 p-button-danger"
                        aria-label="filter"
                    />
                </div>  
                </div>
                { getFormErrorMessage('parentCategory')}
                
                {/*template image */}
                {deletedImage == null &&
                    <div style={{width:150,height:150}} 
                    className="relative field flex align-items-center ">
                    <img src={values.photo} alt={values.nameCategory} width='150' height='150'/>
                    <div 
                    onClick={()=> setDeletedImage(values.photo)}
                    className='flex align-items-center justify-content-center'
                    style={{position:'absolute',top:5,right:5,height:40,width:40,borderRadius:20,cursor:'pointer',backgroundColor:'#fff'}}>
                            <i className={'pi pi-trash'} 
                            style={{color:'#f00'}}></i>
                    </div>
                    </div>
                }                
                {deletedImage &&
                <div>
                    <h5>Images</h5>
                    <FileUpload name="demo[]" url="https://primefaces.org/primereact/showcase/upload.php" 
                        multiple={false}
                        headerTemplate={headerTemplate}
                        accept="image/*" 
                        maxFileSize={2000000}
                        onSelect={(e) => onTemplateSelect(e,setFieldValue)}
                        onError={(e) => onTemplateClear(setFieldValue)} 
                        onClear={(e) =>onTemplateClear(setFieldValue)} 
                        onRemove={(e) => onTemplateRemove(setFieldValue)}
                        emptyTemplate={<p className="m-0">Faites glisser et déposez les images ici pour les télécharger.</p>} 
                        />
                        { getFormErrorMessage('photo')}
                    
                    </div>         
                } 
                
                {/* STATUS */}
                <div className='mb-3'>
                <CustomSwitch
                    label='status'
                    name='active'
                    active={values.active} 
                    handleChange={handleChange}/>
                </div>
                
            </div>

            <div className='mt-4 flex align-items-center justify-content-end'>
                <Button 
                label="annuler" 
                className='w-auto p-button-outlined p-button-sm p-button-secondary mr-2' 
                icon="pi pi-times" onClick={hideDialog}/>
                <Button 
                disabled={isSubmitting}
                onClick={handleSubmit} 
                label={isSubmitting?'loading':'modifier'}
                loading={isSubmitting} 
                className='w-auto p-button-sm p-button-warning' 
                icon="pi pi-pencil"  
                type='submit' />
            </div>
            </>
                )
            }}
            </Formik>
            
        </Dialog>
    </>
  )
}

export default EditCategory

