import React, { createContext, useState, useEffect } from "react";
import Api from "../utils/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AdminService } from "../service/AdminService";
import { UnauthorizedErrorTypes } from "../utils/Consts";
import { signout } from "../utils/SignOut";
// -------------------------

// User data context
export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState({});
  const [isAuth, setisAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [userToken, setUserToken] = useState(
    window.localStorage.getItem("userToken")
  );

  const listenToResponseAxios = () => {
    Api.interceptors.response.use(
      (response) => {
        if (response.headers.refreshtoken) {
          window.localStorage.setItem(
            "userToken",
            response.headers.refreshtoken
          );
          //set the refresh token to axios instance header
          Api.defaults.headers.common["Authorization"] =
            "Bearer " + response.headers.refreshtoken;
          setUserToken(response.headers.refreshtoken);
        }
        return response;
      },
      (error) => {
        console.log("error: ",error)
        if (error.response) {
          const status = error.response.status;
          if (status === 403 || status === 401) {
            console.log("you don't have permission");
            if (error.response.data) {
              const errorType = error.response.data.errorType;
              if (
                errorType == UnauthorizedErrorTypes.BLACKLIST_TOKEN ||
                errorType == UnauthorizedErrorTypes.TOKEN_INVALID ||
                errorType == UnauthorizedErrorTypes.TOKEN_EXPIRD ||
                errorType == UnauthorizedErrorTypes.USER_INACTIF
              ) {
                console.log(errorType);
                signout();
              } else if (
                errorType == UnauthorizedErrorTypes.UNAUTHORIZED_ROLE
              ) {
                toast.error("non autorisé");
              }
            }
          } else {
            if (error.response.data.message) {
              toast.error(error.response.data.message);
            } else {
              toast.error("Internal server error");
            }
          }
        } else if (error.request) {
          toast.error("Error");
        } else {
          // Something happened in setting up the request that triggered an Error
          toast.error("Error send request");
        }
      }
    );
  };

  const getData = async () => {
    // Get user token
    let token = null;
    // Get user data
    try {
      token = window.localStorage.getItem("userToken");
      if (token) {
        Api.defaults.headers.common["Authorization"] = "Bearer " + token;
        const { _id } = await JSON.parse(
          window.localStorage.getItem("userInfo")
        );
        const userType = window.localStorage.getItem("userType");
        let response;
        if (userType === "ADMIN") {
          const adminService = new AdminService();
          response = await adminService.getAdminInfos(_id);
        }
        if (!response.error) {
          if (response.data && response.data.active && !response.data.deletedAt) {
            setUserInfo(response.data);
            setisAuth(true);
            setIsLoading(false);
          } else {
            window.localStorage.clear();
            setisAuth(false);
            setIsLoading(false);
          }
        }
      } else {
        setisAuth(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    Api.defaults.headers.common["Authorization"] = "Bearer " + userToken;
  }, [userToken]);

  useEffect(() => {
    listenToResponseAxios();
    getData();
  }, []);
  return (
    <>
      <UserContext.Provider
        value={{
          userInfo,
          isAuth,
          setisAuth,
          setUserInfo,
          setUserToken,
          isLoading,
          setIsLoading,
        }}
      >
        {children}
      </UserContext.Provider>
    </>
  );
};
