import React from 'react'
import { Chips } from 'primereact/chips'

const TagsBlock = ({tags,newTags,setNewTags,setFieldValue}) => {

    const spliceTag=(index)=>{
        const arr=[...tags]
        arr.splice(index,1)
        setFieldValue('tags',arr)
    }

  return (
    <div className='card p-fluid justify-content-center'>
    <p className='font-bold mb-4'>Tags</p>
    <div className='flex my-4 flex-wrap'>
    {tags.map((tag,index) => 
        <div className='flex px-2 py-1 m-1 align-items-center' 
        style={{backgroundColor:'#eee',borderRadius:5}}>
            <div>
                <p>{tag}</p>
            </div>
            <div 
            className='ml-2' style={{cursor:'pointer'}} 
            onClick={()=>spliceTag(index)} >
                <i className='pi pi-times-circle' 
                style={{color:'#838584',fontSize:'15px'}}></i>
            </div>
        </div>
    )}
      </div>
        <p>insérer des nouveaus tags</p>
        <Chips
            name='tags'
            value={newTags}
            onChange={(e) => setNewTags(e.value)} separator="," />
    </div>
  )
}

export default TagsBlock