import React,{ useState, useEffect, useRef } from 'react'
import Emails from '../components/@settings/emails/Emails'
import Phones from '../components/@settings/phones/Phones'
import Terms from '../components/@settings/terms/Terms';
import CartControl from '../components/@settings/min_quantity/CartControl';
import { SettingsService } from '../service/SettingsService';

const Settings = () => {
  const settingsService = new SettingsService()
  const [establishmentInfo, setEstablishmentInfo] = useState({})
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    getData()
  },[])

  async function getData(){
    const response = await settingsService.getEstablishmentInfo()
    if(response.data){
      response.data.length > 0 && setEstablishmentInfo(response.data[0])
    } else {
      console.log(response.error)
    }
    setLoading(false)
  }

  async function addEstablishmentInfo(_establishmentInfo){

    if(Object.keys(establishmentInfo).length > 0){
      _establishmentInfo._id = establishmentInfo._id
    }
    const response = await settingsService.addEstablishmentInfo(_establishmentInfo)
    if(response.data){
      getData()
    } else {
      console.log(response.error)
    }
  }

  async function _deleteEstablishmentInfo(_establishmentInfo){
    _establishmentInfo._id = establishmentInfo._id
    const response = await settingsService.deleteEstablishmentInfo(_establishmentInfo)
    if(response.data){
      getData()
    } else {
      console.log(response.error)
    }
  }

  return (
    <div>
      
      
      <div className='mb-5 flex alignItems-center'>
          <p className='text-2xl font-semibold'>Paramètres</p>
      </div>


    <div className="grid">
      {!loading && 
        <div className="grid">
        <Phones phones = {establishmentInfo?.phonesNumbers} addEstablishmentInfo={addEstablishmentInfo} deleteEstablishmentInfo={_deleteEstablishmentInfo}/>
        <Emails emails = {establishmentInfo?.emails} addEstablishmentInfo={addEstablishmentInfo} deleteEstablishmentInfo={_deleteEstablishmentInfo}/>
        <Terms termsConditions = {establishmentInfo?.termsConditions} addEstablishmentInfo={addEstablishmentInfo} deleteEstablishmentInfo={_deleteEstablishmentInfo} />
        {/* <CartControl/> */}
        </div>    
      }
    </div>  
    </div>  
  )
}

export default Settings