import React,{useState,useEffect} from 'react'
import { useLocation , useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import BreadCrump from '../../components/BreadCrump';
import { LoadingComponent } from '../../components/LoadingComponents';
//services
import { VariantService } from '../../service/VariantService'
import { OptionsService } from '../../service/OptionsService'
import { CategoryService } from '../../service/CategoryService';

const PreviewProduct = () => {

  const optionsService = new OptionsService()
  const variantService = new VariantService()
  const categoryService = new CategoryService()

  const params = useParams();
  const location = useLocation();
  const [singleVariant, setSingleVariant] = useState({})
  const [options, setOptions] = useState([])
  const [variants, setVariants] = useState([])
  const [loading, setLoading] = useState(true)
  const toast = React.useRef(null);
  let { _id, nameProduct, photos,
    quantityStock, totalOrdered, active, priceProduct,
    description, category, hasVariant, underCategory,
    minOrderQuantity, tags } = location?.state

  useEffect(()=>{
    if(hasVariant){
      getOptions()
      getVariants()
    }else{
      getProductSku()
    }
  },[params?.id])
  
  console.log(underCategory)


  async function getProductSku(){
    const response = await variantService.getVariantsByProduct(_id)
    if(response.data){
        setSingleVariant(response.data[0])
        console.log(response.data)
    } else {
        toast.current.show({severity:'error', summary: 'Error', detail:'erreur de serveur, réessayez plus tard', life: 3000});
        console.log(response.error)
    }
    setLoading(false)
    }

  // GET OPTIONS
  const getOptions = async ()=>{
    const response = await optionsService.getOptions(_id)
    if(response.data){
      setOptions(response.data)
    }else {
        toast.current.show({severity:'error', summary: 'Error', detail:'erreur de serveur, réessayez plus tard', life: 3000});
        console.log(response.error)
    }
    setLoading(false)
  }

  // API CALL FOR VARIANTS BY PRODCUT ID
  const getVariants=async()=>{
  const response = await variantService.getVariantsByProduct(_id)
  if(response.data){
      setVariants(response.data)
  }else{
      toast.current.show({severity:'error', summary: 'Error', detail:'erreur des options essayer plus tard', life: 3000});
      console.log(response.error)
  }
}

  

  const breadCrumpItems={
    href:'products',
    parentName:'produits',
    currentPage:'détail de produit'
  }

 
  if(loading){ 
    return (
      <LoadingComponent/>
    )
  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
      <Toast ref={toast}/>
      <BreadCrump breadCrumpItems={breadCrumpItems} />

      <div className="card overflow-hidden">

      <div className="grid">
      <div className="col-12 md:col-6">
      {!hasVariant && <TextData head='sku' value={singleVariant?.reference} />}
      <TextData head='nom de produit' value={nameProduct} />
      <TextData head='description' value={description} />
      <TextData head='catégorie' value={<p>{categoryService.displayCategories(category)}</p>} />
      {underCategory && <TextData head='sous catégorie (marque)' value={<p>{categoryService.displayUnderCategories(underCategory)}</p>} />}
      {!hasVariant && <TextData head='quantité de stock' value={`${singleVariant?.quantityStock}pcs`} />}
      {!hasVariant && <TextData head='quantité minimal pour commander' value={`${singleVariant?.minOrderQuantity}pcs`} />}
      {hasVariant && <VariantPriceData head='prix' priceProduct={priceProduct} />}
      {!hasVariant && <PriceData head='prix' priceProduct={singleVariant?.priceProduct}/>}
      <TextData head='nombre de commande' value={totalOrdered} />
      {tags && <TagsData tags={tags} />}
      </div>
      
      <div className="col-12 md:col-6">

      <ImagesData photos={photos} />
      <StatusData head='statut' value={active}/>
      {hasVariant && <OptionsData options={options}/>}
      <div>
      
      </div>

      </div>

      <div className='col-12'>
      {hasVariant && <VariantsData variants={variants} />}
      </div>


      </div>

      </div>
      </div>
    </div>
  )
}

  export default PreviewProduct

  const TextData=({head,value})=>{
    return (
      <div className='flex flex-column mb-3'>
        <div> 
        <p className='font-bold'>
          {head}
        </p>
        </div>
        <div><p>{value}</p></div>
      </div>
    )
  }

  const StatusData=({head,value})=>{
    return (
      <div className='flex flex-column mb-3'>
        <div> 
        <p className='font-bold'>
          {head}
        </p>
        </div>
        <div>
          <p className='font-bold' style={{color:value?'#05a11c':'#f00'}}>{value ? 'actif' : 'inactif' }</p>
        </div>
      </div>
    )
  }

  const PriceData=({priceProduct})=>{
    return (
      <div className='flex flex-column mb-3'>

        <div> <p className='font-bold'>prix</p></div>

        <p>{priceProduct}dh</p>

      </div>
    )
  }

  const VariantPriceData=({head,priceProduct})=>{
    return (
      <div className='flex flex-column mb-3'>
        <div> 
        <p className='font-bold'>
          {head}
        </p>
        </div>
        <div>
          <p>{`${priceProduct} dh`}</p>
        </div>
      </div>
    )
  }

  const TagsData=({tags})=>{
    return (
      <>
      {
        tags.length > 0
        && <div className='flex flex-column mb-3'>
        <div> 
        <p className='font-bold'>
          tags
        </p>
        </div>
        <div className='flex'>
          {tags.map(tag => 
            <div className='px-2 py-1 mr-1' style={{backgroundColor:'#eee',borderRadius:3}}>
              <p>{tag}</p>
            </div>
          )}
        </div>
      </div>}
      </>
    )
  }

  const OptionsData=({options})=>{
    return(
    <>
      <p className='font-bold'>
          Options
        </p>
        { 
          options.map(({_id,nameOption,values},index)=>{
            const checkHex = values.length > 0 && values[0].name.charAt(0)
            return( 
            <>
            {values.length > 0
            && 
            <div key={_id} 
                style={{border:'1px solid #eee',borderRadius:'5px'}} 
                className='p-2 mb-2 flex align-items-center'>
                
                <div  className='flex flex-column w-full'>
                  <p>{nameOption}</p>
                
                

                <div className='flex flex-wrap'>
                {
                  values.map(({name},index)=>(
                    <div  key={index.toString()} className='mr-1'> 
                      { checkHex === '#'
                      ? 
                      <div style={{backgroundColor:'#DEE2E6',borderRadius:'20px'}} 
                      className='flex align-items-center justify-content-center mr-2 mb-2 px-2 py-2'>
                      
                      <div style={{backgroundColor:name,height:20,width:20,borderRadius:10}} />        

                    

                      </div>
                      : 
                      <div style={{backgroundColor:'#DEE2E6',borderRadius:'20px'}} 
                      className='flex align-items-center justify-content-center mr-2 mb-2 px-2 py-2'>
                      
                      <div>
                        <p className='font-normal text-sm' style={{color:'#1f1f1f'}}>{name}</p>
                      </div>

                    

                      </div>
                      } 
                    </div>
                  ))
                }

                </div>

              </div>   

              </div>
              }
            </>
            )
            
          }) 
        }
        </>
    )
  }

  const ImagesData=({photos})=>{
    return(
      <>
      
      {photos.length > 0
      &&
      <div className='flex flex-column mb-3'>
        <p className='font-bold'>images</p>
      <div className='flex-wrap mb-3'>
          {
            photos.map(photo => (
              <img key={photo} src={photo} height='130px' width='130px' />
            ))
          }
        </div>
        </div>
        }
        </>
    )
  }

  const VariantsData=({variants})=>{

  const footer = `nombre total de variantes est ${variants.length}`;

  const valuesBody=(rowData)=>{
      
      const renderOptionValue=(option)=>{
          if(option !== null){
              const checkHex = option.value.charAt(0) 
              if(checkHex === '#'){
                  return <div style={{backgroundColor:option.value,height:15,width:15,borderRadius:15/2}} />
              }else{
                  return <div> 
                          <p className='font-medium'>{`${option.value}`}</p>
                      </div>
              }
          }else return 
      }

      return(
          <div className='flex align-items-center'>
              {renderOptionValue(rowData.option1)}
              {rowData.option2 !== null && <div className='px-2'> <p>{`/`}</p> </div>}
              {renderOptionValue(rowData.option2)}
              {(rowData.option3 !== null && rowData.option2 !== null) && <div className='px-2'> <p>{`/`}</p> </div>}
              {renderOptionValue(rowData.option3)}
              {rowData?.new
              &&<div className='px-2 ml-2' 
              style={{backgroundColor:'#524aff',borderRadius:10}}>
                  <p className='text-xs' style={{color:'#fff'}}>nouveau</p>
              </div>
              }
          </div>
      )
  }
  
    const rowClass = (data) => {
        return {
            'row-class': Number(data.priceProduct) === Number(data.discountPrice)
        }
    }

    return(
      <>
       <DataTable
             stripedRows
             editMode="row"
             scrollable
             selectionMode="checkbox"
             rowClassName={rowClass}
             dataKey={'_id'}
             scrollHeight="400px"
             value={variants}
             footer={footer} 
             size="small"
             responsiveLayout="scroll">
                    <Column  frozen style={{minWidth:'150px'}} headerStyle={{fontWeight: 300}} header="variant" body={valuesBody} ></Column>
                    <Column style={{minWidth:'120px'}} headerStyle={{fontWeight: 300}} field="reference" header="reference" ></Column>
                    <Column style={{minWidth:'120px'}} headerStyle={{fontWeight: 300}} field="quantityStock" header="stock"></Column>
                    <Column style={{minWidth:'120px'}} headerStyle={{fontWeight: 300}} field="minOrderQuantity" header="qte minimal" ></Column>
                    <Column style={{minWidth:'120px'}} headerStyle={{fontWeight: 300}} field="priceProduct" header="prix original" ></Column>
                    <Column style={{minWidth:'120px'}} headerStyle={{fontWeight: 300}} field="discountPrice" header="prix de solde" ></Column>
             </DataTable>
      </>
    )
  }