import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useRecoilState } from 'recoil';
import { lazyParamsBrand } from '../atoms/LazyParamsBrand';
//COMPONENTS
import AddBrand from '../components/brands/AddBrand'; 
import EditBrand from '../components/brands/EditBrand';
import ImageTemplate from '../components/orders/ImageTemplate';
import NoData from '../components/NoData';
//SERVICES
import { BrandsService } from '../service/BrandsService';

const Brands = () => {

    const brandService = new BrandsService()
    
    const STATUS = [
        {label: 'tout', value: null},
        {label: 'actif', value: true},
        {label: 'inactif', value: false},
    ]

    const [brands, setBrands] = useState(null);
    const [lazyParams, setLazyParams] = useRecoilState(lazyParamsBrand);
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(
        parseInt(lazyParams.totalRecords)
    );
    const dt = useRef(null);

    console.log("totalRecords: ",totalRecords)
    
    // get products
    useEffect(() => {
        lazyLoadData();
    },[lazyParams])


    //load products
    async function lazyLoadData (){
        setLoading(true);
        const queryParameters = generateQueryParams()
        const response = await brandService.getBrandsWithPagination(queryParameters);
        if(response.data){
            setBrands(response.data.brands)
            setTotalRecords(response.data.totalDocuments)
        } else {
            console.log(response.error)
        }
        setLoading(false)
    }

    const generateQueryParams = () => {
        const {
            first, rows, page, 
            filters : {
                active, brandName
            }, 
            sortfield, sortorder 
        } = lazyParams
        const _sortfield = sortfield === null ? '_id' : sortfield
        let parameters = `limit=${rows}&totalSkip=${first}&currentPage=${page}&totalDocuments=${totalRecords}&sortfield=${_sortfield}&sortorder=${sortorder}`
        if(active != null) parameters+= `&active=${active}`
        if(brandName) parameters+= `&brandName=${brandName}`

        return parameters
    }

    const createBrand = async (data) => {
        const response = await brandService.createBrand(data);
        if(response.data){
            lazyLoadData()
        } else {
            console.log(response.error);
        }
    }

    const updateBrand = async (id,data) => {
        const response = await brandService.updateBrand(id,data)
        if(response.data){
            lazyLoadData()
        } else {
            console.log(response.error);
        }
    }


    const statusBodyTemplate = (rowData) => {
        const CustomBadge=(bg,status)=>{
            return(
                <div style={{backgroundColor:bg,borderRadius:10}} className='px-2 w-max'>
                    <p className='text-sm'>{status}</p>
                </div>
            )
        }
        return (
            <>
            {
              rowData.active
              ? <> {CustomBadge('#A1D8C1','Actif')}</>
              : <> {CustomBadge('#d8a1a1','Inactif')}</>
            }
            </>
          )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex">
                <EditBrand rowData={rowData} updateBrand={updateBrand}/>
            </div>
        );
    }

    const onPage = (event) => {
        setLazyParams({
            ...lazyParams,
            first: event.first,
            rows: event.rows,
            page: event.page + 1
        })
    }

    const onSort = (event) => {
        console.log("event sort: ",event)
        let sortorder;
        if(lazyParams.sortfield == null || lazyParams.sortfield != event.sortField){
          sortorder = 1
        } else {
          sortorder = lazyParams.sortorder * -1
        }
        setLazyParams({
          first: 0,
          rows: 10,
          page: 1,
          filters: {...lazyParams.filters},
          sortfield: event.sortField,
          sortorder: sortorder,
        });
    };


    const OnSearchBrandWithName = (event) => {
        setLazyParams({
            first: 0,
            rows: 10,
            page: 1,
            filters : {
                ...lazyParams.filters,
                brandName: event.target.value,
            },
            sortfield: null,
            sortorder: -1
        })
    }

    const onChangeStatus = (event) => {
        setLazyParams({
            first: 0,
            rows: 10,
            page: 1,
            filters : {
                ...lazyParams.filters,
                active: event.value,
            },
            sortfield: null,
            sortorder: -1
        })
    }



    const statusFitler = () => {
        return <Dropdown 
                  value={lazyParams.filters.active}
                  placeholder='Statut'
                  options={STATUS} 
                  onChange={onChangeStatus}
                  className="p-column-filter p-inputtext-sm" />; 
    }

    const brandNameFilter = () => {
        return(
          <InputText 
          className="mr-2 p-inputtext-sm" 
          placeholder='nom de marque' 
          onChange={OnSearchBrandWithName} 
          value={lazyParams.filters.brandName}/>
        )
    }




  return (
    <div className="relative grid crud-demo">
            <div className="col-12">
                <div>
                    <div className='mb-4 flex alignItems-center justify-content-between'>
                        <div className='flex align-items-center justify-content-center'>
                            <p className='text-2xl font-semibold'>Marques</p>
                        </div>
                        <AddBrand createBrand={createBrand} />
                    </div>

                    <div className='card'>
                    <div className='overflow-x-auto mb-4 flex align-items-center justify-content-start'>
                        {brandNameFilter()}
                        {statusFitler()}
                    </div>

                <DataTable
                    paginator 
                    lazy
                    first={lazyParams.first}
                    loading={loading}
                    rows={lazyParams.rows} 
                    totalRecords={totalRecords} 
                    onPage={onPage} 
                    onSort={onSort}
                    filterDisplay="row"
                    stripedRows 
                    ref={dt} 
                    rowHover
                    rowsPerPageOptions={[10, 25, 50]}
                    responsiveLayout="scroll" 
                    value={brands}
                    size='small'
                    dataKey="_id" 
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} marques"
                    emptyMessage={<NoData label='Aucun marques disponible' />}>
                        <Column sortable field="brandName" header="nom"></Column>
                        <Column field="photo" header="image" body={(rowData)=><ImageTemplate photos={[rowData.photo]}/>}></Column>
                        <Column field="active" header="status" body={statusBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                    </div>

                </div>
            </div>
    </div>
  )
}


export default React.memo(Brands);

