import React from 'react'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'

const CustomInput = ({
    label,
    placeholder,
    isFormFieldValid,
    handleChange,
    value,
    name,
    getFormErrorMessage,
    keyfilter,
    bold=true
}) => {

  return (
    <div className='mb-4 w-full justify-content-center'>
    <p className={bold ? 'font-bold mb-2' : ' mb-2'}>{label}</p>
    <InputText 
    keyfilter={keyfilter}
    placeholder={placeholder}
    className={classNames({ 'p-invalid':  isFormFieldValid(name)})}
    onChange={handleChange(name)}
    value={value}
    />
    {getFormErrorMessage(name)}
    </div>
  )
}

export default CustomInput