import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { TreeSelect } from 'primereact/treeselect';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import * as Yup from 'yup'
import { Formik } from 'formik';

import CustomInput from '../../CustomInput';
import CustomSwitch from '../../CustomSwitch';

import { CategoryService } from '../../../service/CategoryService';
import { BrandsService } from '../../../service/BrandsService';
import { ProductService } from '../../../service/ProductService';

const UpdateMultipleProducts = ({selectedProducts, visibiltyModal, setVisibilityModal,lazyLoadData}) => {

    const [categories, setCategories] = useState([])
    const [brands, setBrands] = useState([])
    const [loadingCategories, setLoadingCategories] = useState(false)
    const [loadingBrands, setLoadingBrands] = useState(false)

    const productsService = new ProductService()
    const categoryService = new CategoryService()
    const brandsService = new BrandsService()
    
    
    const initialValues = {
        mainCategory: "",
        brand: "",
        minOrderQuantity: "",
        maxOrderQuantity: "",
        active: true,
    }
    
    const validationSchema = Yup.object().shape({
        mainCategory: Yup.string(),
        brand: Yup.string(),
        minOrderQuantity: Yup.number()
                            .integer('sans virgule'),
        maxOrderQuantity: Yup.number()
                            .integer('sans virgule'),
    })

    useEffect(() => {
        getCategories()
        getBrands()
    }, [])

    const getCategories = async () => {
        const response = await categoryService.getCategories()
        if(response.data){
            const tree = categoryService.buildTree(response.data)
            console.log(tree)
            setCategories(tree)
            setLoadingCategories(false)
        } else {
            console.log(response.error);
            setLoadingCategories(false)
        }
    }

    const getBrands = async () => {
        const queryParameters = `active=true`
        const response = await brandsService.getAllBrands(queryParameters)
        if(response.data){
            setBrands(response.data)
            setLoadingBrands(false)
        } else {
            console.log(response.error);
            setLoadingBrands(false)
        }
    }    
  
    const onSubmit = async(values, actions) => {
        let newValues  = {...values}
        //convert the empty values to null
        Object.keys(newValues).map((key) => {
            if(!newValues[key] && typeof newValues[key] !== "boolean")
                delete newValues[key]
        })
        for(let i = 0; i < selectedProducts.length; i++){
            await productsService.updatedProduct(selectedProducts[i]._id, newValues)
        }
        setVisibilityModal(false)
        lazyLoadData()
    }

    return (
    <>
     {/* MODAL */}
     <Dialog draggable={false} visible={visibiltyModal} breakpoints={{'1900px': '60vw', '640px': '100vw'}}
            header={`Modifier ${selectedProducts.length} produits`} modal 
            className="p-fluid" onHide={() => setVisibilityModal(false)}>
            
            <Formik 
            initialValues={initialValues} 
            validationSchema={validationSchema} 
            onSubmit={onSubmit}>

            {({ handleChange,
                handleBlur, 
                handleSubmit,
                setFieldValue,
                isSubmitting, 
                values,
                errors, 
                touched })=>{
               
                const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
                const getFormErrorMessage = (name) => {
                    return isFormFieldValid(name) && <small className="p-error">{errors[name]}</small>;
                };

            return(
            <>
            {/* CATEGORY */}
            <div className='mb-3'>
            <p className="mb-2 font-bold">Catégorie</p>   
                <TreeSelect
                    value={values.mainCategory} 
                    onChange={handleChange("mainCategory")} 
                    options={categories} 
                    disabled={loadingCategories}
                    className="md:w-20rem w-full" 
                    placeholder={loadingCategories ? "loading" : "selectionner catégorie"}
                    filter
                />
            </div>
            {getFormErrorMessage("mainCategory")}

            {/* BRAND */}
            <div className='mt-4'>
                <p className="mb-2 font-bold">marque</p>
                <div className='flex align-items-center'>
                    <Dropdown
                        filter
                        value={values.brand} 
                        onChange={handleChange('brand')}
                        placeholder={loadingBrands ? "loading" : "sélectionner marque"}
                        disabled={loadingBrands}
                        options={brands}
                        optionLabel="brandName" 
                        optionValue="_id"
    
                    />
             
                </div>
            </div>
            {getFormErrorMessage("brand")}      

            <div className='mt-4'>
            <CustomInput
                bold={true}
                label='Quantité minimale'
                handleChange={handleChange}
                value={values.minOrderQuantity}
                name='minOrderQuantity'
                isFormFieldValid={isFormFieldValid}
                getFormErrorMessage={getFormErrorMessage}
                keyfilter='pnum'
            />
            </div>
            
            <div className='mt-4'>
            <CustomInput
                bold={true}
                label='Quantité maximale'
                handleChange={handleChange}
                value={values.maxOrderQuantity}
                name='maxOrderQuantity'
                isFormFieldValid={isFormFieldValid}
                getFormErrorMessage={getFormErrorMessage}
                keyfilter='pnum'
            />
            <div className='mt-4'>
                <CustomSwitch
                label='status de produit'
                name='active'
                active={values.active} 
                handleChange={handleChange}/>
            </div>
            </div>

            <div className='mt-4 flex align-items-center justify-content-end'>
                <Button label="annuler" 
                className='w-auto p-button-secondary p-button-outlined p-button-sm mr-2' icon="pi pi-times" onClick={() => setVisibilityModal(false)}/>
                <Button 
                    disabled={isSubmitting}
                    onClick={handleSubmit} 
                    label={isSubmitting?'loading':'sauvegarder'}
                    loading={isSubmitting} 
                    className='w-auto p-button-sm p-button-success' 
                    icon="pi pi-check"  
                    type='submit' />
            </div>
            </>
            )
            }}
            </Formik>
            
        </Dialog>
    </>
  )
}

export default UpdateMultipleProducts