import Api from "../utils/Api";

export class CategoryService {

  // GET ALL CATEGORIES
  async getCategories(queryParams) {
    let response  = {}
    try {
      const categories = await Api.get(`category/getAll?${queryParams}`)
      response.data = categories.data
      return response
    } catch (error) {
      response.error = error
      return response
    }
  }

  // UPDATE CATEGORY
  async updateCategory (_id, data) {
    let response = {}
    try {
      const updatedCategory = await Api.put(`category/update/${_id}`,data)
      response.data = updatedCategory.data
      return response
    } catch (error) {
      response.error = error
      return response
    }
  }

  // DELETE CATEGORY
  async deleteCategory (_id){
    let response = {}
    try {
      const deleteCategory = await Api.delete(`category/delete/${_id}`)
      response.data = deleteCategory.data.message
      return response
    } catch (error) {
      response.error = error
      return response
    }
  }

  // CREATE CATEGORY
  async createCategory (data){
    let response = {}
    try {
      const createdCategory = await Api.post(`category/create`,data)
      response.data = createdCategory.data
      return response
    } catch (error) {
      response.error = error
      return response
    }
  }

  buildTree(categories, parent = null) {
    const tree = [];
    categories
      .filter(category => category.parentCategory === parent)
      .forEach(category => {
        const children = this.buildTree(categories, category._id);
        if (children.length) {
          category.children = children;
        } else {
          category.children = [];
        }
        tree.push({
          key: category._id,
          _id: category._id,
          label: category.nameCategory,
          nameCategory: category.nameCategory,
          parentCategory: category.parentCategory,
          photo: category.photo,
          active: category.active,
          children: category.children
        });
      });
    return tree;
  }

}