import Api from "../utils/Api";
import { NotificationService } from "./NotificationService";

export class OrderService {

  async getAllOrders(queryParameters){
    let response = {}
    try {
      const orders = await Api.get(`order/backoffice?${queryParameters}`)
      response.data = orders.data
      return response
    } catch (error) {
      console.log("error : ",error)
      response.error = error
      return response
    }
  }

  async updateOrderStatus (order){
    let response = {}
    try {
      const updatedOrder = await Api.put(`order/update/${order._id}`,order)
      response.data = updatedOrder.data
      return response
    } catch (error) {
      response.error = error
      return response
    }
  }

  async getOrderDetail (id){

    let response = {}
    try {
      const orderDetail = await Api.get(`order/detail/${id}`)
      response.data  = orderDetail.data
      return response
    } catch (error) {
      response.error = error
      return response
    }
  }

  async createOrderStatusHistory (data){
    let response = {}
    try {
      const res = await Api.post(`statusHistory/create`, data)
      response.data  = res.data
      return response
    } catch (error) {
      response.error = error
      return response
    }
  }

  async getOrderStatusHistory (orderId){
    let response = {}
    try {
      const res = await Api.get(`statusHistory?orderId=${orderId}`)
      response.data  = res.data
      return response
    } catch (error) {
      response.error = error
      return response
    }
  }

}