import React from 'react'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import { InputTextarea } from 'primereact/inputtextarea'

const CustomTextArea = ({
    label,
    placeholder,
    isFormFieldValid,
    handleChange,
    value,
    name,
    getFormErrorMessage,
    keyfilter
}) => {

  return (
    <div className='mb-4 w-full justify-content-center'>
    <p className='font-bold mb-2'>{label}</p>
    <InputTextarea 
    keyfilter={keyfilter}
    rows='10' cols='50'
    placeholder={placeholder}
    className={classNames({ 'p-invalid':  isFormFieldValid(name)})}
    onChange={handleChange(name)}
    value={value}
    />
    {getFormErrorMessage(name)}
    </div>
  )
}

export default CustomTextArea