import React, { useEffect, useState } from "react";
import { ExpeditionService } from "../../service/ExpeditionService";
import { useRecoilState } from "recoil";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import NoData from "../NoData";
import AddExpedition from "./AddExpedition";
import {  totalRecordsAtom } from "../../atoms/ExpeditionsAtoms";


const Expeditions = ({ activeIndex }) => {

    const expeditionService = new ExpeditionService()

    const [expeditions, setExpeditions] = useState([])
    const [loading, setLoading] = useState(false)
    const [filters, setFilters] = useState({
        pagination: {
            first: 0,
            rows: 10,
            page: 1,
        },
        numExpedition: null,
        deliveryMan: null, 
        expidationDate: null, 
        sortfield: '_id',
        sortorder: -1
    })
    const [totalRecords, setTotalRecords] = useRecoilState(totalRecordsAtom);


    useEffect(() => {
        if(activeIndex === 0){
            getExpeditions()
        }
    },[activeIndex, filters])

    const getExpeditions = async () => {
        setLoading(true)
        const queryParams = generateQueryParams()
        const response = await expeditionService.getExpeditions(queryParams)
        if(response.data){
            setExpeditions(response.data)
        }
        setLoading(false)

    }

    const generateQueryParams = () => {
        const { 
            pagination: { rows, page, first }, 
            sortfield, sortorder, 
            numExpedition, deliveryMan, expidationDate
        } = filters
        let queryParams = `limit=${rows}&totalDocuments=${totalRecords}&currentPage=${page}
            &totalSkip=${first}&sortfield=${sortfield}&sortorder=${sortorder}`
        if(numExpedition) queryParams += `numExpedition=${numExpedition}`
        if(deliveryMan) queryParams += `deliveryMan=${deliveryMan}`
        if(expidationDate) {queryParams += `numExpedition=${expidationDate.toISOString()}`}

    }

    //----------------CHANGE EVENT --------------------------------
    const onPage = (event) => {
        setFilters({
            ...filters,
            pagination: {
                first: event.first,
                rows: event.rows,
                page: event.page + 1,
            }
        })
    }

    const onSort = (event) => {
        setFilters({
            ...filters,
            pagination: {
                first: 0,
                rows: 10,
                page: event.page + 1,                
            },
            sortfield: event.sortfield,
            sortorder: filters.sortorder * -1
        })
    }

    return (
        <div>
            <div className="flex justify-content-end">
                <AddExpedition />
            </div>
        <DataTable 
            size='small' 
            lazy
            first={filters.pagination.first}
            loading={loading}
            totalRecords={totalRecords} 
            paginator
            onPage={onPage}
            onSort={onSort}
            value={expeditions} 
            responsiveLayout="scroll"
            rowHover 
            dataKey="_id" 
            rows={filters.pagination.rows} 
            filterDisplay="row"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} à {last} de {totalRecords}"
            className="datatable-responsive"
            emptyMessage={<NoData label='Aucune expédition disponible'/>}>
                <Column field="numExpedition" header="#Expedition" />

        </DataTable>
        </div>

    )

}

export default Expeditions