import React,{ useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { TreeSelect } from 'primereact/treeselect';
import { Dropdown } from 'primereact/dropdown';
import { Formik } from 'formik';
import { Toast } from 'primereact/toast';
import { v4 as uuidv4 } from 'uuid';
import { validationSchemaWithoutVariants, validationSchemaWithVariants } from '../../components/products/Edit_product/YupValidation'

// SERVICES ------------------------------------------------
import { ImageService } from '../../service/ImageService';
import { ProductService } from '../../service/ProductService';
import { OptionsService } from '../../service/OptionsService';
import { VariantService } from '../../service/VariantService'
import { CategoryService } from '../../service/CategoryService';
import { BrandsService } from '../../service/BrandsService';

// BLOCKS ---------------------------------
import BreadCrump from '../../components/BreadCrump';
import CustomSwitch from '../../components/CustomSwitch';
import StockBlock from '../../components/products/Add_product/StockBlock';
import PriceBlock from '../../components/products/Add_product/PriceBlock';
import OptionsBlock from '../../components/products/Edit_product/OptionsBlock';
import TagsBlock from '../../components/products/Edit_product/TagsBlock';
import ImageTemplate from '../../components/products/Edit_product/ImageTemplate';
import ImageUpload from '../../components/products/Edit_product/ImageUpload';
import { LoadingComponent } from '../../components/LoadingComponents';
import CustomInput from '../../components/CustomInput';
import CustomTextArea from '../../components/CustomTextArea';

const EditProduct = () => {
  
  const variantService = new VariantService()
  const productService = new ProductService()
  const optionsService = new OptionsService()
  const imageService = new ImageService()
  const categoryService = new CategoryService()
  const brandsService = new BrandsService()

  const marginBottom = 25
  const [options,setOptions]=useState([])
  const [productInfo,setProductInfo]=useState({})
  const [productInfoLoading,setProductInfoLoading]=useState(true)
  const [deletedImages, setDeletedImages] = useState([])
  const [newImages, setNewImages] = useState([])
  const [categories, setCategories] = useState([])
  const [brands, setBrands] = useState([])
  const [loading, setLoading]=useState(false)
  const [optionsLoading,setOptionsLoading]=useState(true)
  const [deleteVariantsId,setDeletedVariantsId]=useState([])
  const [deleteOptionsId,setDeletedOptionId]=useState([])
  const [variants,setVariants]=useState([])
  const [newTags,setNewTags]=useState([])
  const [familyDiscount, setFamilyDiscount]= useState([])
  const [generatedVariants,setGeneratedVariants]=useState([])
  const [singleVariant,setSingleVariant]=useState({})
  const [loadingCategories, setLoadingCategories] = useState(false)
  const [loadingBrands, setLoadingBrands] = useState(false)
  const toast = React.useRef(null);
  const navigate = useNavigate()
  let { _id } = useParams()

    const getProductDetail = async() => {
      const response = await productService.getProductDetail(_id)
      if(response.data){
        setProductInfo(response.data)
        setProductInfoLoading(false)
        if(response.data.hasVariant){
          await getOptions()
        } else {
          await getSingleVariant()
        }
        setFamilyDiscount(response.data.discountPercentage)
      } else {
        toast.current.show({severity:'error', summary: 'Error', detail:'erreur de serveur, réessayez plus tard', life: 3000});
        console.log(response.error)
      }
    }

    // IF SIMPLE PRODUCT GET SKU
    const getSingleVariant = async () => {
      const response = await variantService.getVariantsByProduct(_id)
      if(response.data){
        setSingleVariant(response.data[0])
      } else {
        toast.current.show({severity:'error', summary: 'Error', detail:'erreur de serveur, réessayez plus tard', life: 3000});
        console.log(response.error)
      }
    }

    // GET OPTIONS
    const getOptions = async ()=>{
      const response = await optionsService.getOptions(_id)
      if(response.data){
        setOptions(response.data)
        setOptionsLoading(false)
      }else {
          toast.current.show({severity:'error', summary: 'Error', detail:'erreur de serveur, réessayez plus tard', life: 3000});
          console.log(response.error)
          setOptionsLoading(false)
      }
    }

  const getCategories = async () => {
    const queryParams = "active=true"
    const response = await categoryService.getCategories(queryParams)
    if(response.data){
        const tree = categoryService.buildTree(response.data)
        console.log(tree)
        setCategories(tree)
        setLoadingCategories(false)
    } else {
        console.log(response.error);
        setLoadingCategories(false)
    }
  }

  const getBrands = async () => {
      const queryParameters = `active=true`
      const response = await brandsService.getAllBrands(queryParameters)
      if(response.data){
          setBrands(response.data)
          setLoadingBrands(false)
      } else {
          console.log(response.error);
          setLoadingBrands(false)
      }
  }
  


    useEffect(()=>{
      getProductDetail()
      getCategories()
      getBrands()
    },[])


    const initialValuesWithVariants = {
      _id: _id,
      nameProduct: productInfo?.nameProduct,
      mainCategory: productInfo?.mainCategory?._id,
      brand: productInfo?.brand?._id,
      photos: productInfo?.photos,
      description: productInfo?.description,
      priceProduct: productInfo?.priceProduct,
      discountPercentage: productInfo?.discountPercentage,
      active: productInfo?.active,
      tags: productInfo?.tags,
      hasVariant : productInfo?.hasVariant
    }

    const initialValuesWithoutVariants = {
      codeArticle: productInfo?.codeArticle ? productInfo?.codeArticle : ""  ,
      codeFamille: productInfo?.codeFamille ? productInfo?.codeFamille : "",
      nameProduct: productInfo?.nameProduct,
      mainCategory: productInfo?.mainCategory?._id,
      brand: productInfo?.brand?._id,
      priceProduct: productInfo.priceProduct,
      discountPercentage: productInfo?.discountPercentage,
      photos: productInfo?.photos,
      tags: productInfo?.tags,
      description: productInfo?.description,
      quantityStock:singleVariant?.quantityStock,
      minOrderQuantity: productInfo?.minOrderQuantity == null ? "" : productInfo?.minOrderQuantity,
      maxOrderQuantity: productInfo?.maxOrderQuantity == null ? "" : productInfo?.maxOrderQuantity,
      active: productInfo?.active,
      hasVariant : productInfo?.hasVariant
    }

    console.log("initialValuesWithoutVariants:",initialValuesWithoutVariants)

  //-----------handle template images-------------//

  // PUSH OPTION ID TO EACH VARIANT
  const pushIdToGeneratedVariants=(responseOpt, generatedVariants)=>{
    let opt1, opt2, opt3
    let newArr = [...generatedVariants]
    
    newArr.map((_var,index)=>{
        if(_var.option1){
            if(typeof opt1 === 'undefined')
            opt1 = responseOpt.find(op => op.numOption === 1)
            newArr[index].option1.optionId = opt1._id
        }
        if(_var.option2){
            if(typeof opt2 === 'undefined')
            opt2 = responseOpt.find(op => op.numOption === 2)
            newArr[index].option2.optionId = opt2._id
        }
        if(_var.option3){
            if(typeof opt3 === 'undefined')
            opt3 = responseOpt.find(op => op.numOption === 3)
            newArr[index].option3.optionId = opt3._id
        }
    })
    return newArr
  }


  // ADD VARIANT TO DB
  async function addVariant(variant){
      const variantService = new VariantService()
      const response = await variantService.addVariant(variant)
      if(response.data){
        console.log('variant added')
      }else{
        console.log(response.error)
        console.log('error add variant')
      }

      return response
  }

  // REF FOR IMAGE UPLOAD


  console.log('deleteVariantsId',deleteVariantsId)

  // SUBMIT EDIT
  async function onSubmit(values, actions){
    setLoading(true)
    !productInfo?.hasVariant && await _editVariant(values)
    let firebaseUrl = []
    if(newImages.length > 0){
        for(let i = 0; i < newImages.length; i++ ){
            const blobUrl = await fetch(newImages[i]).then(r => r.blob()); //get blob url
            const imageUrlFirebase = await imageService.uploadImage(blobUrl,`products/${values.nameProduct}-${uuidv4()}`) // upload to firebase and get url
            firebaseUrl.push(imageUrlFirebase.data)
        }
    }
    if(deletedImages.length > 0){
      for(let i = 0; i < deletedImages.length; i++){
        await imageService.deletImage(deletedImages[i])

      }
    }
    //remove the deleted images from photos value
    values.photos.map((photo) => {
      if(!deletedImages.find((deletedImage) => deletedImage == photo)){
        firebaseUrl.push(photo)
      }
    })

    let _familyDiscount = []
    if(familyDiscount.length > 0)
        _familyDiscount = familyDiscount.map(({family, percentage}) => ({family, percentage}))

    values.photos = firebaseUrl
    values.discountPercentage = _familyDiscount
    console.log(values.discountPercentage)
    await _editProduct(_id, values)
    
    if(productInfo?.hasVariant){
      if(generatedVariants.length > 0){
        const _generatedVariants = variants.filter(vt => vt.new === true)
        const _oldVariants = variants.filter(vt => !vt.new)
        
        for(let i in _generatedVariants){
            _generatedVariants[i].product = _id 
            delete _generatedVariants[i]._id
            delete _generatedVariants[i].new
        }

        const arr = pushIdToGeneratedVariants(options, _generatedVariants)

        const varRes = await addVariant(arr)
        if(varRes.data){
          console.log('new generated variant are added')
        }else{
          toast.current.show({severity:'error', summary: 'Error', detail:'erreur de serveur, réessayez plus tard', life: 3000});
        }
        

        for(let i in _oldVariants){
            const res = await variantService.editVariant(_oldVariants[i]._id, _oldVariants[i])
            if(res.data){
              console.log('edit old variant')
            }else{
              console.log(res.error)
              toast.current.show({severity:'error', summary: 'Error', detail:'erreur update old variant essayer plus tard', life: 3000})
            }
          
        }

      } else{
        console.log('variants',variants)
          for(let i in variants){
            const res = await variantService.editVariant(variants[i]._id, variants[i])
            if(res.data){
              console.log('edit variant')
            }else{
              console.log(res.error)
              toast.current.show({severity:'error', summary: 'Error', detail:'erreur de serveur, réessayez plus tard', life: 3000})
            }
          }
      }
      
      for(let i in options){
        const res = await optionsService.updateOption(options[i]._id, options[i])
        if(res.data){
          console.log('edit option')
        }else{
          console.log(res.error)
          toast.current.show({severity:'error', summary: 'Error', detail:'erreur de serveur, réessayez plus tard', life: 3000})
        }
      }

      if(deleteVariantsId.length > 0){
        for(let i in deleteVariantsId){
           await variantService.deleteVariant(deleteVariantsId[i])
        }
      }

    }
    setLoading(false)

  }

  // EDIT PRODUCT
  async function _editProduct(_id ,data){
    setLoading(true)
    const _data = {...data,tags:[...data.tags,...newTags]}
    const response = await productService.updatedProduct(_id ,_data)
    if(response.data){
      setLoading(false)
      navigate('/products')
    } else {
        toast.current.show({severity:'error', summary: 'Error', detail:'erreur de serveur, réessayez plus tard', life: 3000});
        console.log(response.error)
    }
  }

  // EDIT VARIANT BY ID
  async function _editVariant(variantData) {
    const { 
      reference, 
      quantityStock, 
      minOrderQuantity } = variantData

    const _variantData = {
        reference, quantityStock, minOrderQuantity
    }
    await variantService.editVariant(singleVariant._id, _variantData)
  }



  const breadCrumpItems={
    href:'products',
    parentName:'produits',
    currentPage:initialValuesWithoutVariants.nameProduct
  }

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    return (
        <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
            {
             chooseButton
            }
            {cancelButton}
        </div>
    )
} 

  //when an image added
  const onTemplateSelect = (e) => {
      const objectURL = e.files[0]?.objectURL
      setNewImages([...newImages, objectURL])
  }

  //when an image removed
  const onTemplateRemove = (e) => {
    const removedPhoto = e.file.objectURL
    let _newImages = [...newImages]
    const indexRemovedPhoto = _newImages.findIndex((photo) => photo == removedPhoto)
    if (indexRemovedPhoto != -1){
       _newImages.splice(indexRemovedPhoto, 1)
    }
    setNewImages([..._newImages])
  }

  //when all images removed
  const onTemplateClear = (setFieldValue) => {
    setNewImages([])
  }

  if(productInfoLoading){
    return <LoadingComponent/>
  }

  return (
    <>
      <Toast ref={toast} />
      <div 
      className='flex flex-column sm:flex-row align-items-center justify-content-between' >
      <BreadCrump breadCrumpItems={breadCrumpItems} />
      </div>

      <div className='p-fluid grid'>
        <Formik 
        enableReinitialize={true}
        initialValues={productInfo?.hasVariant ? initialValuesWithVariants : initialValuesWithoutVariants } 
        validationSchema={ productInfo?.hasVariant ? validationSchemaWithVariants : validationSchemaWithoutVariants }
        onSubmit={onSubmit} >
        {({ handleChange, 
            handleSubmit,
            isSubmitting,
            handleBlur,
            values, 
            errors, 
            touched, 
            setFieldValue 
        }) =>
        {
          const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
          const getFormErrorMessage = (name) => {
              return isFormFieldValid(name) && <small className="p-error mt-2">{errors[name]}</small>;
          };
            
          return(
          <>
          <div className='col-12 md:col-7'>

          <div className='card'>

          {/* CODE ARTICLE */}
          <CustomInput
          label='code article'
          placeholder='code article (atlas)'
          handleChange={handleChange}
          value={values.codeArticle}
          name='codeArticle'
          isFormFieldValid={isFormFieldValid}
          getFormErrorMessage={getFormErrorMessage}
          />

          {/* CODE FAMILLE */}
          <CustomInput
            label='code famille'
            placeholder='code famille (atlas)'
            handleChange={handleChange}
            value={values.codeFamille}
            name='codeFamille'
            isFormFieldValid={isFormFieldValid}
            getFormErrorMessage={getFormErrorMessage}
          />

          {/* NAME */}
          <CustomInput
            label='titre de produit'
            placeholder='titre'
            handleChange={handleChange}
            value={values.nameProduct}
            name='nameProduct'
            isFormFieldValid={isFormFieldValid}
            getFormErrorMessage={getFormErrorMessage}
          />

          {/* DESCRIPTION */}
          <CustomTextArea
             label='description de produit'
             placeholder='description'
             handleChange={handleChange}
             value={values.description}
             name='description'
             isFormFieldValid={isFormFieldValid}
             getFormErrorMessage={getFormErrorMessage}
          />
          {/* CATEGORY */}
          <div className='mb-3'>
            <p className="mb-2 font-bold">Catégorie</p>   
                <TreeSelect
                    value={values.mainCategory} 
                    onChange={handleChange("mainCategory")} 
                    options={categories} 
                    disabled={loadingCategories}
                    className="md:w-20rem w-full" 
                    placeholder={loadingCategories ? "loading" : "selectionner catégorie"}
                    filter
                />
            </div>
            {getFormErrorMessage("mainCategory")}

          {/* BRAND */}
          <div className='mt-4'>
              <p className="mb-2 font-bold">marque</p>
              <div className='flex align-items-center'>
                  <Dropdown
                      filter
                      value={values.brand} 
                      onChange={handleChange('brand')}
                      placeholder={loadingBrands ? "loading" : "sélectionner marque"}
                      disabled={loadingBrands}
                      options={brands}
                      optionLabel="brandName" 
                      optionValue="_id"
  
                  />
            
              </div>
          </div>
          {getFormErrorMessage("brand")}
          </div>

          <TagsBlock 
          tags={values.tags} 
          setFieldValue={setFieldValue}
          newTags={newTags}
          setNewTags={setNewTags} />

          {values?.photos.length > 0
          &&<ImageTemplate  
          deletedImages={deletedImages}
          setDeletedImages={setDeletedImages}
          images={values?.photos} />}
          
          <div>
              <h5>Images</h5>
              <FileUpload name="demo[]" url="https://primefaces.org/primereact/showcase/upload.php" 
                  multiple={false}
                  headerTemplate={headerTemplate}
                  accept="image/*" 
                  maxFileSize={2000000}
                  onSelect={(e) => onTemplateSelect(e)}
                  onError={(e) => onTemplateClear(e)} 
                  onClear={(e) =>onTemplateClear(e)} 
                  onRemove={(e) => onTemplateRemove(e)}
                  emptyTemplate={<p className="m-0">Faites glisser et déposez les images ici pour les télécharger.</p>} 
                  />
                  { getFormErrorMessage('photo')}
          </div>         
          
          {productInfo?.hasVariant
          &&<OptionsBlock
          optionsLoading={optionsLoading}
          setGeneratedVariants={setGeneratedVariants}
          generatedVariants={generatedVariants}
          deleteOptionsId={deleteOptionsId}
          setDeletedOptionId={setDeletedOptionId}
          setDeletedVariantsId={setDeletedVariantsId}
          deleteVariantsId={deleteVariantsId}
          toast={toast}
          productId={_id}
          hasVariant={productInfo?.hasVariant} 
          variants={variants} 
          setVariants={setVariants} 
          options={options} 
          setOptions={setOptions} />}

          </div>

          <div className='col-12 md:col-5'>

        {!productInfo?.hasVariant
          &&<StockBlock
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          isFormFieldValid={isFormFieldValid}
          getFormErrorMessage={getFormErrorMessage}/>}

        <PriceBlock
          familyDiscount={familyDiscount}
          setFamilyDiscount={setFamilyDiscount}
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          isFormFieldValid={isFormFieldValid}
          getFormErrorMessage={getFormErrorMessage} />
      

        {/* STATUS  */}
        <div className="card">
        <CustomSwitch
            label='status de produit'
            name='active'
            active={values.active} 
            handleChange={handleChange}/>
        </div>      

          </div>

          <div
          className='mb-3 flex justify-content-end w-full'>
          <Button 
              label='modifier' 
              type="submit"
              onClick={handleSubmit} 
              loading={loading}
              disabled={loading}
              className='w-auto p-button-warning' 
              icon="pi pi-pencil"/>
            </div>
          </>
          )
        }}
        </Formik>
      </div>
    
    </>
  )
}

export default EditProduct