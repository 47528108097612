import React, { useState, useEffect, useRef, useContext } from "react";
import classNames from "classnames";
import { Route, useLocation, Routes, Navigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { Tooltip } from "primereact/tooltip";
import PrimeReact from "primereact/api";

import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "../AppFooter";
import { AppMenu } from "../AppMenu";
import { AppConfig } from "../AppConfig";
import { UserContext } from "../context";
import { AuthRouter } from "../routes/ProtectedRoutes";
import Login from "../pages/Login";
import { sideMenu } from "../utils/sideMenu";
import { AppRoutes } from "../utils/AppRoutes";

const AppLayout = () => {
  
  const { userInfo, isAuth, isLoading } = useContext(UserContext);

  const [layoutMode, setLayoutMode] = useState("static");
  const [waitAuth, setWaitAuth] = useState(false);
  const [layoutColorMode, setLayoutColorMode] = useState("light");
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const copyTooltipRef = useRef();
  const location = useLocation();

  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  const waitingAuth = () => {
    setTimeout(() => {
      setWaitAuth(true);
    }, 1500);
  };

  useEffect(() => {
    waitingAuth();
  }, []);

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };

  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  // FILTER SIDE MENU BASED ON PERMISSIONS AND ROLES
  function filterMenu(menu, userPermissions) {
    return menu
      .map((menuItem) => {
        if (!menuItem.items) {
          return menuItem;
        }

        const filteredItems = menuItem.items.filter((item) => {
          const modulePermissions = userPermissions[item.module];
          if (!modulePermissions) {
            return false;
          }
          const hasNoPermissions = Object.values(modulePermissions).every(
            (permission) => !permission
          );
          return !hasNoPermissions;
        });

        return {
          ...menuItem,
          items: filteredItems,
        };
      })
      .filter((menuItem) => menuItem.items && menuItem.items.length > 0);
  }

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive":
      staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active":
      overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
    "layout-theme-light": layoutColorMode === "light",
  });

  if (!waitAuth) {
    return (
      <div
        style={{ height: "100vh" }}
        className="flex align-items-center justify-content-center"
      >
        <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="h-screen flex align-items-center justify-content-center">
        <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
      </div>
    );
  }

  return (
    <>
      {isAuth ? (
        <div className={wrapperClass} onClick={onWrapperClick}>
          <Tooltip
            ref={copyTooltipRef}
            target=".block-action-copy"
            position="bottom"
            content="Copied to clipboard"
            event="focus"
          />

          <AppTopbar
            onToggleMenuClick={onToggleMenuClick}
            layoutColorMode={layoutColorMode}
            mobileTopbarMenuActive={mobileTopbarMenuActive}
            onMobileTopbarMenuClick={onMobileTopbarMenuClick}
            onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
          />

          <div
            className="custom-layout-sidebar layout-sidebar"
            onClick={onSidebarClick}
          >
            <AppMenu
              model={filterMenu(sideMenu, userInfo?.roles)}
              onMenuItemClick={onMenuItemClick}
              layoutColorMode={layoutColorMode}
            />
          </div>

          <div className="layout-main-container custom-container-main-layout">
            <div className="layout-main">
              <AppRoutes />
            </div>

            <AppFooter layoutColorMode={layoutColorMode} />
          </div>

          <AppConfig />

          <CSSTransition
            classNames="layout-mask"
            timeout={{ enter: 200, exit: 200 }}
            in={mobileMenuActive}
            unmountOnExit
          >
            <div className="layout-mask p-component-overlay"></div>
          </CSSTransition>
        </div>
      ) : (
        <Routes>
          <Route element={<AuthRouter isAuth={isAuth} />}>
            <Route path="/" element={<Login />} />
          </Route>
        </Routes>
      )}
    </>
  );
};

export default AppLayout;
