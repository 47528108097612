import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { FileUpload } from 'primereact/fileupload';
import { ImageService } from '../../service/ImageService';
import { v4 as uuidv4 } from 'uuid';

import CustomSwitch from '../CustomSwitch';
import CustomInput from '../CustomInput';

const EditBrand = ({ rowData, updateBrand }) => {

    const imageService = new ImageService()

    const [dialogVisibility, setDialogVisibility] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null)
    const [deletedImage, setDeletedImage] = useState(null)

    const hideDialog = () => setDialogVisibility(false)
    const openNew = () => setDialogVisibility(true)

    const initialValues = {
        brandName: rowData.brandName,
        photo: rowData.photo == null ? "" : rowData.photo,
        active: rowData.active
    }
 
    const validationSchema = Yup.object().shape({
        brandName: Yup.string().required('nom de marque obligatoire'),
        active:Yup.bool()
    })

    useEffect(() => {
        return () => {
            setSelectedImage(null)
            setDeletedImage(null)
        }
    }, [dialogVisibility])

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {
                 selectedImage == null && chooseButton
                }
                {cancelButton}
            </div>
        )
    } 

    //when an image added
    const onTemplateSelect = (e, setFieldValue) => {
        const objectURL = e.files[0]?.objectURL
        setSelectedImage(objectURL)
        setFieldValue('photo',objectURL)
    }

    //when an image removed
    const onTemplateRemove = (setFieldValue) => {
        setSelectedImage(null)
        setFieldValue('photo', '')
    }

    //when all images removed
    const onTemplateClear = (setFieldValue) => {
        setSelectedImage(null)
        setFieldValue('photo', '')
    }

    const onSubmit = async (values, actions) => {
        const { brandName, photo, active} = values
        let url_image
        if(deletedImage){
            await imageService.deletImage(deletedImage)
            const blob = await fetch(photo).then(r => r.blob()); //get blob url
            url_image = await imageService.uploadImage(blob,`brands/${brandName}-${uuidv4()}`) // upload to firebase and get url
        }
        let data = {
            brandName,  
            photo: url_image ? url_image.data : photo, 
            active
        }
        
        await updateBrand(rowData._id, data)
        setSelectedImage(null)
        setDeletedImage(null)
        hideDialog()
    }
    
    return(
        <>
        <Button 
        icon="pi pi-pencil"
        className="p-button-sm p-button-rounded p-button-text p-button-warning" 
        onClick={openNew} />
        
        {/* MODAL */}
        <Dialog draggable={false} visible={dialogVisibility} breakpoints={{'1900px': '60vw', '640px': '100vw'}}
            header="Modifier marque" modal
            className="p-fluid" onHide={hideDialog}>
            
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ handleChange, handleBlur, handleSubmit, isSubmitting, values, errors, touched, setFieldValue })=>{
               
                const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
                const getFormErrorMessage = (name) => {
                    return isFormFieldValid(name) && <small className="p-error">{errors[name]}</small>;
                };
                return(
                <>
                {/* NAME */}
                <CustomInput
                    label='nom de la marquee'
                    placeholder='nom'
                    handleChange={handleChange}
                    value={values.brandName}
                    name='brandName'
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                />

                {/*template image */}
                {deletedImage == null && values.photo &&
                    <div style={{width:150,height:150}} 
                    className="relative field flex align-items-center ">
                    <img src={values.photo} alt={values.brandName} width='150' height='150'/>
                    <div 
                    onClick={()=> setDeletedImage(values.photo)}
                    className='flex align-items-center justify-content-center'
                    style={{position:'absolute',top:5,right:5,height:40,width:40,borderRadius:20,cursor:'pointer',backgroundColor:'#fff'}}>
                            <i className={'pi pi-trash'} 
                            style={{color:'#f00'}}></i>
                    </div>
                    </div>
                }                
                {(deletedImage || !values.photo) &&
                <div>
                    <h5>Images</h5>
                    <FileUpload name="demo[]" url="https://primefaces.org/primereact/showcase/upload.php" 
                        multiple={false}
                        headerTemplate={headerTemplate}
                        accept="image/*" 
                        maxFileSize={2000000}
                        onSelect={(e) => onTemplateSelect(e,setFieldValue)}
                        onError={(e) => onTemplateClear(setFieldValue)} 
                        onClear={(e) =>onTemplateClear(setFieldValue)} 
                        onRemove={(e) => onTemplateRemove(setFieldValue)}
                        emptyTemplate={<p className="m-0">Faites glisser et déposez les images ici pour les télécharger.</p>} 
                        />                    
                </div>         
                }
                {/* STATUS */}
                <div className='mb-3'>
                <CustomSwitch
                    label='status'
                    name='active'
                    active={values.active} 
                    handleChange={handleChange}/>
                </div>        
                
            <div className='mt-4 flex align-items-center justify-content-end'>
                <Button label="annuler" className='w-auto p-button-secondary p-button-sm p-button-outlined mr-2' icon="pi pi-times" 
                onClick={hideDialog}/>
                <Button onClick={handleSubmit} 
                label={isSubmitting?'loading':'sauvegarder'}
                disabled={isSubmitting}
                loading={isSubmitting}
                className='w-auto p-button-sm p-button-success' 
                icon="pi pi-check"  type='submit' />
            </div>
            </>
                )
            }}
            </Formik>
            </Dialog>
        </>
    )
}

export default EditBrand