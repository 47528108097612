import React,{ useEffect ,useState } from 'react'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { Dialog } from 'primereact/dialog';
import CustomInput from '../CustomInput';
import { CustomersService } from '../../service/CustomersService';

export default function ChangePassword ({ customerId }){

  const [dialogVisibility, setDialogVisibility] = useState(false);
  const hideDialog = () => setDialogVisibility(false)
  const openNew = () => setDialogVisibility(true)

  return (
    <>
      <Button
        label='changer le mot de passe'
        icon="pi pi-lock" 
        className="w-auto p-button-sm p-button-text p-button-secondary" 
        onClick={openNew} 
      />
      <UpdatePasswordModal 
        customerId={customerId}
        dialogVisibility={dialogVisibility} 
        hideDialog={hideDialog}
      />
    </>
  )
}

export const UpdatePasswordModal = ({ customerId, dialogVisibility, hideDialog }) => {

  const customerService = new CustomersService()

  const initialValues = {
      password:'',
      repeatPassword:''
  }

  const validationSchema = Yup.object().shape({
      password: Yup.string().required('saisir le mot de pass').min(8,'8 caractères'),
      repeatPassword:  Yup.string()
                          .required('saisir le mot de pass')
                          .oneOf([Yup.ref('password'), null],'les mots de passe ne correspondent pas')
  })

  const onSubmit = async(values,actions) => {
      const response = await customerService.updatePassword(customerId, values.password)
      if(response.data){
          console.log('password changed')
          actions.setSubmitting(false)
          hideDialog()
      } else {
          console.log(response.error)
          actions.setSubmitting(false)

      }
  }  

  return (
    <Dialog draggable={false} visible={dialogVisibility} 
      header="Modifier mot de passe" modal 
      className="p-fluid" onHide={hideDialog}>

    <div className='p-fluid grid'>

      <Formik 
          enableReinitialize={true}
          initialValues={initialValues} 
          validationSchema={validationSchema} 
          onSubmit={onSubmit}>
          {({ 
              handleChange, 
              handleSubmit,
              isSubmitting, 
              values, 
              errors, 
              touched })=>{
              
              const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
              const getFormErrorMessage = (name) => {
                  return isFormFieldValid(name) && <small className="p-error">{errors[name]}</small>;
              };

              return(
                  <>
            
                  
              <div className='col-12'>

              <CustomInput
                label="mot de passe"
                name='password'
                placeholder={"mot de passe"}
                handleChange={handleChange}
                isFormFieldValid={isFormFieldValid}
                getFormErrorMessage={getFormErrorMessage}
                value={values.password}/>

              <CustomInput
                label="Répéter le mot de passe"
                name='repeatPassword'
                placeholder={"Répéter le mot de passe"}
                handleChange={handleChange}
                isFormFieldValid={isFormFieldValid}
                getFormErrorMessage={getFormErrorMessage}
                value={values.repeatPassword}/>

              </div>

              <div className='col-12'>
                  <div className='mt-4 flex align-items-center justify-content-end'>
                      <Button label="annuler" className='w-auto p-button-secondary p-button-sm p-button-outlined mr-2' icon="pi pi-times" 
                        onClick={hideDialog}/>
                      <Button onClick={handleSubmit}
                      loading={isSubmitting}
                      disabled={isSubmitting} 
                      label="sauvegarder" 
                      className='w-auto p-button-sm p-button-info' 
                      icon="pi pi-check" />
                  </div>
              </div> 
            
              </>
              )
          }}
      </Formik>

    </div>
    </Dialog>  
  )


  
}

