import React, { useContext } from "react";
import { UserContext } from "../context";
import { Route, Routes } from "react-router-dom";
import { ModulesNames } from "./Consts";

import Admins from "../pages/Admins";
import Brands from "../pages/Brands";
import Categories from "../pages/Categories";
import Customers from "../pages/Customers";
import Gifts from "../pages/Gifts";
import Notifications from "../pages/Notifications";
import Offers from "../pages/Offers";
import Orders from "../pages/Orders";
import OrdersExpeditions from "../pages/OrdersExpeditions";
import Products from "../pages/Products";
import Proposals from "../pages/Proposals";
import RedeemGifts from "../pages/RedeemGifts";
import Revenue from "../pages/Revenue";
import Settings from "../pages/Settings";
import Types from "../pages/Types";
import UpdateAdmin from "../pages/admin_screens/UpdateAdmin";
import AdminRoles from "../pages/admin_screens/AdminRoles";
import AddCustomer from "../pages/customers/AddCustomer";
import UpdateCustomer from "../pages/customers/UpdateCustomer";
// import EditPassword from "../pages/customers/EditPassword";
import OrderDetail from "../pages/orders/OrderDetail";
import AddProduct from "../pages/product/AddProduct";
import EditProduct from "../pages/product/EditProduct";
import PreviewProduct from "../pages/product/PreviewProduct";
import DiscountCustomer from "../pages/DiscountCustomer";
import Livreurs from "../pages/Livreurs";
import UpdateAdminPassword from "../pages/admin_screens/UpdateAdminPassword";
import PageNotFound from "../pages/PageNotFound";

export const AppRoutes = () => {
  const { userInfo } = useContext(UserContext);

  function filterRoutesByPermissions(routes, userPermissions) {
    return routes
      .filter((route) => {
        const moduleName = route.module;

        if (!moduleName || !userPermissions[moduleName]) {
          return true;
        }
        return Object.values(userPermissions[moduleName]).some(
          (permission) => permission
        );
      })
      .map((route) => route.route);
  }

  const originalRoutes = [
    // {
    //   module: ModulesNames.statistics,
    //   route: <Route path={`/`} element={<Revenue />} />,
    // },
    {
      module: ModulesNames.categories,
      route: (
        <Route path={`/${ModulesNames.categories}`} element={<Categories />} />
      ),
    },
    {
      module: ModulesNames.types,
      route: <Route path={`/${ModulesNames.types}`} element={<Types />} />,
    },
    {
      module: ModulesNames.brands,
      route: <Route path={`/${ModulesNames.brands}`} element={<Brands />} />,
    },
    {
      module: ModulesNames.offers,
      route: <Route path={`/${ModulesNames.offers}`} element={<Offers />} />,
    },
    {
      module: ModulesNames.setting,
      route: <Route path={`/${ModulesNames.setting}`} element={<Settings />} />,
    },
    {
      module: ModulesNames.proposals,
      route: (
        <Route path={`/${ModulesNames.proposals}`} element={<Proposals />} />
      ),
    },
    {
      module: ModulesNames.notifications,
      route: (
        <Route
          path={`/${ModulesNames.notifications}`}
          element={<Notifications />}
        />
      ),
    },
    {
      module: ModulesNames.products,
      route: (
        <Route path={`/${ModulesNames.products}`} element={<Products />} />
      ),
    },
    {
      module: ModulesNames.products,
      route: (
        <Route
          path={`/${ModulesNames.products}/:_id`}
          element={<PreviewProduct />}
        />
      ),
    },
    {
      module: ModulesNames.products,
      route: (
        <Route
          path={`/${ModulesNames.products}/edit/:_id`}
          element={<EditProduct />}
        />
      ),
    },
    {
      module: ModulesNames.products,
      route: (
        <Route
          path={`/${ModulesNames.products}/add`}
          element={<AddProduct />}
        />
      ),
    },
    {
      module: ModulesNames.gifts,
      route: <Route path={`/${ModulesNames.gifts}`} element={<Gifts />} />,
    },
    {
      module: ModulesNames.redeemGifts,
      route: (
        <Route
          path={`/${ModulesNames.redeemGifts}`}
          element={<RedeemGifts />}
        />
      ),
    },
    {
      module: ModulesNames.customers,
      route: (
        <Route path={`/${ModulesNames.customers}`} element={<Customers />} />
      ),
    },
    {
      module: ModulesNames.customers,
      route: (
        <Route
          path={`/${ModulesNames.customers}/add`}
          element={<AddCustomer />}
        />
      ),
    },
    {
      module: ModulesNames.customers,
      route: (
        <Route
          path={`/${ModulesNames.customers}/edit/:id`}
          element={<UpdateCustomer />}
        />
      ),
    },
    // {
    //   module: ModulesNames.customers,
    //   route: (
    //     <Route
    //       path={`/${ModulesNames.customers}/edit/password/:id`}
    //       element={<EditPassword />}
    //     />
    //   ),
    // },
    {
      module: ModulesNames.admins,
      route: <Route path={`/${ModulesNames.admins}`} element={<Admins />} />,
    },
    {
      module: ModulesNames.admins,
      route: (
        <Route
          path={`/${ModulesNames.admins}/update/:id`}
          element={<UpdateAdmin />}
        />
      ),
    },
    {
      module: ModulesNames.admins,
      route: (
        <Route
          path={`/${ModulesNames.admins}/roles/:id`}
          element={<AdminRoles />}
        />
      ),
    },
    {
      module: ModulesNames.admins,
      route: (
        <Route
          path={`/${ModulesNames.admins}/updatepassword/:id`}
          element={<UpdateAdminPassword />}
        />
      ),
    },
    {
      module: ModulesNames.famillies,
      route: (
        <Route
          path={`/${ModulesNames.famillies}`}
          element={<DiscountCustomer />}
        />
      ),
    },
    {
      module: ModulesNames.deliveryMen,
      route: (
        <Route path={`/${ModulesNames.deliveryMen}`} element={<Livreurs />} />
      ),
    },
    {
      module: ModulesNames.orders,
      route: <Route path={`/${ModulesNames.orders}`} element={<Orders />} />,
    },
    // {
    //   module: ModulesNames.expeditions,
    //   route: <Route path={`/${ModulesNames.expeditions}`} element={<OrdersExpeditions />} />,

    // },
    {
      module: ModulesNames.orders,
      route: (
        <Route
          path={`/${ModulesNames.orders}/detail/:id`}
          element={<OrderDetail />}
        />
      ),
    },
  ];

  // Filter routes based on user permissions
  const filteredRoutes = filterRoutesByPermissions(
    originalRoutes,
    userInfo?.roles
  );

  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />
      <Route path={`/`} element={<Revenue />} />,
      {filteredRoutes}
    </Routes>
  );
};
