import axios from "axios";

const dev_url = "http://localhost:3000/api/";
const prod_url = "https://api.cmaparapro.ma/api/";

let userToken;

if (typeof window !== "undefined") {
  userToken = window.localStorage.getItem("userToken");
}

const Api = axios.create({
  // headers: {
  //   Authorization: "Bearer " + userToken,
  //   "Content-Type": "application/json",
  // },
  baseURL: prod_url,
  // process.env.REACT_APP_BASE_URL
});

export default Api;
