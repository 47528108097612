import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { OrderService } from '../../service/OrderService';
import { locale, addLocale } from 'primereact/api';
import { onSnapshot, query,collection } from "firebase/firestore";
import { db } from '../../utils/firebase';
import { useNavigate } from 'react-router-dom';

import { statusCheck } from '../../pages/orders/statusColor';
import StatusMenu from '../orders/StatusMenu';
import NoData from '../NoData';
import { useRecoilState } from 'recoil';
import { selectedOrdersToShipAtom } from '../../atoms/ExpeditionsAtoms';
import { MultiSelect } from 'primereact/multiselect';

const STATUS = [
    {
        label:'EN COURS',
        value:'EN COURS'
    },
    {
        label:'PRÊT À EXPÉDIER',
        value:'PRÊT À EXPÉDIER'
    },
    {
        label:'ÉXPEDIÉ',
        value:'ÉXPEDIÉ'
    },
    {
        label:'LIVRÉ',
        value:'LIVRÉ'
    },
    // {
    //     label:'RETOUR',
    //     value:'RETOUR'
    // },
    {
        label:'ANNULÉE',
        value:'ANNULÉE'
    }
]


const OrdersDatatable = ({
    showActions = true,
}) => {

    const orderService = new OrderService();
    const [orders, setOrders] = useState(null);
    //--------------------------------------------
    const [loading, setLoading] = useState(true);
    const dt = useRef(null);
    const navigate = useNavigate()
    const [toggleMenu, setToggleMenu] = useState(null); // toggle menu state
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
        filters : {
            date: null, 
            status: null, 
            customer: null,
            numOrder: null
        },
        sortfield: "createdAt",
        sortorder: -1
    });
    const [selectedOrdersToShip, setSelectedOrdersToShip] = useRecoilState(selectedOrdersToShipAtom)

    addLocale('fr', {
        firstDayOfWeek: 1,
        dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
        monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre' ],
        monthNamesShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc' ],
        today: "aujourd'hui",
        clear: 'réinitialiser'
    });
    
    locale('fr');


    useEffect(() => {
        lazyLoadData()
    }, [lazyParams]);

    //get the orders again when an order is created
    useEffect(() => {
        const q = query(collection(db, "orders"));
        const unsubscribe = onSnapshot(q, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === "modified") {
                setLazyParams({
                    first: 0,
                    rows: 10,
                    page: 1,
                    filters : {
                        date: null, 
                        status: null, 
                        customer: null,
                        numOrder: null
                    },
                    sortfield: "createdAt",
                    sortorder: -1
            })
            }
          });
        });
        return () => unsubscribe()
    }, [])

    // GET ORDERS
    async function lazyLoadData(){
        setLoading(true)
        const queryParams = generateQueryParameters()
        const response = await orderService.getAllOrders(queryParams)
        if(response.data){
            setOrders(response.data.orders)
            setTotalRecords(response.data.totalDocuments)
        }
        setLoading(false)
    }

    const generateQueryParameters = () => {
        const {first, rows, page, filters : {date, status, customer,numOrder}, sortfield, sortorder } = lazyParams
        let parameters = `totalSkip=${first}&currentPage=${page}&totalDocuments=${totalRecords}&limit=${rows}
            &sortfield=${sortfield}&sortorder=${sortorder}`
        if(date) parameters += `&date=${date.toISOString()}`
        if(lazyParams.filters.status && lazyParams.filters.status.length > 0) {
            parameters += `&status=${status.join(",")}`
        }
        if(customer) parameters += `&customer=${customer}`
        if(numOrder) parameters += `&numOrder=${numOrder}`
        console.log(parameters)
        return parameters
    }

    const onPage = (event) => {
        setLazyParams({
            ...lazyParams,
            first: event.first,
            rows: event.rows,
            page: event.page + 1,
        })
    }

    const onSort = (event) => {
        console.log("event sort: ",event)
        let sortorder;
        if(lazyParams.sortfield == null || lazyParams.sortfield != event.sortField){
          sortorder = 1
        } else {
          sortorder = lazyParams.sortorder * -1
        }
        setLazyParams({
            ...lazyParams,
            first: 0,
            rows: 10,
            page: 1,
            sortfield: event.sortField,
            sortorder: sortorder,
        });
    };

    const onDateChanged = (event) => {
        setLazyParams({
            first: 0,
            rows: 10,
            page: 1,
            filters : {
                ...lazyParams.filters,
                date: event.target.value, 
            },
            sortfield: "createdAt",
            sortorder: -1
        })
    }

    const onNumOrderChanged = (event) => {
        setLazyParams({
            first: 0,
            rows: 10,
            page: 1,
            filters : {
                ...lazyParams.filters,
                numOrder: event.target.value
            },
            sortfield: "createdAt",
            sortorder: -1
        })

    }

    const onStatusChanged = (event) => {
        setLazyParams({
            first: 0,
            rows: 10,
            page: 1,
            filters : {
                ...lazyParams.filters,
                status: event.value, 
            },
            sortfield: "createdAt",
            sortorder: -1
        })
    }

    const onOrderSelected = (event) => {
        console.log(event.value)
        //setSelectedOrdersToShip(event.value)
        
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex">

            <Button onClick={()=>navigate(`/orders/detail/${rowData._id}`)} 
                type="button" 
                icon="pi pi-eye" 
                className="p-button-sm p-button-secondary p-button-rounded p-button-outlined p-button-text" 
            />

            {/* STATUS CHANGE */}
            { (rowData.status !== 'RETOUR' || rowData.status !== 'ANNULÉE')
            && <StatusMenu 
            rowData={rowData} 
            setToggleMenu={setToggleMenu} 
            toggleMenu={toggleMenu} 
            />}

            </div>
        );
    }


    const statusBodyTemplate=(rowData)=>{
        const severity = statusCheck(rowData.status)
        return(
            <div style={{backgroundColor:severity,borderRadius:12,display:'inline-block'}} className='pr-2 pl-2'>
                <p className='text-sm font-medium lowercase' style={{color:'#fff'}}>{rowData.status}</p>
            </div>
        )
    }


    const priceBodyTemplate=(rowData)=>{
        return(
            <p>{rowData.totalPriceOrder}dh</p>
        )
    }

    const commandeFilter=()=>{
        return(
            <InputText 
            placeholder='numéro commande'
            className="p-inputtext-sm mr-2" 
            value={lazyParams.filters.numOrder} 
            onChange={onNumOrderChanged} />
        )
    }

    const dateFilter = ()=>{
        return(
            <Calendar
            className="p-inputtext-sm mr-2"
            local={'fr'}
            style={{minWidth:'100px'}}
            showButtonBar
            onChange={onDateChanged} 
            value={lazyParams.filters.date}
            placeholder='date' 
            id="basic"/>
        )
    }

    const textTemplate=(rowData,field)=>{
        let val =''
        if(field==='numOrder') val = rowData.numOrder
        else if(field==='customer') {
            if(rowData.customer !== null)
            val = rowData.customer.nameEntreprise
            else val = "client n'existe plus" 
        }
        return(
        <p>{val}</p>
    )}


    const createdAtTemplate = (rowData) => {
        const createdDate = new Date(rowData.createdAt)
        return <p>{createdDate.toLocaleString("fr-FR",{
            timeZone: "Africa/Casablanca"
        })}</p>
    }
    

      const statusFilter=()=>{
        return(
            <MultiSelect
                optionLabel='label'
                optionValue='value'
                placeholder='STATUS'
                value={lazyParams.filters.status}
                options={STATUS}
                className="p-column-filter p-inputtext-sm"
                onChange={onStatusChanged}
                style={{ width:150 }} 
            />
        )
      }


    return (
        <div className="card">
        <div className='overflow-x-auto mb-4 flex align-items-center justify-content-start'>
            {commandeFilter()}
            {dateFilter()}
            {statusFilter()}
        </div>

        <DataTable 
        size='small' 
        lazy
        first={lazyParams.first}
        loading={loading}
        totalRecords={totalRecords} 
        paginator
        onPage={onPage}
        onSort={onSort}
        onSelectionChange={onOrderSelected}
        selectionMode='mulitple'
        ref={dt} 
        value={orders} 
        responsiveLayout="scroll"
        rowHover 
        selection={selectedOrdersToShip}
        rowsPerPageOptions={[10, 25, 50]}
        dataKey="_id" 
        rows={lazyParams.rows} 
        filterDisplay="row"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="afficher {first} à {last} de {totalRecords} commandes"
        className="datatable-responsive"
        emptyMessage={<NoData label='Aucune commande disponible'/>}>
                <Column hidden={true} selectionMode="multiple" headerStyle={{ width: "3rem" }}/>
                <Column body={(val)=>textTemplate(val,'numOrder')} field="numOrder" header="# commande"></Column>
                <Column sortable field="createdAt" header="date" body={createdAtTemplate}></Column>
                <Column body={(val)=>textTemplate(val,'customer')} field="customer.nameEntreprise" header="client"></Column>
                <Column field="status" style={{minWidth:'90px'}} header="statut" body={statusBodyTemplate} ></Column>
                <Column sortable field="totalPriceOrder" header="total" body={priceBodyTemplate}></Column>
                <Column field="quantityTotal" header="quantité"></Column>
                {showActions &&
                    <Column body={actionBodyTemplate}></Column>
                }
        
        </DataTable>
        </div>
    )
}

export default OrdersDatatable