import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid';
import { FileUpload } from 'primereact/fileupload';
import { ImageService } from '../../service/ImageService';
import CustomInput from '../CustomInput';
import CustomSwitch from '../CustomSwitch';

const EditGift = ({ updateGift, giftData }) => {
    
    const [dialogVisibility, setDialogVisibility] = useState(false);
    const hideDialog = () => setDialogVisibility(false)
    const openNew = () => setDialogVisibility(true)
    const [deletedImages, setDeletedImages] = useState([])

    const initialValues = {
        giftName: giftData.giftName,
        photos: giftData.photos,
        quantityStock: giftData.quantityStock,
        pointRequirement: giftData.pointRequirement,
        active: giftData.active,
        newImages: []
    }
    
    const validationSchema = Yup.object().shape({
        giftName: Yup.string().required('nom de gift obligatoire'),
        quantityStock: Yup.number().min(0).required("quantité obligatoire"),
        pointRequirement: Yup.number().min(1).required("points d'exigence obligatoire"),
        photos: Yup.array(),
        active: Yup.bool(),
        newImages: Yup.array().when('photos', {
            is: (array) => array && array.length === 0,
            then: Yup.array().min(1, "photos obligatoire").required('photos obligatoire'),
            otherwise: Yup.array(),
        }),


    })

    useEffect(() => {
        return () => setDeletedImages([])
    },[dialogVisibility])

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {chooseButton}
                {cancelButton}
            </div>
        );
    } 

    const onRemoveImageClicked = (url, photos, setFieldValue) => {
        const newValues = photos.filter((p) => p!= url)
        setFieldValue("photos",newValues);
        setDeletedImages([...deletedImages, url])
    }


    //when an image added
    const onTemplateSelect = (e, newImages, setFieldValue) => {
        const objectURL = e.files[0]?.objectURL
        setFieldValue("newImages", [...newImages, objectURL])
    }

    //when an image removed
    const onTemplateRemove = (e, newImages, setFieldValue) => {
        const newValues = newImages.filter((p) => p != e.file.objectURL)
        setFieldValue('newImages',[...newValues])

    }

    //when all images removed
    const onTemplateClear = (setFieldValue) => {
        setFieldValue('newImages',[])
    }

    const onSubmit = async (values,actions) => {
        const { giftName, photos, quantityStock, pointRequirement, active, newImages} = values
        let photosUrls = [...photos]
        const imageService = new ImageService()
        console.log(photosUrls)
        //upload new images
        for (let i=0; i < newImages.length; i++) {
            const blob = await fetch(newImages[i]).then(r => r.blob()); //get blob url
            const url_image = await imageService.uploadImage(blob,`gifts/${giftName}${uuidv4()}`) // upload to firebase and get url
            photosUrls.push(url_image.data)
        }
        //delete images
        for(let i=0; i < deletedImages.length; i++) {
            await imageService.deletImage(deletedImages[i])
        }

        const data = {
            giftName,
            photos: photosUrls,
            quantityStock,
            pointRequirement,
            active,
        }
        await updateGift(giftData._id, data)
        hideDialog()
    }
    
    
    return(
        <>
        <Button icon="pi pi-pencil" 
        className="p-button-sm p-button-rounded p-button-text p-button-warning" 
        onClick={openNew} />

        
        {/* MODAL */}
        <Dialog draggable={false} visible={dialogVisibility} breakpoints={{'1900px': '60vw', '640px': '100vw'}}
            header="Modifier cadeau" modal 
            className="p-fluid" onHide={hideDialog}>
            
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ handleChange,handleBlur, handleSubmit,isSubmitting, values, errors, touched, setFieldValue })=>{
               
                const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
                const getFormErrorMessage = (name) => {
                    return isFormFieldValid(name) && <small className="p-error">{errors[name]}</small>;
                };

                return(
                <>
                {/* GIFT NAME */}
                <CustomInput
                    label='nom de cadeau'
                    placeholder='nom de cadeau'
                    handleChange={handleChange}
                    value={values.giftName}
                    name='giftName'
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                />

                {/* STOCK */}
                <CustomInput
                    label='Quantité stock'
                    placeholder='Quantité stock'
                    handleChange={handleChange}
                    value={values.quantityStock}
                    name='quantityStock'
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                    keyfilter='pnum'
                />
                {/* POINTS */}
                <CustomInput
                    label='Points'
                    placeholder='Points'
                    handleChange={handleChange}
                    value={values.pointRequirement}
                    name='pointRequirement'
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                    keyfilter='pnum'
                />
                {/* IMAGE DISPLAY */}
                {values.photos.length > 0 &&
                    <div className='card'>
                    <p className='font-bold'>Média</p>
                    <div className='flex flex-wrap'>
                    {values.photos.map((url) => {
                        return (
                            <div key={url} className='mr-3 mb-3 relative' 
                            style={{height:'150px',width:'150px',boxShadow: '5px 5px 5px #0003',overflow:'hidden',borderRadius:5}}>
                            <img src={url} alt={values.giftName} width='150' height='150'/>
                            <div 
                            onClick={()=> onRemoveImageClicked(url, values.photos, setFieldValue) }
                            className='flex align-items-center justify-content-center'
                            style={{position:'absolute',top:5,right:5,height:40,width:40,borderRadius:20,cursor:'pointer',backgroundColor:'#fff'}}>
                                    <i className={'pi pi-trash'} 
                                    style={{color:'#f00'}}></i>
                            </div>
                            </div>
                        )
                    })
                    }
                    </div>
                    </div>
                }
                
                {/*TEMPLATE UPLOAD IMAGES */}      
                <div>
                    <h5>Images</h5>
                    <FileUpload name="demo[]" url="https://primefaces.org/primereact/showcase/upload.php" 
                        headerTemplate={headerTemplate}
                        accept="image/*" 
                        maxFileSize={2000000}
                        customUpload
                        onSelect={(e) => onTemplateSelect(e, values.newImages, setFieldValue)}
                        onError={(e) => onTemplateClear(setFieldValue)} 
                        onClear={(e) =>onTemplateClear(setFieldValue)} 
                        onRemove={(e) => onTemplateRemove(e, values.newImages, setFieldValue)}
                        emptyTemplate={<p className="m-0">Faites glisser et déposez les images ici pour les télécharger.</p>} />
                {getFormErrorMessage('newImages')}
                </div>


                {/* STATUS */}
                <div className='mb-3'>
                <CustomSwitch
                    label='status'
                    name='active'
                    active={values.active} 
                    handleChange={handleChange}/>
                </div>        
                
            
            <div className='mt-4 flex align-items-center justify-content-end'>
                <Button label="annuler" className='w-auto p-button-secondary p-button-sm p-button-outlined mr-2' icon="pi pi-times" 
                onClick={hideDialog}/>
                <Button onClick={handleSubmit} 
                label={isSubmitting?'loading':'sauvegarder'}
                disabled={isSubmitting}
                loading={isSubmitting}
                className='w-auto p-button-sm p-button-success' 
                icon="pi pi-check"  type='submit' />
            </div>
            </>
                )
            }}
            </Formik>
            
            </Dialog>
        </>
    )
}

export default EditGift