import { Dropdown } from 'primereact/dropdown'
import { classNames } from 'primereact/utils'
import React from 'react'

const CustomDropDown = ({
    isFormFieldValid,
    handleChange,
    placeholder,
    value,
    label,
    disabled=false,
    getFormErrorMessage,
    name,
    options,
    filterBy,
    optionValue,
    optionLabel
}) => {

    

    const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.ville}</div>
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    const countryOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.ville}</div>
            </div>
        );
    }

  return (
    <div className='mb-4 justify-content-center'>
    <p className="font-bold mb-2">{label}</p>
        <Dropdown 
        disabled={disabled}
        optionLabel={optionLabel} 
        optionValue={optionValue}
        filterBy={filterBy}
        value={value}
        options={options} 
        onChange={handleChange(name)} 
        filter
        className={classNames({ 'p-invalid':  isFormFieldValid(name)})}
        placeholder={placeholder}
        />
        {getFormErrorMessage(name)}
    </div>
  )
}

export default CustomDropDown