import Api from "../utils/Api";

export class RedeemGiftService {
    async getRedeemGift(){
        let response = {}
        try {
            const res = await Api.get(`redeemgift/getredeemgifts`)
            response.data = res.data
            return response
        } catch (error) {
            response.error = error
            return response
        }
    }

    async updatestatus(id, status){
        let response = {}
        try {
            const res = await Api.put(`redeemgift/updatestatus/${id}`, { status })
            response.data = res.data
            return response
        } catch (error) {
            response.error = error
            return response
        }
    }
}