import React, { useState } from 'react'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import AddLivreurOrder from './AddLivreurOrder'
import { OrderService } from '../../service/OrderService';
import { AdminService } from '../../service/AdminService';
import { NotificationService } from '../../service/NotificationService';

const StatusMenu = ({rowData,setToggleMenu,toggleMenu}) => {

  // MENU ITEMS
  const Items = () => {

    const orderService = new OrderService()
    const adminService = new AdminService()
    const [updateDialogConfirmation, setUpdateDialogConfirmation] = useState(false)
    const [selectedStatus, setSelectedStatus] = useState(null)
    const [loadingUpdate, setLoadingUpdate] = useState(false)
    const hideUpdateDialogConfirmation = () => {
      setUpdateDialogConfirmation(false) 
      setToggleMenu(null)
    }
    const [dialogLivreur, setDialogLivreur] = useState(false);
    const hideDialog = () => {
      setDialogLivreur(false)
      setToggleMenu(null)
    }
    const openDialog = () => setDialogLivreur(true)
  

    let filteredStatus = []
    switch(rowData.status){
      case 'EN COURS' : filteredStatus = ["PRÊT À EXPÉDIER",'ANNULÉE']; break;
      case "PRÊT À EXPÉDIER" : filteredStatus = ['ÉXPEDIÉ',"ANNULÉE"]; break;
      case 'ÉXPEDIÉ' : filteredStatus = ['ANNULÉE']; break;
      case 'ANNULÉE' : filteredStatus = ['PRÊT À EXPÉDIER',"ÉXPEDIÉ","EN COURS"]; break;
      // case 'LIVRÉ' : filteredStatus = ['RETOUR']; break;
    }

    const iconType = (st) => {
      if(st === 'LIVRÉ'){
        return 'pi pi-check-circle'
      } else if(st === 'ANNULÉE'){
        return 'pi pi-times-circle'
      } else if(st === 'ÉXPEDIÉ'){
        return 'pi pi-car'
      } else if(st === 'RETOUR'){
          return 'pi pi-arrow-right'
      } else return 'pi pi-box'
    }


   

    const pushNotificationUpdateOrderStatus = async (orderData, livreurData) => {
      const notificationService = new NotificationService()
      let livreurInformation = ''
      let message = `Votre commande numéro ${orderData.numOrder} ${generateMessageStatusNotification(orderData.status)}`
      if(orderData.status == 'ÉXPEDIÉ')
        livreurInformation = ` \nLivreur : ${livreurData.nameLivreur}\nContact : ${livreurData.phoneNumber}`

      message += livreurInformation
      const _orderData = { _id: orderData._id, urlFacture: orderData.urlFacture,}
      const notifcationData = {
        channel : "order",
        order: JSON.stringify(_orderData),
        title : "Mise à jour de votre commande ",
        body: message,
      }
      const customerTypes = []
      const customerId = orderData.customer._id
      console.log(customerId)
      await notificationService.pushNotification(notifcationData, customerTypes, customerId)
    }

    const generateMessageStatusNotification = (status) => {
      switch (status) {
        case 'PRÊT À EXPÉDIER' : return "est prête à être expédiée.";
        case 'ÉXPEDIÉ' : return `a été ${status} et est en route vers vous.`;
        case 'LIVRÉ' : return  `a été ${status}.`;
        case 'ANNULÉE' : return `a été ${status}.`;
        case 'RETOUR' : return `a été RETOURNÉE.`;
      }
    }

    const createStatusHistory = async (data) => {
      const response = await orderService.createOrderStatusHistory(data)
      if(response.error){
        console.log(response.error)
      }
    }

     //this function not fire when the value of status clicked is EXPEDIE
     const onConfirmUpdateStatusClicked = async () => {
      setLoadingUpdate(true)
      rowData.status = selectedStatus
      if(selectedStatus == "ANNULÉE"){
        rowData.isCanceledByAdmin = true
      }
      const response = await  orderService.updateOrderStatus(rowData)
      if(response.data){
        const { _id } = await adminService.fetchAdminData() //get admin id 
        const data = {
          order: rowData._id,
          status: selectedStatus,
          by : _id // add that do this action
        }      
        await createStatusHistory(data)
        await pushNotificationUpdateOrderStatus(rowData)
      }

      setToggleMenu(rowData._id === toggleMenu ? null : rowData._id )
      setLoadingUpdate(false)
    }

    const updateDialogFooter = () => {
        return (
          <>
            <Button label="non" icon="pi pi-times" className="p-button-text" 
            onClick={hideUpdateDialogConfirmation} />
            <Button 
            label="oui" 
            icon="pi pi-check" 
            className="p-button-text p-button-success" 
            loading={loadingUpdate}
            disabled={loadingUpdate}
            onClick={()=> { onConfirmUpdateStatusClicked()}}/>
          </>
        )
    }

    const DialogConfirmation = () => {
      return (
        <Dialog 
        visible={updateDialogConfirmation} 
        style={{ width: '450px' }} header="Confirmer" modal 
        footer={updateDialogFooter} 
        onHide={hideUpdateDialogConfirmation}>
        <div className="flex align-items-center justify-content-center"> 
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          <div className="flex flex-column align-items-start justify-content-center">
              <span className='mt-2'>êtes-vous sûr de vouloir <b>{selectedStatus}</b> cette commande</span>
          </div>
        </div>
      </Dialog>
      )

    }
  
    return(
      <>
      {
        filteredStatus.map(val=>{
          return(
          <>
            {DialogConfirmation()}
            { dialogLivreur
             && <AddLivreurOrder 
            selectedStatus={selectedStatus} 
            rowData={rowData} 
            hideDialog={hideDialog}
            pushNotificationUpdateOrderStatus={pushNotificationUpdateOrderStatus} /> }
            <div 
            key={val} className='align-items-center flex p-2 pl-3 pr-6 menu-child'
            onClick={() => {
              setSelectedStatus(val)
              val == 'ÉXPEDIÉ' ? setDialogLivreur(true) :  setUpdateDialogConfirmation(true)
             }}
            >
                <i className={iconType(val)}></i>
                <span className='uppercase ml-2'>{val}</span>
            </div>
            </> 
          )
        })
      }
      </>
    )
  }

  return (
    <>
      <Button 
      onClick={() => setToggleMenu(rowData._id === toggleMenu ? null : rowData._id )}
      icon={toggleMenu === rowData._id ? 'pi pi-times' : 'pi pi-bars'} 
      className='p-button-text p-button-secondary p-button-rounded p-2' />
      {
      rowData._id === toggleMenu 
        && <div className='pb-2 pt-2 options-menu mt-6'>
            {Items()}
        </div>
      }
   </>
  )
}

export default StatusMenu