import { atom } from "recoil";

export const lazyParamsBrand = atom({
  key: "lazyParamsBrand",
  default: {
    first: 0,
    rows: 10,
    page: 1,
    sortfield: null,
    sortorder: -1,
    totalRecords: 0,
    filters: {
      active: null,
      brandName: null,
    }
  },
});
