import React,{ useState, useEffect, memo } from 'react'
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import VariantsBlock from './VariantsBlock';
const COLORS = require('../../../utils/Colors.json')

const OptionsBlock = ({
  productId,
  options,
  setOptions,
  variants,
  setVariants,
  hasVariant,
  handleChange}) => {
  
  const initialValues = {
    optionName: '',
    optionValues:''
  }
  
  const [optionValues,setOptionValues]=useState([]) // VALUES OF OPTION
  const [optionNameError,setOptionNameError]=useState('') // OPTION NAME ERROR
  const [emptyValuesError,setEmptyValuesError]=useState('') // EMPTY VALUES ERROR
  const [values, setValues] = useState(initialValues); // OPTION NAME AND VALUES FOR INPUT
  const [optionFormVisibility,setOptionFormVisibility] = useState(true)
  const [selectedColor,setSelectedColor] = useState(null) // FOR COLOR DROPDOWN
  const [colors,setColors]=useState(COLORS)


  const handleCheckBox=(e) => {
    handleChange('hasVariant')(e)
    setOptionValues([])
    setValues(initialValues)
    setOptions([])
    setOptionFormVisibility(false)
    setEmptyValuesError('')
    setOptionNameError('')
  }

  // INPUT CHANGE FOR VALUE AND OPTION
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  // CHECK IF VALUES ARE DUPLICATED 
  const checkDuplicateValue=()=>{
    const removeSpaces = values.optionValues.trim()
    const lowerCase = removeSpaces.toLocaleLowerCase()
    let duplicateFound = false
    const checkDuplicates = optionValues.filter(option=>option.name === lowerCase)
    if (checkDuplicates.length > 0){
      duplicateFound = true
    }
    return duplicateFound
  }

  // CHECK IF VALUES ARE DUPLICATED 
  const checkDuplicateOptionName=()=>{
    const removeSpaces = values.optionName.trim()
    const lowerCase = removeSpaces.toLocaleLowerCase()
    let duplicateFound = false
    const checkDuplicates = options.filter(option => option.nameOption === lowerCase)
    if (checkDuplicates.length > 0){
      duplicateFound = true
    }
    return duplicateFound
  }
  
  // REMOVE VALUE FROM VALUES ARRAY
  const spliceOptionValue=(index)=>{
      const newArr=[...optionValues]
      newArr.splice(index,1)
      setOptionValues(newArr)
  }

  // REMOVE VALUE FROM VALUES ARRAY
  const spliceColorOptionValue=(color,index)=>{
    const newArr=[...optionValues]
    newArr.splice(index,1)
    setOptionValues(newArr)
    setColors([...colors,selectedColor])
    setSelectedColor(null)
}

  // PUSH VALUE TO VALUES ARRAY
  const addValueOption=()=>{
    const removeSpaces = values.optionValues.trim()
    const lowerCase = removeSpaces.toLocaleLowerCase()
    const val = { name: lowerCase, quantityStock: 0 }
    if (!checkDuplicateValue()){
      setOptionValues([...optionValues,val])
      setValues({...values,optionValues:''})
    }
  }
  
  // PUSH COLOR HEX TO VALUES ARRAY
  const uniqueColors=()=>{
    let uniqueArray = []
    if(optionValues.length!==0){
      for(let hex in optionValues){
        uniqueArray = colors.filter(color=>color.hex !== optionValues[hex].name)
      } 
    }else{
      uniqueArray = colors
    }
    setColors(uniqueArray)

  }
  
  useEffect(()=>{
    uniqueColors()
  },[optionValues])

  

  // PUSH OPTION TO OPTIONS ARRAY
  const pushOption=()=>{
    const option={
      numOption:options.length+1,
      nameOption:values.optionName,
      values: optionValues,
    }
    if(values.optionName === ''){
      setOptionNameError("le nom de l'option est obligatoire")
    }else if(optionValues.length===0){
      setEmptyValuesError(`ajouter des valeurs pour l'option (${values.optionName})`)
    }
    else{    
      if(!checkDuplicateOptionName()){
        setOptions([...options,option])
        setOptionValues([])
        setEmptyValuesError('')
        setValues(initialValues)
        setOptionFormVisibility(false)
      }
    }
  }

  // SPLICE SINGLE OPTION FROM OPTIONS ARRAY
  const spliceOption=(index,nameOption)=>{
    const newArr=[...options]
    newArr.splice(index,1)
    setOptions(newArr)
    if(nameOption === 'couleur'){
      setColors(COLORS)
      setSelectedColor(null)
    }
  }


  const handleColorChange=(e)=>{
    setValues({
      ...values,
      optionValues: e.target.value.hex,
    })
    setSelectedColor(e.value)
  }

  // DROPDOWN SELECTED VALUE TEMPLATE
  const selectedColorTemplate=(option,props)=>{
      if (option) {
        return (
            <div className='flex'>
              <div className='mr-3' style={{height:20,width:20,borderRadius:10,backgroundColor:option.hex}}></div>
                <div>{option.name}</div>
            </div>
        );
    }
    return (
        <span>
          {props.placeholder}
        </span>
    );
    
  }
  
  // COLOR DROPDOWN TEMPLATE
    const itemTemplate = (item) => {
      return (
          <div className="country-item flex">
            <div style={{height:20,width:20,backgroundColor:item.hex,borderRadius:10}} className='mr-2'/>
            <div>{item.name}</div>
          </div>
      );
    }

  return (
    <>
    <div className='card col-12'>
    <p className='font-bold mb-4'>Options</p>

    <div className="flex align-items-center">
        <Checkbox onChange={handleCheckBox} checked={hasVariant} inputId="val1" />
        <label htmlFor="val1" className="ml-2">Ce produit comporte des options, comme la taille ou la couleur</label>
    </div>

    {hasVariant
      &&<>
      {options.length > 0 && <Divider type='solid' />}
      
      {/* LIST OPTION ARRAY */}
      { 
        options.map(({nameOption,values},index)=>{
          const checkHex = values[0].name.charAt(0)
           return <div key={index.toString()} 
              style={{border:'1px solid #eee',borderRadius:'5px'}} 
              className='p-2 mb-2 flex align-items-center'>

              <div className='flex flex-column w-full'>
              <p className='font-bold'>{nameOption}</p>
              <div className='flex flex-wrap'>
              {
                values.map(({name},index)=>(
                  <div key={index.toString()} className='mr-1'> 
                    { checkHex === '#'
                    ? <div style={{backgroundColor:name,height:20,width:20,borderRadius:10}} />
                    : 
                    <div style={{backgroundColor:'#DEE2E6',borderRadius:'20px'}} 
                      className='mb-2 p-2 px-3 flex align-items-center'>
                    <p className='font-normal text-sm' style={{color:'#1f1f1f'}}>{name}</p>
                    </div>
                    } 
                  </div>
                ))
              }
              </div>
            </div>
              <div className='flex'>
              <Button 
              onClick={()=>spliceOption(index,nameOption)}
              tooltip='supprimer'
              icon="pi pi-trash" 
              className="p-button-rounded p-button-danger p-button-text" 
              aria-label="filter" />
              </div>
            </div>
          
        }) 
      }

      {/* {options.length > 0 && <Divider type='solid' />} */}

      {/* FORM TO ADD OPTION */}
      { optionFormVisibility
        &&<div className='mt-5'>

      {/* ADD OPTION NAME */}
      <div className='flex flex-column mb-3'>
          <p className='mb-2'>{`Nom de l'option pour ajouter des couleur écrire <<couleur>>`}</p>
          <div className='flex'>
          <div className='flex w-full flex-column'>
            <InputText 
            name='optionName'
            disabled={optionValues.length>0}
            value={values.optionName}
            onChange={handleInputChange}
            placeholder='taille, couleur...' 
            className={`w-full ${classNames({ 'p-invalid':  (optionNameError && values.optionName ==='')})}`}/>

            { (optionNameError && values.optionName ==='') 
            && <div className='mt-2 flex align-items-center justify-content-start'>
                <i className='mr-1 pi pi-info-circle' style={{color:'#f00'}}></i>
                <p style={{color:'#f00'}}>{optionNameError}</p>
              </div>}

            {
              checkDuplicateOptionName()
              && <div className='mt-2 flex align-items-center justify-content-start'>
                  <i className='mr-1 pi pi-info-circle' style={{color:'#f00'}}></i>
                  <p style={{color:'#f00'}}>{`vous avez déja utilisé le nom de l'option ${values.optionName}`}</p>
                </div>
            }

          </div>
          </div>
      </div>

      {/* LIST OPTION VALUES */}
      <div className='w-full flex flex-wrap align-items-center justify-content-start'>
      {
        optionValues.map(({name}, index) =>{
          const checkHex = name.charAt(0)
          return (
          <div key={index.toString()}>
          <> 
            { checkHex === '#'
            ? 
            <div 
            style={{backgroundColor:'#DEE2E6',borderRadius:'20px'}} className='flex align-items-center justify-content-center mr-2 mb-2 px-2 py-2'>
            <div className='mr-4' style={{backgroundColor:name,height:20,width:20,borderRadius:10}} />            
            <div 
            style={{cursor:'pointer'}}
            onClick={()=>spliceColorOptionValue(name,index)} >
              <i className='pi pi-times-circle' style={{color:'#838584',fontSize:'18px'}}></i>
            </div>
            </div>
            : 
            <div
            style={{backgroundColor:'#DEE2E6',borderRadius:'20px'}} className='flex align-items-center justify-content-center mr-2 mb-2 px-2 py-2'>
            <div className='mr-4'><p className='font-normal text-sm' style={{color:'#1f1f1f'}}>{name}</p></div>
            <div style={{cursor:'pointer'}} onClick={()=>spliceOptionValue(index)} >
            <i className='pi pi-times-circle' style={{color:'#838584',fontSize:'18px'}}></i>
            </div>
            </div>
            
            } 
          </>
          
          

          </div>)
        })
      }
      </div>

      {/* ADD OPTION VALUES */}
      <div className='mt-2 flex flex-column'>
          <p className='mb-2'>Valeurs de l'option</p>
          <div className='flex'>
            <div className='flex w-full flex-column'>
              {
              ( !checkDuplicateOptionName() && values.optionName === 'couleur')  
              ? 
              <div className='flex'>
              <Dropdown 
              value={selectedColor} 
              onChange={handleColorChange}
              itemTemplate={itemTemplate}
              valueTemplate={selectedColorTemplate}
              options={colors} 
              name='optionValues'
              optionLabel="name" 
              className='w-full'
              placeholder="selectionnez un couleur" />
              <div>
              <Button
                  disabled={values.optionValues === ''} 
                  onClick={addValueOption}
                  icon="pi pi-plus" 
                  className='ml-2' 
                  aria-label="filter" />
              </div>
              </div>
              :
              <div className='flex'>
              <InputText 
              onChange={handleInputChange}
              name='optionValues'
              value={values.optionValues}
              placeholder='valeur' 
              className={`w-full ${classNames({ 'p-invalid':  checkDuplicateValue()})}`}/>
              
              <div>
              <Button
                  disabled={values.optionValues === ''} 
                  onClick={addValueOption}
                  icon="pi pi-plus" 
                  className='ml-2' 
                  aria-label="filter" />
              </div>
              </div>
              
              }
              
              { checkDuplicateValue() 
              && <div className='mt-2 flex align-items-center justify-content-start'>
                <i className='mr-1 pi pi-info-circle' style={{color:'#f00'}}></i>
                <p style={{color:'#f00'}}>{`vous avez déjà utilisé la valeur (${values.optionValues}) pour cette option`}</p>
              </div>}
              
              { (emptyValuesError && optionValues.length === 0) 
              && <div className='mt-2 flex align-items-center justify-content-start'>
              <i className='mr-1 pi pi-info-circle' style={{color:'#f00'}}></i>
              <p style={{color:'#f00'}}>{emptyValuesError}</p>
              </div>}

            </div>
          
          </div>
      </div>

      {/* PUSH OPTION TO OPTIONS ARRAY */}
      <Button 
      onClick={pushOption}
      className='mt-4 p-button-outlined p-button-secondary' label='términer' />
      
      </div>}

      { options.length < 2
      &&<>
      <Divider type='solid' />
      <div 
      onClick={()=>setOptionFormVisibility(true)}
      style={{cursor:'pointer'}} className='w-full flex align-items-center justify-content-start'>
        <i className='pi pi-plus' style={{color:'#6366F1'}}></i>
        <div><p style={{color:'#6366F1'}} className='ml-2'>Ajouter une autre option</p> </div>
      </div>
      </>}
      </>
    }

    </div>

    <VariantsBlock 
      productId={productId} 
      variants={variants} 
      setVariants={setVariants} 
      options={options} />
      
    </>
  )
}

export default memo(OptionsBlock)