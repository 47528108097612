import Api from '../utils/Api'

export class SettingsService {
    
    async getEstablishmentInfo (){
        let response  = {}
        try {
            const establishmentInfo = await Api.get(`establishmentinfo`)
            response.data = establishmentInfo.data
            return response
        } catch (error) {
            response.error = error
            return response
        }
    }

    async addEstablishmentInfo (establishmentInfo){
        let response = {}
        try {
            console.log(establishmentInfo)
            const res = await Api.post(`establishmentinfo/create`,establishmentInfo)
            response.data = res.data
            return response
        } catch (error) {
            response.error = error
            return response
        }
    }

    async deleteEstablishmentInfo(establishmentInfo){
        let response = {}
        try {
            console.log(establishmentInfo)
            const res = await Api.delete('establishmentinfo/delete', {data: establishmentInfo})
            response.data = res.data
            return response
        } catch (error) {
            response.error = error
            return response
        }
    }
}