import { Button } from "primereact/button";
import React from "react";
import { useNavigate } from "react-router-dom";
import DeleteAdmin from "./DeleteAdmin";

function AdminBlock({ admin }) {
  const navigate = useNavigate();

  return (
    <>
      <div className=" col-12 md:col-6 xl:col-3 p-1">
        <div className="card">
          <div>
            <p className="text-800 font-bold">{admin.name}</p>
            <p className="text-800">{admin.email}</p>
          </div>
          <div className="flex flex-row justify-content-between align-items-center">
            <Button
              onClick={() =>
                navigate(`/admins/update/${admin?._id}`, { replace: true })
              }
              tooltip="modifier les infos"
              icon="pi pi-pencil"
              className="p-button-text p-button-secondary p-button-rounded"
            />
            <Button
              onClick={() =>
                navigate(`/admins/updatepassword/${admin?._id}`, {
                  replace: true,
                })
              }
              tooltip="modifier mot de pass"
              icon="pi pi-lock"
              className="p-button-text p-button-secondary p-button-rounded"
            />
            <Button
              onClick={() =>
                navigate(`/admins/roles/${admin?._id}`, { replace: true })
              }
              tooltip="modifier les roles"
              icon="pi pi-list"
              className="p-button-text p-button-secondary p-button-rounded"
            />
            <DeleteAdmin name={admin.name} adminId={admin._id} />
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminBlock;
