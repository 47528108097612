import Api from "../utils/Api";
import { generateErrorMessage } from "../utils/generateErrorMessage";

export class BrandsService {

  async createBrand (data){
    let response = {}
    try {
      const res = await Api.post(`brand/create`,data)
      response.data = res.data
      return response
    } catch (error) {
      response.error = generateErrorMessage(error)
      return response
    }
  }

  async updateBrand(id, data){
    let response = {}
    try {
      const res = await Api.put(`brand/update/${id}`,data)
      response.data = res.data
      return response
    } catch (error) {
      response.error = generateErrorMessage(error)
      return response
    }
  }

  async getAllBrands(queryParameters){
    let response = {}
    try {
      const res = await Api.get(`brand/getallbrands?${queryParameters}`)
      response.data = res.data
      return response
    } catch (error) {
      response.error = generateErrorMessage(error)
      return response
    }
  }

  async getBrandsWithPagination(queryParameters){
    let response = {}
    try {
      console.log(queryParameters)
      const res = await Api.get(`brand/getbrandswithpagination?${queryParameters}`)
      console.log("brands: ",res.data)
      response.data = res.data
      return response
    } catch (error) {
      response.error = generateErrorMessage(error)
      return response
    }
  }

}