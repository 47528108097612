import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { TreeSelect } from 'primereact/treeselect';
import { InputSwitch } from 'primereact/inputswitch';
import { Field, Formik } from 'formik';
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid';
import { ImageService } from '../../service/ImageService';
import CustomInput from '../CustomInput';
import { CategoryService } from '../../service/CategoryService';


const AddCategory=({ createCategory })=>{

    const imageService = new ImageService()
    const categoryService = new CategoryService()
    const [dialogVisibility, setDialogVisibility] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null)
    const [categories, setCategories] = useState([])
    const [loadingCategories, setLoadingCategories] = useState(false)
    const hideDialog = () => setDialogVisibility(false)
    const openNew = () => setDialogVisibility(true)

    useEffect(() => {
        if(dialogVisibility){
            getCategories()
        }   

        return () => {
            setSelectedImage(null)
        }
    },[dialogVisibility])

    const getCategories = async () => {
        setLoadingCategories(true)
        const response = await categoryService.getCategories()
        if(response.data){
            const tree = categoryService.buildTree(response.data)
            console.log(tree)
            setCategories(tree)
        }
        setLoadingCategories(false)


    }

    const initialValues = {
        nameCategory: '',
        parentCategory: '',
        photo: '',
        active: true,
    } 
    
    const validationSchema = Yup.object().shape({
        nameCategory: Yup.string().required('Nom de catégorie obligatoire'),
        parentCategory: Yup.string(),
        photo: Yup.string().required('Image obligatoire'),
        active: Yup.boolean()
    })



    const onSubmit=(values,actions)=>{
        handleSubmit(values)
    }

    const handleSubmit = async(values) => {
        console.log(values)
        const { nameCategory, parentCategory, photo, active } = values
        const blob = await fetch(photo).then(r => r.blob()); //get blob url
        const url_image = await imageService.uploadImage(blob,`categories/${nameCategory}-/${uuidv4()}`)
        const data = { 
            nameCategory, 
            parentCategory: parentCategory == "" ? null : parentCategory, 
            photo: { url: url_image.data },
            active
        }
        await createCategory(data)
        hideDialog()
    };

    //when an image added
    const onTemplateSelect = (e, setFieldValue) => {
        const objectURL = e.files[0]?.objectURL
        setSelectedImage(objectURL)
        setFieldValue('photo',objectURL)
    }

    //when an image removed
    const onTemplateRemove = (setFieldValue) => {
        setSelectedImage(null)
        setFieldValue('photo', '')
    }

    //when all images removed
    const onTemplateClear = (setFieldValue) => {
        setSelectedImage(null)
        setFieldValue('photo', '')
    }

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {
                 selectedImage == null && chooseButton
                }
                {cancelButton}
            </div>
        )
    } 

    return(
        <>
        <Button 
        label="nouvelle catégorie" 
        icon="pi pi-plus" 
        className="mr-2" 
        onClick={openNew} />
        
        {/* MODAL */}
        <Dialog draggable={false} visible={dialogVisibility} breakpoints={{'1900px': '60vw', '640px': '100vw'}}
            header="Ajouter une nouvelle catégorie" modal 
            className="p-fluid" onHide={hideDialog}>
            
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ handleChange, handleBlur, handleSubmit,isSubmitting, values, errors, touched, setFieldValue })=>{
               
                const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
                const getFormErrorMessage = (name) => {
                    return isFormFieldValid(name) && <small className="p-error">{errors[name]}</small>;
                };

                return(
                <>
                <CustomInput
                    label='nom de catégorie'
                    placeholder='nom de catégorie'
                    handleChange={handleChange}
                    value={values.nameCategory}
                    name='nameCategory'
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                />
                <div className='mb-3'>
                    <p className="mb-2 font-bold">Catégorie parente</p>   
                <TreeSelect
                    value={values.parentCategory} 
                    onChange={handleChange("parentCategory")} 
                    options={categories} 
                    disabled={loadingCategories}
                    className="md:w-20rem w-full" 
                    placeholder={loadingCategories ? "loading" : "select catégorie"}
                    filter
                />
                </div>

                <div>
                    <h5>Image</h5>
                    <FileUpload name="demo[]" url="https://primefaces.org/primereact/showcase/upload.php" 
                        multiple={false}
                        headerTemplate={headerTemplate}
                        accept="image/*" 
                        maxFileSize={2000000}
                        onSelect={(e) => onTemplateSelect(e,setFieldValue)}
                        onError={(e) => onTemplateClear(setFieldValue)} 
                        onClear={(e) =>onTemplateClear(setFieldValue)} 
                        onRemove={(e) => onTemplateRemove(setFieldValue)}
                        emptyTemplate={<p className="m-0">Faites glisser et déposez l'image ici.</p>} 
                        />
                        { getFormErrorMessage('photo')}

                </div> 

                {/* STATUS */}
                <div className='mb-3'>
                    <p className="mb-2">active</p>
                    <InputSwitch checked={values.active}
                    onChange={handleChange('active')} />
                    {getFormErrorMessage('active')}
                </div>

                <div className='mt-4 flex align-items-center justify-content-end'>
                    <Button label="annuler" 
                    className='w-auto p-button-secondary p-button-outlined p-button-sm mr-2' icon="pi pi-times" onClick={hideDialog}/>
                    <Button 
                        disabled={isSubmitting}
                        onClick={handleSubmit} 
                        label={isSubmitting?'loading':'sauvegarder'}
                        loading={isSubmitting} 
                        className='w-auto p-button-sm p-button-success' 
                        icon="pi pi-check"  
                        type='submit' />
                </div>
            </>
                )
            }}
            </Formik>
            
        </Dialog>
        </>
    )
}

export default AddCategory
