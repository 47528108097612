import { atom } from "recoil";

export const lazyParamsProduct = atom({
  key: "lazyParamsProduct",
  default: {
    first: 0,
    rows: 10,
    page: 1,
    sortfield: null,
    sortorder: -1,
    // totalRecords: 0,
    filters: {
      selectedCategory: null,
      selectedBrand: null,
      active: true,
      reference: null,
      nameProduct: null,
      codeArticle: null,
    }
  },
});

export const totalProductsAtom = atom({
  key: "totalProductsAtom",
  default: 0,
})
