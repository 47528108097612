import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Formik } from 'formik';
import * as Yup from 'yup'
import CustomSwitch from '../CustomSwitch';
import CustomInput from '../CustomInput';

const UpdateFamily = ({rowData,updateFamily}) => {

    const [dialogVisibility, setDialogVisibility] = useState(false);
    const [content] = useState(rowData);
    const hideDialog = () => setDialogVisibility(false)
    const openModal = () => setDialogVisibility(true)
  
    const initialValues = {
      _id:content._id,
      nameFamily: content.nameFamily
    }
  
    const validationSchema = Yup.object().shape({
        nameFamily: Yup.string().required('famille obligatoire')
    })
  
    const onSubmit = async (values,actions) => {
      const data = {
        nameFamily: values.nameFamily
      }
      await updateFamily(values._id,data)
      hideDialog()
    }


    return (
        <>
        <div 
            className='align-items-center flex p-2 pl-3 pr-6 menu-child'
            onClick={openModal}
            >
            <i className='pi pi-pencil'></i>
            <span className='uppercase ml-2'>modifier</span>
        </div>
        
        {/* MODAL */}
        <Dialog draggable={false} visible={dialogVisibility} breakpoints={{'1900px': '60vw', '640px': '100vw'}}
            header="Modifié type" modal 
            className="p-fluid" onHide={hideDialog}>
            
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ 
                handleChange,handleBlur, 
                handleSubmit,isSubmitting, values, 
                errors, touched })=>{
                    
                const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
                const getFormErrorMessage = (name) => {
                    return isFormFieldValid(name) && <small className="p-error">{errors[name]}</small>;
                };
    
                return(
                <>
                {/* NAME FAMILY */}
               <CustomInput
                    label='famille'
                    placeholder='famille'
                    handleChange={handleChange}
                    value={values.nameFamily}
                    name='nameFamily'
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                />
                
                
            
            <div className='mt-4 flex align-items-center justify-content-end'>
                <Button label="annuler" 
                className='w-auto p-button-sm p-button-secondary p-button-outlined mr-2' icon="pi pi-times" 
                onClick={hideDialog}/>
                <Button onClick={handleSubmit} 
                label={isSubmitting?'loading':'modifier'}
                disabled={isSubmitting}
                loading={isSubmitting}
                className='w-auto p-button-sm p-button-warning' 
                icon="pi pi-pencil"  type='submit' />
            </div>
            </>
                )
            }}
            </Formik>
            
            </Dialog>
        </>
      )
}

export default UpdateFamily