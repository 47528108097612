import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { LivreursService } from '../service/LivreursService';
import AddLivreur from '../components/livreurs/AddLivreur'
import EditLivreur from '../components/livreurs/EditLivreur'
import NoData from '../components/NoData';
import OptionsMenu from '../components/OptionsMenu';
import SingleDelete from '../components/SingleDelete';


const Livreurs = () => {

    const livreursService = new LivreursService()
    const [livreurs, setLivreurs] = useState(null);
    const [loading, setLoading] = useState(true);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [toggleMenu, setToggleMenu] = useState(null); // toggle menu state

    const dt = useRef(null);

    async function getLivreurs(){
        setLoading(true)
        const response = await livreursService.getLivreurs()
        if(response.data){
            console.log("livreurs response", response.data)
            setLivreurs(response.data)
        } else {
            console.log(response.error);
        }
        setLoading(false)

    }


    useEffect(() => {
        getLivreurs()
    }, []);


    // CREATE LIVREUR
    const createLivreur = async (data) => {
        const response = await livreursService.createLivreur(data)
        if(response.data){
            getLivreurs()
        } else {
            console.log(response.error);
        }
    }

    // UPDATE LIVREUR
    const updateLivreur = async (id,data) => {
        const response = await livreursService.updateLivreur(id,data)
        if(response.data){
            getLivreurs()
        } else {
            console.log(response.error);
        }
    }

    // UPDATE LIVREUR
    const deleteLivreur = async (id) => {
        const response = await livreursService.deleteLivreur(id)
        if(response.data){
            getLivreurs()
        } else {
            console.log(response.error);
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <OptionsMenu
                rowData={rowData} 
                setToggleMenu={setToggleMenu} 
                toggleMenu={toggleMenu}>
            {
            rowData._id === toggleMenu 
              && <div className='pb-2 pt-2 options-menu'>
                    <EditLivreur toggleMenu={toggleMenu} setToggleMenu={setToggleMenu} updateLivreur={updateLivreur} rowData={rowData}/>
                    <SingleDelete id={rowData?._id} name={rowData?.nameLivreur} deleteFunction={()=>deleteLivreur(rowData._id)} setToggleMenu={setToggleMenu}/>
              </div>
            }
          </OptionsMenu>
        );
    }


  return (
    <div className="grid crud-demo">
            <div className="col-12">
                <div>

                <div className='mb-4 flex alignItems-center justify-content-between'>
                    <div className='flex align-items-center justify-content-center'>
                        <p className='text-2xl font-semibold'>Livreurs</p>
                    </div>
                    <AddLivreur createLivreur={createLivreur} />
                </div>
                
                <div className='card'>
                    <DataTable size='small' 
                    stripedRows 
                    ref={dt} 
                    value={livreurs} 
                    responsiveLayout="scroll"
                    loading={loading} 
                    rowHover
                    globalFilter={globalFilter} 
                    paginator
                    dataKey="id" 
                    rows={25} 
                    rowsPerPageOptions={[5, 10, 25, 50]} 
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Afficher {first} à {last} de {totalRecords} livreurs"
                    emptyMessage={<NoData label='Aucune livreur disponible' />}>
                            <Column sortable field="nameLivreur" header="Nom complet"></Column>
                            <Column sortable field="phoneNumber" header="Numéro du téléphone"></Column>
                            <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>  
                </div>
            </div>
        </div>
  )
}

export default Livreurs