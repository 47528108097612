import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { MultiSelect } from 'primereact/multiselect';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid';
import CustomInput from '../CustomInput';

const AddLivreur = ({ createLivreur }) => {
    const [dialogVisibility, setDialogVisibility] = useState(false);
    const hideDialog = () => setDialogVisibility(false)
    const openNew = () => setDialogVisibility(true)

    const initialValues = {
        nameLivreur: '',
        phoneNumber:'',
    }
    
    const validationSchema = Yup.object().shape({
        nameLivreur: Yup.string().required('nom de livreur obligatoire'),
        phoneNumber: Yup.string()
                        .min(10, "saisir 10 nombre")
                        .max(10, "saisir 10 nombre")
                        .required("numero de telephone obligatoire")

    })

    const onSubmit = async (values,actions) => {
        await createLivreur(values)
        hideDialog()
    }
    
    
    return(
        <>
        <Button 
        label="ajouter nouveau livreur" 
        icon="pi pi-plus" 
        className="mr-2" 
        onClick={openNew} />
        
        {/* MODAL */}
        <Dialog draggable={false} visible={dialogVisibility}
         breakpoints={{'1900px': '60vw', '640px': '100vw'}}
            header="Ajouter nouveau livreur" modal 
            className="p-fluid" onHide={hideDialog}>
            
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ handleChange,handleBlur, handleSubmit,isSubmitting, values, errors, touched })=>{
               
                const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
                const getFormErrorMessage = (name) => {
                    return isFormFieldValid(name) && <small className="p-error">{errors[name]}</small>;
                };
                return(
                <>
                {/* name */}
                <CustomInput
                label='nom et prénom'
                placeholder='nom complet de livreur'
                handleChange={handleChange}
                value={values.nameLivreur}
                name='nameLivreur'
                isFormFieldValid={isFormFieldValid}
                getFormErrorMessage={getFormErrorMessage}
                />

                {/* phone */}
                <CustomInput
                label='numéro de téléphone'
                placeholder='numéro de téléphone'
                handleChange={handleChange}
                value={values.phoneNumber}
                name='phoneNumber'
                isFormFieldValid={isFormFieldValid}
                getFormErrorMessage={getFormErrorMessage}
                />
                
                
            <div className='mt-4 flex align-items-center justify-content-end'>
                <Button label="annuler" className='w-auto p-button-secondary p-button-sm p-button-outlined mr-2' icon="pi pi-times" 
                onClick={hideDialog}/>
                <Button onClick={handleSubmit} 
                label={isSubmitting?'loading':'sauvegarder'}
                disabled={isSubmitting}
                loading={isSubmitting}
                className='w-auto p-button-sm p-button-success' 
                icon="pi pi-check"  type='submit' />
            </div>
            </>
                )
            }}
            </Formik>
            
            </Dialog>
        </>
    )
}

export default AddLivreur