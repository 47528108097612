import React, {memo} from 'react'
import { InputSwitch } from 'primereact/inputswitch'

const StatusBlock = ({name,label,active,handleChange}) => {
  
  return (
   <>
      <p className="font-bold mb-4">{label}</p>
      <div className='flex'>
      <InputSwitch checked={active} onChange={handleChange(name)}/>
      <p className='ml-2' >{active ? 'actif' : 'inactif'}</p>
      </div>
    </>
  )
}

export default memo(StatusBlock)