import Api from '../utils/Api'

export class ProductService {

    async getProducts(queryParameters) {
        let response = {}
        try {
            const products = await Api.get(`product/backoffice?${queryParameters}`)
            response.data = products.data
            return response
        } catch (error) {
            response.error =  error
            return response
        }
    }

    async getProductDetail(id) {
        let response = {}
        try {
            const products = await Api.get(`product/${id}`)
            response.data = products.data
            return response
        } catch (error) {
            response.error =  error
            return response
        }
    }

    async addProduct (data) {
        let reponse = {}
        try {
            const createdProduct = await Api.post(`product/create`,data)
            reponse.data = createdProduct.data
            return reponse
        } catch (error) {
            reponse.error = error.response.data.message
            return reponse
        }
    }

    async updatedProduct (productId,data) {
        let response = {}
        try {
            const updatedProduct = await Api.put(`product/update/${productId}`,data)
            response.data = updatedProduct.data
            return response
        } catch (error) {
            response.error = error.response.data.message
            return response
        }
    }

    async removeProduct (productId) {
        let response = {}
        try {
            const deletedProduct = await Api.delete(`product/delete/${productId}`)
            response.data = deletedProduct.data
            return response
        } catch (error) {
            response.error = error
            return response
        }
    }

    async getProductName (productTitle){
        let response = {}
        try {
            const productResponse = await Api.get(`/product/mobile?active=true&nameProduct=${productTitle}`)
            response.data = productResponse.data
            return response 
        } catch(error){
            response.error = error
            return response
        }
    }



}