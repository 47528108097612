import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DialogConfirmation from "../DialogConfirmation";
import { CustomersService } from "../../service/CustomersService";
import { UpdatePasswordModal } from "./ChangePassword";

const ConnectedDevices = ({  customerId, connectedDevices }) => {

    const customerService =  new CustomersService()

    const [dialogVisibility, setDialogVisibility] = useState(false);
    const openNew = () => setDialogVisibility(true)
    const hideDialog = () => setDialogVisibility(false);

    const [dialogConfirmationSingle, setDialogConfirmationSingle] = useState(false)
    const openDialogConfirmationSingle = () => setDialogConfirmationSingle(true)
    const hideDialogConfirmationSingle = () => setDialogConfirmationSingle(false)

    const [dialogConfirmationMultiple, setDialogConfirmationMultiple] = useState(false)
    const openDialogConfirmationMultiple = () => setDialogConfirmationMultiple(true)
    const hideDialogConfirmationMultiple = () => setDialogConfirmationMultiple(false)

    const [loadingDisconnect, setLoadingDisconnect] = useState(false)

    const [selectedDevices, setSelectedDevices] = useState([])
    const [showUpdatePassword, setShowUpdatePassword] = useState(false)

    const actionBodyTemplate = (rowData) => {
        return (
            <div 
                className="flex align-items-center"
                style={{
                    textAlign: "center",
                }}
            >
                <p className="m-0">{rowData.deviceModel}</p>
                <DialogConfirmation 
                    headerTitle={"Confirmer"}
                    message={"êtes-vous sûr de vouloir déconnecter cet appareil"}
                    visibilityDialog={dialogConfirmationSingle}
                    onHideDialog={hideDialogConfirmationSingle}
                    onConfirmClicked={async () => await onDisconnectConfirmerd(customerId, rowData.deviceId )}
                    loading={loadingDisconnect}
                    ButtonShowDialog={() => 
                        <Button 
                            icon="pi pi-sign-out"
                            tooltip="déconnecter"
                            tooltipOptions={{ position: 'top' }}
                            className="p-button-sm p-button-rounded p-button-danger ml-2" 
                            onClick={() => openDialogConfirmationSingle()}
                        />
                    }
                />
            </div>
        )
    }

    const onDisconnectConfirmerd = async (customerId, deviceId) => {
        console.log("onDisconnectConfirmer: ", deviceId)
        setLoadingDisconnect(true)
        const response = await customerService.disconnectDevice(customerId, deviceId)
        if(response.data){
            hideDialogConfirmationSingle()
            setShowUpdatePassword(true)
            hideDialog()
        }
        setLoadingDisconnect(false)

    }

    const onDisconnectMultipleDeviceConfirmed = async (customerId) => {
        setLoadingDisconnect(true)
        for(let i = 0; i <selectedDevices.length; i++) {
            await customerService.disconnectDevice(customerId, selectedDevices[i].deviceId)
        }
        hideDialogConfirmationSingle()
        setLoadingDisconnect(false)
        setShowUpdatePassword(true)
        hideDialog()

    }

    const onSelectionChange = (e) => {
        setSelectedDevices(e.value)
    }

    return (
        <>
        <div className="relative inline-block">
        {connectedDevices.length > 0 &&
            <div
                className="absolute bg-red font-bold z-10" 
                style={{
                    width: '20px',
                    height: '20px',
                    top: '-5px',
                    right: '-5px',
                    zIndex: 10,
                    backgroundColor: "red",
                    border: '2px solid red',
                    borderRadius: "50%",
                    fontSize: '12px',
                    textAlign: 'center', /* Center the text horizontally */
                    lineHeight: '20px', /* Ensure the text is vertically centered */      
                }}
            >
                <p className="text-white" >{connectedDevices.length}</p>
            </div>
        }
            
            <Button 
                icon="pi pi-mobile"
                tooltip="appareils connectés"
                tooltipOptions={{ position: 'top' }}
                className="p-button-sm p-button-rounded p-button-success" 
                onClick={openNew} 
            />
        </div>
        <UpdatePasswordModal 
            customerId={customerId} 
            dialogVisibility={showUpdatePassword}
            hideDialog={() => setShowUpdatePassword(false)}
        /> 
        <Dialog 
            draggable={false} modal
            visible={dialogVisibility} 
            breakpoints={{'1900px': '60vw', '640px': '100vw'}}
            header="Les appareils connectés" 
            className="p-fluid" onHide={hideDialog}
        >
            {(selectedDevices && selectedDevices.length > 0) && 
                <DialogConfirmation 
                headerTitle={"Confirmer"}
                message={`êtes-vous sûr de vouloir déconnecter ${selectedDevices.length} appareils`}
                visibilityDialog={dialogConfirmationMultiple}
                onHideDialog={hideDialogConfirmationMultiple}
                onConfirmClicked={async () => await onDisconnectMultipleDeviceConfirmed(customerId)}
                loading={loadingDisconnect}
                ButtonShowDialog={() => 
                    <div onClick={()=> openDialogConfirmationMultiple()} style={{cursor:'pointer'}}>
                    <p className='mb-2 filterBar font-normal text-md'>
                        {`déconnecter ${selectedDevices.length} appareils`}
                    </p>
                    </div>
                }
            />            

            }
            <DataTable
                value={connectedDevices}  
                showGridlines 
                tableStyle={{ minWidth: '50rem' }}
                onSelectionChange={onSelectionChange}
                selectionMode={'multiple'} 
                selection={selectedDevices} 
            >
            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
            <Column field="deviceModel" header="Nom d'appareil" body={actionBodyTemplate} />
            </DataTable>         
        </Dialog>

        </>
        
    )

    
}

export default ConnectedDevices