import Api from "../utils/Api";

export class OfferService {

    async getOffers (){
        let response  = {}
        try {
          const res = await Api.get('offer/get');
          response.data = res.data
          return response
        } catch (error) {
          console.log("error : ",error)
          response.error = error
          return response
        }
    }

    async createOffer (data){
        let response  = {}
        try {
          const res = await Api.post('offer/create',data);
          response.data = res.data
          return response
        } catch (error) {
          console.log("error : ",error)
          response.error = error
          return response
        }
    }

    async updateOffer (offerId, data){
        let response  = {}
        try {
          const res = await Api.put(`offer/update/${offerId}`,data);
          response.data = res.data
          return response
        } catch (error) {
          console.log("error : ",error)
          response.error = error
          return response
        }
    }

    async deleteOffer (offerId){
      let response  = {}
      try {
        const res = await Api.delete(`offer/delete/${offerId}`);
        response.data = res.data
        return response
      } catch (error) {
        console.log("error : ",error)
        response.error = error
        return response
      }
    }

    async reorderOffers (offers){
      if(offers){
        let totalOffers = offers.length
        let offersPriority = []
        //reorder categories
        for(let i = 0; i < offers.length; i++){
          offersPriority.push({_id: offers[i]._id, priority: totalOffers})
          totalOffers -= 1
        }
        let response = {}
        try {
          await Api.put(`offer/reorder`,{offersPriority})
          response.data = "reorder successfully"
          return response
        } catch (error) {
          response.error = error
          return response
        }
      }
    }

  }
