import React from 'react'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { useState } from 'react'
import { classNames } from 'primereact/utils';
import { Formik } from 'formik';
import * as Yup from 'yup'
import AddButton from '../buttons/AddButton'
import RemoveButton from '../buttons/RemoveButton'


const Phones = ({phones, addEstablishmentInfo, deleteEstablishmentInfo}) => {

    const initialValues = {
        phoneValue: ''
    }

    const validationSchema = Yup.object().shape({
        phoneValue: Yup.string().min(10).max(10).required('champ obligatoire')
    })

    const onSubmit=async(values,actions)=>{
        // console.log(values.phoneValue)
        await addEstablishmentInfo({phoneNumber: values.phoneValue})
        actions.resetForm()
        actions.setSubmitting(false)
    }


    const onRemoveClicked = async (phone) => {
        await deleteEstablishmentInfo({phoneNumber: phone})
    }
    

  return (
    <div className="col-12 lg:col-6">
        <div className="card p-fluid">
        <p className='mb-4 font-semibold'>numéro de télephone de support</p>
        {
        phones && 
            <div className='w-full'>
            {
                phones.map((phone,index) => (
                    <div key={index} className='flex mb-1 w-full align-items-center justify-content-between'>
                        <div className='flex align-items-center'>
                            <i className='pi pi-phone'></i>
                            <div className='ml-2'> <p>{phone}</p> </div>
                        </div>
                        <RemoveButton onRemoveClicked={onRemoveClicked} phone={phone} />
                    </div>
                ))
            }
            </div>
        }   


        <Formik 
            initialValues={initialValues} 
            validationSchema={validationSchema} 
            onSubmit={onSubmit}>
            {({ handleChange,
                handleBlur, 
                handleSubmit,
                isSubmitting, 
                values, 
                errors, 
                touched 
            })=>{
               
                const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
                const getFormErrorMessage = (name) => {
                    return isFormFieldValid(name) && <small className="p-error">{errors[name]}</small>;
                };

               return (
               <div className='mt-2'>
                <div className='flex align-items-center'>
                    <InputText 
                    placeholder="numéro de support" 
                    onChange={handleChange('phoneValue')}
                    onBlur={handleBlur('phoneValue')}
                    className={classNames({ 'p-invalid':  isFormFieldValid('phoneValue')})}
                    />
                    <Button
                        icon="pi pi-plus" 
                        className="ml-2"
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                        />
                </div>
                {getFormErrorMessage('phoneValue')}
                    {/* <AddButton onAddClicked={onAddClicked} setPhoneValue={setPhoneValue} /> */}
                </div>
                )}}
        </Formik>


        </div>
    </div>
  )
}

export default Phones