import { Field, Form, Formik, FormikProvider, useFormik } from "formik";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import React, { useRef, useState } from "react";
import { AdminService } from "../../../service/AdminService";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { FrenchModulesNames, ModulesNames } from "../../../utils/Consts";

function RolesForm({ userInfos }) {
  const { updateAdmin } = new AdminService();
  const { _id, roles } = userInfos;
  const toast = useRef(null);

  const {
    admins,
    categories,
    brands,
    products,
    offers,
    gifts,
    points,
    redeemGifts,
    famillies,
    orders,
    customers,
    deliveryMen,
    types,
    proposals,
    notifications,
    setting,
    statistics,
  } = roles;

  const initialValues = {
    admins: {
      create: admins.create,
      read: admins.read,
      update: admins.update,
      delete: admins.delete,
      updatePassword: admins.updatePassword,
    },
    categories: {
      create: categories.create,
      read: categories.read,
      update: categories.update,
      delete: categories.delete,
    },
    brands: {
      create: brands.create,
      read: brands.read,
      update: brands.update,
      delete: brands.delete,
    },
    products: {
      create: products.create,
      read: products.read,
      update: products.update,
      delete: products.delete,
    },
    offers: {
      create: offers.create,
      read: offers.read,
      update: offers.update,
      delete: offers.delete,
    },
    gifts: {
      create: gifts.create,
      read: gifts.read,
      update: gifts.update,
      delete: gifts.delete,
    },
    points: { update: points.update },
    redeemGifts: {
      read: redeemGifts.read,
      markAsDelivered: redeemGifts.markAsDelivered,
    },
    orders: {
      read: orders.read,
      markAsDelivered: orders.markAsDelivered,
      markAsShipped: orders.markAsShipped,
      markAsCancelled: orders.markAsCancelled,
      markAsReturn: orders.markAsReturn,
    },
    customers: {
      create: customers.create,
      read: customers.read,
      update: customers.update,
      delete: customers.delete,
      updatePassword: customers.updatePassword,
    },
    deliveryMen: {
      create: deliveryMen.create,
      read: deliveryMen.read,
      update: deliveryMen.update,
      delete: deliveryMen.delete,
      updatePassword: deliveryMen.updatePassword,
    },
    types: {
      create: types.create,
      read: types.read,
      update: types.update,
      delete: types.delete,
    },
    famillies: {
      create: famillies.create,
      read: famillies.read,
      update: famillies.update,
      delete: famillies.delete,
    },
    proposals: { read: proposals.read },
    notifications: { create: notifications.create },
    setting: { update: setting.update },
    statistics: { read: statistics.read },
  };

  const [parentChecked, setParentChecked] = useState({});
  const navigate = useNavigate();

  const _updateAdmin = async (data) => {
    try {
      const response = await updateAdmin(_id, { roles: data });
      if (response.data) {
        navigate("/admins");
      } else {
        toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "ressayez plus tard",
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onSubmit = async (values, actions) => {
    await _updateAdmin(values);
  };

  const toggleChildren = (role, checked) => {
    const updatedValues = { ...initialValues[role] };
    for (const permission in updatedValues) {
      updatedValues[permission] = checked;
    }
    setParentChecked({ ...parentChecked, [role]: checked });
    return updatedValues;
  };

  const handleParentCheckboxChange =
    (role, values, setFieldValue) => (event) => {
      const { checked } = event.target;
      const updatedValues = toggleChildren(role, checked);
      setFieldValue(role, updatedValues);
    };

  const handleChildCheckboxChange =
    (role, permission, values, setFieldValue) => (event) => {
      const { checked } = event.target;
      const updatedValues = { ...values[role], [permission]: checked };
      setFieldValue(role, updatedValues);
    };

  const { values, handleSubmit, setFieldValue, isSubmitting } = useFormik({
    initialValues,
    onSubmit,
  });

  return (
    <>
      <Toast ref={toast} />
      {Object.entries(initialValues).map(([role, permissions]) => (
        <div className="p-2 col-12 md:col-6 xl:col-3" key={role}>
          <div className="card">
            <div className="flex align-items-center">
              <Checkbox
                checked={Object.values(values[role]).every((v) => v)}
                onChange={handleParentCheckboxChange(
                  role,
                  values,
                  setFieldValue
                )}
              ></Checkbox>
              <label className="ml-3 font-bold text-900">
                {FrenchModulesNames[role]}
              </label>
            </div>
            {/* <div className="flex flex-column ">
              {Object.entries(permissions).map(([permission, value]) => (
                <div key={permission} className="flex align-items-center mb-1">
                  <Checkbox
                    onChange={handleChildCheckboxChange(
                      role,
                      permission,
                      values,
                      setFieldValue
                    )}
                    name={`${role}.${permission}`}
                    checked={values[role][permission]}
                  ></Checkbox>
                  <label className="ml-3">{permission}</label>
                </div>
              ))}
            </div> */}
          </div>
        </div>
      ))}
      <div className="col-12 flex align-items-center justify-content-end">
        <Button
          onClick={handleSubmit}
          label={isSubmitting ? "loading" : "modifier"}
          disabled={isSubmitting}
          loading={isSubmitting}
        />
      </div>
    </>
  );
}

export default RolesForm;
