import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { CategoryService } from '../service/CategoryService';
import { InputText } from 'primereact/inputtext';
import OptionsMenu from '../components/OptionsMenu';
import { useNavigate } from 'react-router-dom';
import ImageTemplate from '../components/orders/ImageTemplate';
import NoData from '../components/NoData';
import SingleDelete from '../components/SingleDelete';
import { lazyParamsProduct, totalProductsAtom } from '../atoms/LazyParamsProduct';
import { useRecoilState } from 'recoil';
import { TreeSelect } from 'primereact/treeselect';
//SERVICES
import { BrandsService } from '../service/BrandsService';
import { ProductService } from '../service/ProductService';
import UpdateMultipleProducts from '../components/products/Edit_product/UpdateMultipleProducts';

const Products = () => {

    const productsService = new ProductService();
    const categoryService = new CategoryService()
    const brandService = new BrandsService()

    const STATUS = [
        {label: 'tout', value: null},
        {label: 'actif', value: true},
        {label: 'inactif', value: false},
    ]
    const [loading, setLoading] = useState(false);
    const [loadingCategories, setLoadingCategories] = useState(false)
    const [loadingBrands, setLoadingBrands] = useState(false);
    const [toggleMenu, setToggleMenu] = useState(null); // toggle menu state
    const [lazyParams, setLazyParams] = useRecoilState(lazyParamsProduct);
    const [totalRecords, setTotalRecords] = useRecoilState(totalProductsAtom);
    const [products, setProducts] = useState(null);
    const [categories, setCategories]= useState(null);
    const [brands, setBrands] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [visibiltyModal, setVisibilityModal]=useState(false)

    const navigate = useNavigate();
    const dt = useRef(null);
    
    // get products
    useEffect(() => {
        lazyLoadData();
    },[lazyParams])


    //get categories
    useEffect(() => {
        getCategories()
        getBrands()
    },[])


    //load products
    async function lazyLoadData (){
        console.log("lazyLoadData")
        const queryParams = generateQueryParams()
        setLoading(true);
        const response = await productsService.getProducts(queryParams);
        if(response.data){
            setProducts(response.data.products)
            setTotalRecords(response.data.totalDocuments)
        } else {
            console.log(response.error)
        }
        setLoading(false)
    }
  
    //get categories
    async function getCategories(){
        setLoadingCategories(true)
        const  response = await categoryService.getCategories()
        const tree = categoryService.buildTree(response.data)
        let treeCategories = [{
            key: null,
            label: 'Toutes les catégories',
            children: []
        }]
        treeCategories = [...treeCategories, ...tree]
        setLoadingCategories(false)
        setCategories(treeCategories)

    }

    async function getBrands(){
        setLoadingBrands(true)
        const queryParams = `active=true`
        const  response = await brandService.getAllBrands(queryParams)
        let _brands = [{
            brandName: 'Toutes les marques', _id: null
        }]
        _brands = [..._brands, ...response.data]
        setLoadingBrands(false)
        setBrands(_brands)
    }

    const deleteProduct = async (productId) => {
        const response = await productsService.removeProduct(productId)
        if(response.data){
            lazyLoadData()
        } else {
            console.log(response.error)
        }
    }


    function generateQueryParams (){
        const {
            first, rows, page, 
            filters : { active, selectedCategory, selectedBrand, reference, nameProduct, codeArticle }, 
            sortfield, sortorder 
        } = lazyParams
        const _sortfield = sortfield === null ? '_id' : sortfield
        let queryParameters = `limit=${rows}&totalSkip=${first}&currentPage=${page}&totalDocuments=${totalRecords}&sortfield=${_sortfield}&sortorder=${sortorder}`
        if(active !== null) queryParameters += `&active=${active}`
        if(selectedCategory) queryParameters += `&mainCategory=${selectedCategory}`
        if(selectedBrand) queryParameters += `&brand=${selectedBrand}`
        if(reference) queryParameters += `&reference=${reference}`
        if(nameProduct) queryParameters += `&nameProduct=${nameProduct}`
        if(codeArticle) queryParameters += `&codeArticle=${codeArticle}`

        return queryParameters
    }


    const statusBodyTemplate = (rowData) => {
        const CustomBadge=(bg,status)=>{
            return(
                <div style={{backgroundColor:bg,borderRadius:10}} className='px-2 w-max'>
                    <p className='text-sm'>{status}</p>
                </div>
            )
        }
        return (
            <>
            {
              rowData.active
              ? <>{CustomBadge('#A1D8C1','Actif')}</>
              :  <>{CustomBadge('#d8a1a1','Inactif')}</>
            }
            </>
          )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <OptionsMenu 
                rowData={rowData} 
                setToggleMenu={setToggleMenu} 
                toggleMenu={toggleMenu}>
            {
            rowData._id === toggleMenu 
              && <div className='pb-2 pt-2 options-menu'>
                  <div 
                      className='align-items-center flex p-2 pl-3 pr-6 menu-child'
                       onClick={()=>navigate(`/products/${rowData?._id}`,{state:rowData})}>
                          <i className='pi pi-eye'></i>
                          <span className='uppercase ml-2'>aperçu</span>
                  </div>
                  <div 
                      className='align-items-center flex p-2 pl-3 pr-6 menu-child'
                      onClick={()=>navigate(`/products/edit/${rowData?._id}`,{state:{rowData}})}
                      >
                          <i className='pi pi-pencil'></i>
                          <span className='uppercase ml-2'>modifier</span>
                </div>
                <SingleDelete id={rowData?._id} name={rowData?.nameProduct} deleteFunction={()=>deleteProduct(rowData?._id)} setToggleMenu={setToggleMenu}/>
              </div>
            }
          </OptionsMenu>
        );
    }

    const quantityTemplate = (rowData) => {
            return(
                <>
                {
                    rowData?.quantityStock <= 20
                    ? 
                    <div className='flex align-items-center'>
                        <i className='pi pi-exclamation-circle' style={{color:'#f00'}}></i>
                        <div><p className='ml-1' style={{color:'#f00'}}>{rowData?.quantityStock}</p></div>
                    </div>
                    : <div> 
                        <p>{rowData?.quantityStock}</p>
                    </div>
                }
                </>
            )
        
    }

    const priceTemplate=(rowData)=>{
        return <p>{`${rowData.priceProduct} dh`}</p>
    }

    const categoryTemplate = (rowData) => {
        return <p>{rowData?.mainCategory?.nameCategory}</p>
    }

    const brandTemplate = (rowData) => {
        return <p>{rowData?.brand?.brandName}</p>
    }

    const generateArrayTotalPages = () => {
        const totalPages = Math.ceil(totalRecords / lazyParams.rows);
        const numbers = []
        for(let i=1; i<=totalPages; i++){
            numbers.push({ page: i })
        }
        return numbers;
      }

    const paginatorRight = () => {
        return (
        <div className="flex align-items-center">
            <Dropdown
                filter
                value={lazyParams.page} 
                onChange={onCustomPageChange}
                placeholder={"numéro de page"}
                options={generateArrayTotalPages()}
                virtualScrollerOptions={{ itemSize: 38 }}
                // className="w-full md:w-14rem"
                optionLabel="page" 
                optionValue="page"

            />
       
        </div>
        )
    }

    //------------------------- ON CHANGE EVETS ----------------------------

    const onPage = (event) => {
        setLazyParams({
            ...lazyParams,
            first: event.first,
            rows: event.rows,
            page: event.page + 1
        })
    }

    const onCustomPageChange = (event) => {
        const pageNumber = event.value
        console.log(pageNumber)
        setLazyParams({
            ...lazyParams,
            page: pageNumber,
            first: (pageNumber - 1) * lazyParams.rows,

        })
    }

    const onSort = (event) => {
        console.log("event sort: ",event)
        let sortorder;
        if(lazyParams.sortfield == null || lazyParams.sortfield != event.sortField){
          sortorder = 1
        } else {
          sortorder = lazyParams.sortorder * -1
        }
        setLazyParams({
            ...lazyParams,
            first: 0,
            rows: 10,
            page: 1,
            sortfield: event.sortField,
            sortorder: sortorder,
        });
    };

    const onChangeCategory = (event) => {
        setLazyParams({
            first: 0,
            rows: 10,
            page: 1,
            filters : {
                ...lazyParams.filters,
                selectedCategory: event.value,
            },
            sortfield: null,
            sortorder: -1
        })
    }

    const onSelectedBrandChanged = (event) => {
        setLazyParams({
            first: 0,
            rows: 10,
            page: 1,
            filters : {
                ...lazyParams.filters,
                selectedBrand: event.value,
            },
            sortfield: null,
            sortorder: -1
        })
    }
    
    const onCodeArticleChanged = (event) => {
        setLazyParams({
            first: 0,
            rows: 10,
            page: 1,
            filters : {
                ...lazyParams.filters,
                codeArticle: event.target.value,
            },
            sortfield: null,
            sortorder: -1
        })
    }

  
    const onNameProductChanged = (event) => {
        setLazyParams({
            first: 0,
            rows: 10,
            page: 1,
            filters : {
                ...lazyParams.filters,
                nameProduct: event.target.value,
            },
            sortfield: null,
            sortorder: -1
        })
    }

    const onChangeStatus = (event) => {
        setLazyParams({
            first: 0,
            rows: 10,
            page: 1,
            filters : {
                ...lazyParams.filters,
                active: event.value,
            },
            sortfield: null,
            sortorder: -1
        })
    }



    //--------------FILTERS-----------------------------
    const codeArticleFilter=()=>{
        return(
          <InputText 
          className="mr-2 p-inputtext-sm" 
          placeholder='code article' 
          onChange={onCodeArticleChanged} 
          value={lazyParams.filters.codeArticle}/>
        )
    }

    const categoryFilter = () => {
        return <TreeSelect
            value={lazyParams.filters.selectedCategory} 
            onChange={onChangeCategory} 
            options={categories} 
            disabled={loadingCategories}
            placeholder={loadingCategories ? "loading" : "selectionner catégorie"}
            className="mr-2 p-column-filter p-inputtext-sm"
            filter
        />
    }

    const brandFilter = () => {
        return (
          <>
          <Dropdown
            filter
            value={lazyParams.filters.selectedBrand}
            placeholder="Toutes les marques"
            options={brands}
            optionLabel="brandName"
            optionValue="_id"
            onChange={onSelectedBrandChanged}
            className="mr-2 p-column-filter p-inputtext-sm"
          />
          </>
        );
    };

    const statusFitler = () => {
        return <Dropdown 
                  value={lazyParams.filters.active}
                  placeholder='Statut'
                  options={STATUS} 
                  onChange={onChangeStatus}
                  className="p-column-filter p-inputtext-sm" />; 
    }

    const nameProductFilter=()=>{
        return(
          <InputText 
          className="mr-2 p-inputtext-sm" 
          placeholder='nom de produit' 
          onChange={onNameProductChanged} 
          value={lazyParams.filters.nameProduct}/>
        )
    }

  return (
    <div className="relative grid crud-demo">
        <UpdateMultipleProducts 
            selectedProducts={selectedProducts} 
            visibiltyModal={visibiltyModal}
            setVisibilityModal={setVisibilityModal}
            setLoading={setLoading}
            lazyLoadData={lazyLoadData}
            
        />
            <div className="col-12">
                <div>
                    <div className='mb-4 flex alignItems-center justify-content-between'>
                        <div className='flex align-items-center justify-content-center'>
                            <p className='text-2xl font-semibold'>Produits</p>
                        </div>
                        <Button
                            icon='pi pi-plus' 
                            onClick={()=>navigate('/products/add',{replace:true})}
                            label='nouveau produit'
                        />
                                     
                        {selectedProducts.length > 0 &&
                        <div className='flex'>
                        <div onClick={()=> setVisibilityModal(true)} style={{cursor:'pointer'}}>
                            <p className='ml-3 filterBar font-normal text-md'>
                                {`Modifier ${selectedProducts.length} produits`}
                            </p>
                        </div>
                        <div onClick={()=> setSelectedProducts([])} style={{cursor:'pointer'}}>
                            <p className='ml-3 filterBar font-normal text-md'>
                                {`désélectionner les produits`}
                            </p>
                        </div>
                        </div>
                        
                        }
                    </div>

                    <div className='card'>
                    <div className='overflow-x-auto mb-4 flex align-items-center justify-content-start'>
                        {codeArticleFilter()}
                        {categoryFilter()}
                        {brandFilter()}
                        {nameProductFilter()}
                        {statusFitler()}
                        
                    </div>

                    
                <DataTable
                    paginator 
                    paginatorRight={paginatorRight}
                    lazy
                    first={lazyParams.first}
                    loading={loading}
                    rows={lazyParams.rows} 
                    totalRecords={totalRecords} 
                    onPage={onPage} 
                    onSort={onSort}
                    filterDisplay="row"
                    stripedRows 
                    ref={dt} 
                    rowHover
                    rowsPerPageOptions={[10, 25, 50]}
                    responsiveLayout="scroll" 
                    value={products}
                    size='small'
                    dataKey="_id" 
                    selectionMode={'multiple'} 
                    selection={selectedProducts} 
                    onSelectionChange={(e) => setSelectedProducts(e.value)} 
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="{first} à {last} de {totalRecords}"
                    emptyMessage={<NoData label='Aucun produit disponible' />}>
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column sortable field="codeArticle" header="code article"></Column>
                        <Column sortable field="nameProduct" header="nom"></Column>
                        <Column field="mainCategory" header="categorie" body={categoryTemplate} ></Column>
                        <Column field="brand" header="marque" body={brandTemplate} ></Column>
                        <Column field="photos" header="image" body={(rowData)=><ImageTemplate photos={rowData.photos}/>}></Column>
                        <Column field="priceProduct" header="prix" body={priceTemplate}></Column>
                        <Column sortable field="quantityStock" header="quantité" body={quantityTemplate}></Column>
                        <Column field="active" header="status" body={statusBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                    </div>

                </div>
            </div>
    </div>
  )
}


export default React.memo(Products);

