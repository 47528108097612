import Api from "../utils/Api";


export class FamilleService{
 
    // GET FAMILY TYPES
    async getFamilyTypes (){
        let response = {}
        try {
          const res = await Api.get(`family/admin`)
          response.data = res.data
          return response
        } catch (error) {
          response.error = error
          return response
        }
    }

    // CREATE FAMILY TYPES
    async createFamilyType (data){
        let response = {}
        console.log(data)
        try {
          const res = await Api.post(`family/create`,data)
          response.data = res.data
          return response
        } catch (error) {
          response.error = error
          return response
        }
    }

    // UPDATE FAMILY TYPES
    async updateFamilyType (id,data){
        let response = {}
        try {
          const res = await Api.put(`family/update/${id}`,data)
          response.data = res.data
          return response
        } catch (error) {
          response.error = error
          return response
        }
    }

    // DELETE FAMILY TYPES
    async deleteFamilyTypes (id){
        let response = {}
        try {
          const res = await Api.delete(`family/delete/${id}`)
          response.data = res.data
          return response
        } catch (error) {
          response.error = error
          return response
        }
    }


}