import Api from '../utils/Api'

export class CustomersService {

    async createCustomer(data){
        let response = {}
        try {
            const createdCustomer = await Api.post(`customer/signup`,data)
            response.data = createdCustomer.data
            return response
        } catch (error) {
            response.error = error.response.data.message
            return response
        }

    }

    async updateCustomer (_id,data){
        let response = {}
        try {
            const updatedCustomer = await Api.put(`customer/update/${_id}`,data)
            response.data = updatedCustomer.data
            return response
        } catch (error) {
            response.error = error.response.data.message
            return response
        }

    }

    async getCustomers (lazyParams, totalRecords){
        const {
            first, rows, page, sortfield, sortorder,
            filters : {
                customerType, active, city, nameEntreprise, phoneNumber,customerReference, ISE
            } 
        } = lazyParams
        const _sortfield = sortfield === null ? '_id' : sortfield
        let parameters = `totalSkip=${first}&currentPage=${page}&totalDocuments=${totalRecords}&sortfield=${_sortfield}&sortorder=${sortorder}`
        if(customerType) parameters += `&customerType=${customerType}`
        if(active != null) parameters += `&active=${active}`
        if(city) parameters += `&city=${city}`
        if(nameEntreprise) parameters += `&nameEntreprise=${nameEntreprise}`
        if(phoneNumber) parameters += `&phoneNumber=${phoneNumber}`
        if(ISE) parameters += `&ISE=${ISE}`
        if(customerReference) parameters += `&customerReference=${customerReference}`
        console.log(parameters)
        
        let response = {}
        try {
            const costumers = await Api.get(`customer/getcustomers?${parameters}`)
            response.data = costumers.data
            return response
        } catch (error) {
            response.error = error
            return response
        }
    }

    async deleteCustomer (_id){
        let response = {}
        try {
            const deletedCustomer = await Api.delete(`customer/remove/${_id}`)
            response.data = deletedCustomer.data
            return response
        } catch (error) {
            response.error = error
            return response
        }
    }

    async updatePassword(_id, newPassword) {
        let response = {}
        try {
            const customers = await Api.put(`customer/update/password/withoutcheck/${_id}`,{ newpassword: newPassword })
            response.data = customers.data
            return response
        } catch (error) {
            response.error = error
            return response
        }
    }

    async getCustomerDetail(customerId) {
        let response = {}
        try {
            const customer = await Api.get(`customer/customerinfo/${customerId}`)
            response.data = customer.data
            return response
        } catch (error) {
            response.error = error
            return response
        }
    }

    async disconnectDevice (customerId, deviceId){
        let response = {}
        try {
            const res = await Api.post(`customer/disconnectdevice?customerid=${customerId}&deviceid=${deviceId}`)
            response.data = res.data
            return response
        } catch (error) {
            response.error = error
            return response
        }
    }

}