import React,{ useState, useEffect } from 'react'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';


const SingleDelete=({name, id, deleteFunction,setToggleMenu, toggleMenu})=> {

    const [deleteDialog, setDeleteDialog] = useState(false);
    const confirmDelete = () => {
        setDeleteDialog(true)
    }
    const hideDeleteDialog = () => {
        setToggleMenu(!toggleMenu)
        setDeleteDialog(false) 
    }

    const handleDelete = (_id) => {
        deleteFunction()
        hideDeleteDialog()
        setToggleMenu(!toggleMenu)
    }



    const deleteDialogFooter = (
        <>
            <Button label="non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label="oui" icon="pi pi-trash" className="p-button-text p-button-danger" 
                    onClick={()=> { handleDelete(id)}}/>
        </>
    );


    return (
        <>

        <div 
            className='align-items-center flex p-2 pl-3 pr-6 menu-child'
            onClick={() => confirmDelete()}
            >
                <i style={{color:'#f00'}} className='pi pi-trash'></i>
                <span className='uppercase ml-2' style={{color:'#f00'}}>supprimmer</span>
        </div>

        <Dialog 
        visible={deleteDialog} 
        draggable={false}
        style={{ width: '450px' }} 
        header="Confirmer" 
        modal 
        footer={deleteDialogFooter} 
        onHide={hideDeleteDialog}>
            <div className="flex align-items-center"> 
                <i className="pi pi-exclamation-triangle" style={{ fontSize: '2rem' }} />
                <div className="ml-3 flex flex-column align-items-start justify-content-center">
                    <span className='mt-2'>Voulez-vous vraiment supprimer <b>{name}</b>?</span>
                    <span>{'cette action est irréversible'}</span>
            </div>
            </div>
        </Dialog>
        </>
    )
}

export default SingleDelete