import React, { useState } from "react"
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

const DialogConfirmation = ({
    headerTitle, 
    message, 
    onConfirmClicked,
    ButtonShowDialog,
    visibilityDialog,
    onHideDialog,
    loading
}) => {

    const updateDialogFooter = () => {
      return (
        <>
          <Button
            label="non"
            icon="pi pi-times"
            className="p-button-text"
            onClick={() => onHideDialog()}
          />
          <Button
            label={loading ? "chargement" : "oui"}
            disabled={loading}
            icon="pi pi-check"
            className="p-button-text p-button-success"
            onClick={() => onConfirmClicked()}
          />
        </>
      );
    };

    return (
      <>
        <ButtonShowDialog />
        <Dialog
          visible={visibilityDialog}
          style={{ width: "450px" }}
          header={headerTitle}
          modal
          footer={updateDialogFooter}
          onHide={() => onHideDialog()}
        >
          <div className="flex align-items-center justify-content-center">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            <div className="flex flex-column align-items-start justify-content-center">
              <span className="mt-2">
                {message}
              </span>
            </div>
          </div>
        </Dialog>
      </>
    );
};

export default DialogConfirmation