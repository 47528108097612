import Api from "../utils/Api";

export class OptionsService {

    async addOption(options){
        let response = {}
        try {
            const createOptions = await Api.post(`option/create`,options)
            response.data  = createOptions.data
            return response
        } catch (error) {
            response.error = error
            return response
        }
    }

    async getOptions(productId){
        let response = {}
        try {
            const getOptions = await Api.get(`option?productId=${productId}`,)
            response.data  = getOptions.data
            return response
        } catch (error) {
            response.error = error
            return response
        }
    }

    async deleteOption(optionId){
        let response = {}
        try {
            const deleteOption = await Api.delete(`option/delete/${optionId}`,)
            response.data  = deleteOption.data
            return response
        } catch (error) {
            response.error = error
            return response
        }
    }

    async updateOption(optionId,option){
        let response = {}
        try {
            const updateOption = await Api.put(`option/update/${optionId}`,option)
            response.data  = updateOption.data
            return response
        } catch (error) {
            response.error = error
            return response
        }
    }

        
}