import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { GiftService } from '../../service/GiftService';

import CustomInput from '../CustomInput';

const PointsRules = () => {
    
    const giftService = new GiftService()
    const [dialogVisibility, setDialogVisibility] = useState(false);
    const [pointsRules, setPointsRules] = useState({
        _id: '', amount: '', totalPoints: ''
    })
    const hideDialog = () => setDialogVisibility(false)
    const openNew = () => setDialogVisibility(true)

    const initialValues = {
        _id: pointsRules._id,
        amount: pointsRules.amount,
        totalPoints: pointsRules.totalPoints,
    }

    const validationSchema = Yup.object().shape({
        amount: Yup.number().min(1).required('montant obligatoire'),
        totalPoints: Yup.number().min(1).required("total de points obligatoire"),
    })

    useEffect(() => {
        if(dialogVisibility){
            getData()
        }

    },[dialogVisibility])

    async function getData(){
        const response = await giftService.getPointsRules()
        if(response.data){
            setPointsRules(response.data)
        } else {
            console.log(response.error)
        }
    }


    const onSubmit = async (values,actions) => {
        const { _id, amount, totalPoints } = values
        const data = {
            amount, totalPoints
        }
        console.log(data)
        const response = await giftService.updatePointsRules(_id, data)
        if(response.data){
            hideDialog()
        } else {
            console.log(response.error)
        }
    }
    
    
    return(
        <>
        <Button 
            label="Gérer les points" 
            className="mr-2" 
            onClick={openNew} 
        />
        
        {/* MODAL */}
        <Dialog draggable={false} visible={dialogVisibility} breakpoints={{'1900px': '60vw', '640px': '100vw'}}
            header="Gérer les points" modal 
            className="p-fluid" onHide={hideDialog}>
            
            <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ handleChange,handleBlur, handleSubmit,isSubmitting, values, errors, touched, setFieldValue })=>{
               
                const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
                const getFormErrorMessage = (name) => {
                    return isFormFieldValid(name) && <small className="p-error">{errors[name]}</small>;
                };
                return(
                <>

                {/* AMOUNT */}
                <CustomInput
                    label='montant'
                    placeholder='montant'
                    handleChange={handleChange}
                    value={values.amount}
                    name='amount'
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                    keyfilter='pnum'
                />

                {/* TOTAL POINTS */}
                <CustomInput
                    label='total de points'
                    placeholder='total de points'
                    handleChange={handleChange}
                    value={values.totalPoints}
                    name='totalPoints'
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                    keyfilter='pnum'
                />

              
            <div className='mt-4 flex align-items-center justify-content-end'>
                <Button label="annuler" className='w-auto p-button-secondary p-button-sm p-button-outlined mr-2' icon="pi pi-times" 
                onClick={hideDialog}/>
                <Button onClick={handleSubmit} 
                label={isSubmitting?'loading':'sauvegarder'}
                disabled={isSubmitting}
                loading={isSubmitting}
                className='w-auto p-button-sm p-button-success' 
                icon="pi pi-check"  type='submit' />
            </div>
            </>
                )
            }}
            </Formik>
            
            </Dialog>
        </>
    )
}

export default PointsRules









