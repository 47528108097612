import React from 'react'
import { useNavigate, Link } from 'react-router-dom'

const BreadCrump = ({breadCrumpItems}) => {
    let { href, parentName, currentPage } = breadCrumpItems
  return (
    <div className='flex align-items-center mb-4'>
        <div className='mr-2'>
            <Link to={`/${href}`}>
                <p>{parentName}</p>
            </Link>
        </div>
        <i className='pi pi-chevron-right'></i>
        <div className='ml-2'>
            <p>{currentPage}</p>
        </div>
    </div>
  )
}

export default BreadCrump