export const UnauthorizedErrorTypes = {
  BLACKLIST_TOKEN: "BLACKLIST_TOKEN",
  TOKEN_EXPIRD: "TOKEN_EXPIRD",
  TOKEN_INVALID: "TOKEN_INVALID",
  UNAUTHORIZED_ROLE: "UNAUTHORIZED_ROLE",
  USER_INACTIF: "USER_INACTIF",
};

// "roles": {
//   "admins": {
//       "create": true,
//       "read": true,
//       "update": true,
//       "delete": true,
//       "updatePassword": true
//   },
//   "categories": {
//       "create": true,
//       "read": true,
//       "update": true,
//       "delete": true
//   },
//   "brands": {
//       "create": true,
//       "read": true,
//       "update": true,
//       "delete": true
//   },
//   "products": {
//       "create": true,
//       "read": true,
//       "update": true,
//       "delete": true
//   },
//   "famillies": {
//       "create": true,
//       "read": true,
//       "update": true,
//       "delete": true
//   },
//   "offers": {
//       "create": true,
//       "read": true,
//       "update": true,
//       "delete": true
//   },
//   "gifts": {
//       "create": true,
//       "read": true,
//       "update": true,
//       "delete": true
//   },
//   "points": {
//       "update": true
//   },
//   "redeemGifts": {
//       "read": true,
//       "markAsDelivered": true
//   },
//   "orders": {
//       "read": true,
//       "markAsDelivered": true,
//       "markAsShipped": true,
//       "markAsCancelled": true,
//       "markAsReturn": true
//   },
//   "customers": {
//       "create": true,
//       "read": true,
//       "update": true,
//       "delete": true,
//       "updatePassword": true
//   },
//   "deliveryMen": {
//       "create": true,
//       "read": true,
//       "update": true,
//       "delete": true,
//       "updatePassword": true
//   },
//   "types": {
//       "create": true,
//       "read": true,
//       "update": true,
//       "delete": true
//   },
//   "proposals": {
//       "read": true
//   },
//   "notifications": {
//       "create": true
//   },
//   "setting": {
//       "update": true
//   },
//   "statistics": {
//       "read": true
//   }
// }

export const ModulesNames = {
  admins: "admins",
  categories: "categories",
  brands: "brands",
  products: "products",
  offers: "offers",
  gifts: "gifts",
  points: "points",
  redeemGifts: "redeemGifts",
  famillies: "famillies",
  orders: "orders",
  expeditions: "expeditions",
  customers: "customers",
  deliveryMen: "deliveryMen",
  types: "types",
  proposals: "proposals",
  notifications: "notifications",
  setting: "setting",
  statistics: "statistics",
};

export const FrenchModulesNames = {
  admins: "les admins",
  categories: "categories",
  brands: "marques",
  products: "produits",
  offers: "les offres",
  gifts: "cadeaux",
  points: "points de fidélisation",
  redeemGifts: "cadeaux commandés",
  famillies: "les familles",
  orders: "commandes",
  customers: "clients",
  deliveryMen: "livreurs",
  types: "types de client",
  proposals: "propositions",
  notifications: "notifications",
  setting: "paramètres",
  statistics: "statistique",
};
