import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
    <div className="grid crud-demo">
        <div className="col-12">
            <div className='h-full card flex flex-column align-items-center justify-content-center'>
                <p className="text-5xl font-bold">404</p>
                <p className="text-xl font-medium">page not found</p>
                <Link to='/'>acceuil</Link>
            </div>
        </div>
    </div>
  )
}

export default PageNotFound