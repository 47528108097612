import * as Yup from 'yup'

export const validationSchemaWithoutVariants = Yup.object().shape({
    reference: Yup.string(),
    nameProduct: Yup.string().required('nom obligatoire'),
    mainCategory: Yup.string().required("catégorie obligatoire"),
    brand: Yup.string(),
    priceProduct: Yup.string().required('prix obligatoire'),
    description: Yup.string().required('description obligatoire'),
    quantityStock: Yup.number()
                    .integer('sans virgule')
                    .required('quantité obligatoire')
                    .test(
                        'Is positive?', 
                        'La quantité doit être supérieure ou égale 0 !', 
                        (value) => value >= 0
                    ),
    minOrderQuantity: Yup.number()
                    .integer('sans virgule'),
    maxOrderQuantity: Yup.number()
        .integer('sans virgule'),
    active: Yup.bool(),
    hasVariant: Yup.bool(),
})

export const validationSchemaWithVariants = Yup.object().shape({
  nameProduct: Yup.string().required('nom obligatoire'),
  description: Yup.string().required('description obligatoire'),
  mainCategory: Yup.string().required("catégorie obligatoire"),
  brand: Yup.string(),
})