import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAVh5i4HdGP2nmkS9eb8K7HAvWR7jWkYrc",
    authDomain: "cma-devtime.firebaseapp.com",
    projectId: "cma-devtime",
    storageBucket: "cma-devtime.appspot.com",
    messagingSenderId: "337000965446",
    appId: "1:337000965446:web:d8be3680f231d078537147",
    measurementId: "G-MQYH9XNLB7"
};
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const db = getFirestore(app)