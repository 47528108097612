import React from "react";

export const LoadingComponent = ({ title = "" }) => (
  <div className="card col-12 ">
    {title !== "" && <p className="font-bold mb-4">{title}</p>}
    <div className="flex align-items-center justify-content-center">
      <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
    </div>
  </div>
);
