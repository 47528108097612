import Api from "../utils/Api";

export class AdminService {
  async login(email, password) {
    let response = {};
    try {
      const data = { email, password };
      const res = await Api.post("admin/login", data);
      response.data = res.data;
      return response;
    } catch (error) {
      response.error = error;
      return response;
    }
  }

  // GET ALL ADMINS
  async getAllAdmins() {
    let response = {};
    try {
      const admins = await Api.get("admin/all");
      response.data = admins.data;
      return response;
    } catch (error) {
      console.log("error : ", error);
      response.error = error;
      return response;
    }
  }

  // GET ADMIN INFOS
  async getAdminInfos(id) {
    let response = {};
    try {
      const res = await Api.get(`admin/info/${id}`);
      response.data = res.data;
      return response;
    } catch (error) {
      response.error = "error getting admin data";
      return response;
    }
  }

  // DELETE ADMIN
  async deleteAdmin(id) {
    let response = {};
    try {
      const admins = await Api.delete(`admin/delete/${id}`);
      response.data = admins.data;
      return response;
    } catch (error) {
      console.log("error : ", error);
      response.error = error;
      return response;
    }
  }

  // CREATE SOUS ADMIN
  async createAdmin(data) {
    let response = {};
    try {
      const res = await Api.post(`admin/create`, data);
      response.data = res.data;
      return response;
    } catch (error) {
      console.log(error);
      response.error = error;
      return response;
    }
  }

  // UPDATE ADMIN
  async updateAdmin(_id, data) {
    let response = {};
    try {
      const res = await Api.put(`admin/update/${_id}`, data);
      response.data = res.data;
      return response;
    } catch (error) {
      console.log("error : ", error.message);
      response.error = error;
      return response;
    }
  }

  // UPDATE ADMIN PASSWORD
  async updatePassword(_id, newPassword) {
    let response = {};
    try {
      const res = await Api.put(`admin/update/password/${_id}`, {
        newPassword,
      });
      response.data = res.data;
      return response;
    } catch (error) {
      console.log("error : ", error.message);
      response.error = error;
      return response;
    }
  }

  async fetchAdminData() {
    let adminData;
    if (typeof window !== "undefined") {
      adminData = await JSON.parse(window.localStorage.getItem("userInfo"));
    }
    return adminData;
  }
}
