import React, { useState, useEffect, useRef, startTransition } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { OfferService } from '../service/OfferService';
import { InputSwitch } from 'primereact/inputswitch';
import AddOffer from '../components/offers/AddOffer';
import EditOffer from '../components/offers/EditOffer';

const Offers = () => {

    const offerSerive = new OfferService();
    const [offers, setOffers] = useState(null);
    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const dt = useRef(null);

    useEffect(() => {
        getData()
    }, []);

    async function getData(){
        setLoading(true)
        const response = await offerSerive.getOffers()
        if(response.data){
            setOffers(response.data)
        } else {
            console.log(response.error);
        }
        setLoading(false)

    }


    const createOffer = async (data) => {
        const response = await offerSerive.createOffer(data);
        if(response.data){
            getData()
        } else {
            console.log(response.error);
        }
    }

    const updateOffer = async (id,data) => {
        const response = await offerSerive.updateOffer(id,data)
        if(response.data){
            getData()
        } else {
            console.log(response.error);
        }
    }

    const deleteOffer = async (id) => {
        const response = await offerSerive.deleteOffer(id)
        if(response.data){
            getData()
        } else {
            console.log(response.error);
        }

    }



    const OnStatusChanged = async (offerId, value) => {
        await updateOffer(offerId, { active: value })
    }

    const categoryBodyTemplate = (rowData) => {
        let title = rowData.category ? rowData.category.nameCategory : "non défini"
        return <p>{title}</p>
    }

    const brandBodyTemplate = (rowData) => {
        let title = rowData.brand ? rowData.brand.brandName : "non défini"
        return <p>{title}</p>
    }

    const productBodyTemplate = (rowData) => {
        let title = rowData.product ? rowData.product.nameProduct : "non défini"
        return <p>{title}</p>
    }

    // STATUS BODY TEMPLATE
    const statusBodyTemplate = (rowData) => {
        return (
            <InputSwitch checked={rowData.active} 
            onChange={(e) => 
                OnStatusChanged(rowData._id,e.value)
            } />
        )
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
               <EditOffer updateOffer={updateOffer}  rowData={rowData}/>
               <DialogDeleteConfirmation rowData={rowData} deleteOffer={deleteOffer}/>
            </div>
        );
    }

    const DialogDeleteConfirmation = ({ rowData, deleteOffer }) => {

        const [dialogDeleteVisibility, setDialogDeleteVisibility] = useState(false);

        const onRemoveOfferClicked = async() => {
            await deleteOffer(rowData._id)
            hideDialogDelete()
        }
        const hideDialogDelete = () => setDialogDeleteVisibility(false);
        return (
            <>
            <Button
                onClick={() => setDialogDeleteVisibility(true)}
                icon="pi pi-trash"
                className="ml-2 p-button-danger"
                aria-label="filter"
            />
            <Dialog visible={dialogDeleteVisibility} 
            style={{ width: '450px' }} header="Confirmer" modal 
            footer={<DialogDeleteFooter 
                onRemoveOfferClicked={onRemoveOfferClicked} 
                hideDialogDelete={hideDialogDelete}
            />} 
            onHide={() => setDialogDeleteVisibility(false)}>
            <div className="flex align-items-center justify-content-center"> 
            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                <div className="flex flex-column align-items-start justify-content-center">
                    <span className='mt-2'>voulez-vous supprimer l'offre <b>{rowData.nameOffer}</b></span>
            </div>
            </div>
            </Dialog>
            </>
        )
  
    }
    const DialogDeleteFooter = ({ onRemoveOfferClicked, hideDialogDelete }) => {
        return (
          <>
            <Button label="non" icon="pi pi-times" className="p-button-text" onClick={hideDialogDelete} />
            <Button label="oui" icon="pi pi-check" className="p-button-text p-button-success" 
                    onClick={onRemoveOfferClicked} />
          </>
        )
    }


    const imageTemplate=(rowData)=>{
        return (
            <>
            {
                rowData.photo !== null
                ? <img src={rowData.photo} alt={rowData.photo} className="shadow-2" width="50" />
                : <b style={{color:'#f00'}}>aucune image</b>
            }
            </>   
        )
    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        setGlobalFilterValue(value);
    }

    const onRowReorder = async(e) => {
        setLoading(true)
        await offerSerive.reorderOffers(e.value);
        setOffers(e.value);
        setLoading(false)
        //toast.current.show({severity:'success', summary: 'Rows Reordered', life: 3000});
    }



    const renderHeader = () => {
        return (
            <div className='flex justify-content-between align-items-center w-full'>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Recherche" />
                </span>

            </div>
        );
    };

    const header = renderHeader()
    
    return (
    <div className="grid crud-demo">
            <div className="col-12">
                <div>

                <div className='mb-4 flex alignItems-center justify-content-between'>
                    <div className='flex align-items-center justify-content-center'>
                        <p className='text-2xl font-semibold'>Offres</p>
                    </div>
                    <AddOffer createOffer={createOffer} />
                </div>
                 
                <div className='card'>
                    <DataTable size='small' 
                    header={header}
                    stripedRows reorderableRows onRowReorder={onRowReorder}
                    ref={dt} 
                    value={offers} 
                    responsiveLayout="scroll"
                    loading={loading} 
                    rowHover
                    globalFilter={globalFilterValue} 
                    paginator
                    dataKey="_id" 
                    rows={25} 
                    rowsPerPageOptions={[5, 10, 25, 50]} 
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Afficher {first} à {last} de {totalRecords} offres"
                    emptyMessage="aucun offres trouvé">
                            <Column rowReorder style={{ width: '3rem' }} />
                            <Column sortable field="nameOffer" header="nom de l'offre"></Column>
                            <Column header="catégorie" body={categoryBodyTemplate}></Column>
                            <Column field={"brand"} header="brand" body={brandBodyTemplate}></Column>
                            <Column header="produit" body={productBodyTemplate}></Column>
                            <Column field="photo" header="image" body={imageTemplate}></Column>
                            <Column field="active" header="status" body={statusBodyTemplate}></Column>
                            <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>  
                </div>
            </div>
        </div>
  )
}

export default Offers
