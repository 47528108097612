import { Button } from 'primereact/button'
import React from 'react'

const InfoCard = () => {
  return (
    <div className="grid">
            <div className="col-12 ">
                <div className="infoCard card p-5 flex flex-column"> 
                    <p className='text-2xl font-bold text-50'>Nous vous invitons à nous contacter si vous souhaitez obtenir une mise à jour ou pour toute autre demande</p>
                    <div className="flex justify-content-between">
                        <p className='text-md text-50'>contact@devtime.tech</p>
                            <Button label='contacter nous' text raised style={{backgroundColor:'#fff',color:'#000'}}/>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default InfoCard