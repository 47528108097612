import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { FileUpload } from 'primereact/fileupload';
import { ImageService } from '../../service/ImageService';
import { v4 as uuidv4 } from 'uuid';
import CustomInput from '../CustomInput';
import CustomSwitch from '../CustomSwitch';

const AddGift = ({ createGift }) => {
    
    const [dialogVisibility, setDialogVisibility] = useState(false);
    const hideDialog = () => setDialogVisibility(false)
    const openNew = () => setDialogVisibility(true)

    const initialValues = {
        giftName: '',
        photos: [],
        quantityStock: '',
        pointRequirement: '',
        active: true,
    }
    
    const validationSchema = Yup.object().shape({
        giftName: Yup.string().required('nom de gift obligatoire'),
        photos: Yup.array().min(1,"photos obligatoire").required(),
        quantityStock: Yup.number().min(0).required("quantité obligatoire"),
        pointRequirement: Yup.number().min(1).required("points d'exigence obligatoire"),
        active: Yup.bool()
    })

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {chooseButton}
                {cancelButton}
            </div>
        );
    } 


    //when an image added
    const onTemplateSelect = (e, setFieldValue, photos) => {
        const objectURL = e.files[0]?.objectURL
        setFieldValue('photos',[...photos, objectURL])
    }

    //when an image removed
    const onTemplateRemove = (e, setFieldValue, photos) => {
        const newPhotos = photos.filter((p) => p != e.file.objectURL)
        setFieldValue('photos',[...newPhotos])

    }

    //when all images removed
    const onTemplateClear = (setFieldValue) => {
        setFieldValue('photos',[])
    }

    const onSubmit = async (values,actions) => {
        const { giftName, photos, quantityStock, pointRequirement, active} = values
        console.log("status: ",active)
        let photosUrls = []
        const imageService = new ImageService()
        for (let i=0; i < photos.length; i++) {
            const blob = await fetch(photos[i]).then(r => r.blob()); //get blob url
            const url_image = await imageService.uploadImage(blob,`gifts/${giftName}${uuidv4()}`) // upload to firebase and get url
            photosUrls.push(url_image.data)

        }
        const data = {
            giftName,
            photos: photosUrls,
            quantityStock,
            pointRequirement,
            active,
        }
        await createGift(data)
        hideDialog()
    }
    
    
    return(
        <>
        <Button 
        label="ajouter cadeau" 
        icon="pi pi-plus" 
        className="mr-2" 
        onClick={openNew} />
        
        {/* MODAL */}
        <Dialog draggable={false} visible={dialogVisibility} breakpoints={{'1900px': '60vw', '640px': '100vw'}}
            header="Ajouter nouveau cadeau" modal 
            className="p-fluid" onHide={hideDialog}>
            
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ handleChange,handleBlur, handleSubmit,isSubmitting, values, errors, touched, setFieldValue })=>{
                console.log(values.photos)
               
                const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
                const getFormErrorMessage = (name) => {
                    return isFormFieldValid(name) && <small className="p-error">{errors[name]}</small>;
                };
                return(
                <>
                {/* GIFT NAME */}
                <CustomInput
                    label='nom de cadeau'
                    placeholder='nom de cadeau'
                    handleChange={handleChange}
                    value={values.giftName}
                    name='giftName'
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                />

                {/* STOCK */}
                <CustomInput
                    label='Quantité stock'
                    placeholder='Quantité stock'
                    handleChange={handleChange}
                    value={values.quantityStock}
                    name='quantityStock'
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                    keyfilter='pnum'
                />
                {/* STOCK */}
                <CustomInput
                    label='Points'
                    placeholder='Points'
                    handleChange={handleChange}
                    value={values.pointRequirement}
                    name='pointRequirement'
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                    keyfilter='pnum'
                />

                {/*template image */}      
                <div>
                    <h5>Images</h5>
                    <FileUpload name="demo[]" url="https://primefaces.org/primereact/showcase/upload.php" 
                        headerTemplate={headerTemplate}
                        accept="image/*" 
                        maxFileSize={2000000}
                        customUpload
                        onSelect={(e) => onTemplateSelect(e, setFieldValue, values.photos)}
                        onError={(e) => onTemplateClear(setFieldValue)} 
                        onClear={(e) =>onTemplateClear(setFieldValue)} 
                        onRemove={(e) => onTemplateRemove(e, setFieldValue, values.photos)}
                        emptyTemplate={<p className="m-0">Faites glisser et déposez les images ici pour les télécharger.</p>} />
                {getFormErrorMessage('photos')}
                </div>


                {/* STATUS */}
                <div className='mb-3'>
                <CustomSwitch
                    label='status'
                    name='active'
                    active={values.active} 
                    handleChange={handleChange}/>
                </div>        
                
            
            <div className='mt-4 flex align-items-center justify-content-end'>
                <Button label="annuler" className='w-auto p-button-secondary p-button-sm p-button-outlined mr-2' icon="pi pi-times" 
                onClick={hideDialog}/>
                <Button onClick={handleSubmit} 
                label={isSubmitting?'loading':'sauvegarder'}
                disabled={isSubmitting}
                loading={isSubmitting}
                className='w-auto p-button-sm p-button-success' 
                icon="pi pi-check"  type='submit' />
            </div>
            </>
                )
            }}
            </Formik>
            
            </Dialog>
        </>
    )
}

export default AddGift