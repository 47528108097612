import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';


const MarkAsDelivered = ({ rowData, updateStatus }) => {
    const [dialogVisibility, setDialogVisibility] = useState(false);
    const [loading, setLoading] = useState(false)
    const hideDialog = () => setDialogVisibility(false)
    const openNew = () => setDialogVisibility(true)

    const onConfirmCicked = async () => {
        setLoading(true)
        const status = "LIVRÉ"
        await updateStatus(rowData._id, status)
        setLoading(false)
        hideDialog()
        
    }

    const updateDialogFooter = () => {
        return (
          <>
            <Button label="non" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button 
            loading={loading}
            disabled={loading}
            label="oui" 
            icon="pi pi-check" 
            className="p-button-text p-button-success" 
                    onClick={onConfirmCicked}/>
          </>
        )
    }

    return (
        <>
        <Button 
            label='marquer comme livré'
            style={{
                backgroundColor: "#02B172",
                borderColor: "#02B172"
            }}
            onClick={openNew}
        />
        <Dialog visible={dialogVisibility} style={{ width: '450px' }} header="Confirmer" modal 
        footer={updateDialogFooter} 
        onHide={hideDialog}>
        <div className="flex align-items-center justify-content-center"> 
        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
            <div className="flex flex-column align-items-start justify-content-center">
                <span className='mt-2'>Êtes-vous sûr de vouloir marquer ce cadeau commandé comme livré ?</span>
        </div>
        </div>
        </Dialog>
        </>
    )

}

export default MarkAsDelivered