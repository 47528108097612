import React, { useEffect, useRef, useState } from "react";
import BreadCrump from "../../components/BreadCrump";
import { Toast } from "primereact/toast";
import { AdminService } from "../../service/AdminService";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import CustomInput from "../../components/CustomInput";
import { Formik } from "formik";
import * as Yup from "yup";

function UpdateAdminPassword() {
  const { updatePassword } = new AdminService();
  const { id } = useParams();
  const navigate = useNavigate();

  const breadCrumpItems = {
    href: "admins",
    parentName: "admin",
    currentPage: "modifier mot de pass",
  };

  const toast = useRef(null);

  const initialValues = {
    password: "",
    repeatPassword: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("saisir le mot de passe")
      .min(8, "8 caractères"),
    repeatPassword: Yup.string()
      .required("saisir le mot de passe")
      .oneOf(
        [Yup.ref("password"), null],
        "les mots de passe ne correspondent pas"
      ),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const res = await updatePassword(id, values.password);
      if (res.data) {
        setSubmitting(false);
        navigate("/admins");
      } else {
        toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "ressayez plus tard",
        });
      }
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="flex flex-column sm:flex-row align-items-center justify-content-between">
        <BreadCrump breadCrumpItems={breadCrumpItems} />
      </div>

      <div className="card p-fluid grid">
        <div className="col-12 md:col-6">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              handleChange,
              handleSubmit,
              isSubmitting,
              values,
              errors,
              touched,
            }) => {
              const isFormFieldValid = (name) =>
                !!(touched[name] && errors[name]);
              const getFormErrorMessage = (name) => {
                return (
                  isFormFieldValid(name) && (
                    <small className="p-error">{errors[name]}</small>
                  )
                );
              };

              return (
                <>
                  <div className="col-12">
                    <CustomInput
                      label="mot de passe"
                      name="password"
                      placeholder={"mot de passe"}
                      handleChange={handleChange}
                      isFormFieldValid={isFormFieldValid}
                      getFormErrorMessage={getFormErrorMessage}
                      value={values.password}
                    />

                    <CustomInput
                      label="Répéter le mot de passe"
                      name="repeatPassword"
                      placeholder={"Répéter le mot de passe"}
                      handleChange={handleChange}
                      isFormFieldValid={isFormFieldValid}
                      getFormErrorMessage={getFormErrorMessage}
                      value={values.repeatPassword}
                    />
                  </div>

                  <div className="col-12">
                    <div className="mt-4 flex align-items-center justify-content-end">
                      <Button
                        onClick={handleSubmit}
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        label={isSubmitting ? "loading" : "modifier"}
                        className="w-auto p-button-sm p-button-info"
                        icon="pi pi-check"
                      />
                    </div>
                  </div>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default UpdateAdminPassword;
