import React,{ useState, useEffect, memo } from 'react'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { v4 as uuidv4 } from 'uuid';
import VariantsBlock from './VariantsBlock';
import { VariantService } from '../../../service/VariantService';
import { LoadingComponent } from '../../LoadingComponents';
const COLORS = require('../../../utils/Colors.json')

const OptionsBlock = ({
  deleteOptionsId,
  setDeletedOptionId,
  optionsLoading,
  deleteVariantsId,
  setDeletedVariantsId,
  productId,
  options,
  toast,
  setOptions,
  variants,
  setVariants,
  hasVariant,
  generatedVariants,
  setGeneratedVariants
  }) => {
  
  const initialValues = {
    optionName: '',
    optionValues:''
  }

  const [_values, setValues] = useState(initialValues); // OPTION NAME AND VALUES FOR INPUT
  const [addValuesToggle,setAddValuesToggle] = useState(null)
  const [selectedColor,setSelectedColor] = useState(null) // FOR COLOR DROPDOWN
  const [colors,setColors]=useState(COLORS)
  const [toggleDelete,setToggleDelete]=useState(false)

  // UPDATE OPTIONS VALUES QUANTITY STOCK WHEN OPTION VALUE IS DELETED
  useEffect(()=>{
    calculateTotalOptionValues()
  },[toggleDelete])

    // INPUT CHANGE FOR VALUE AND OPTION
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setValues({
        ..._values,
        [name]: value,
      });
    };

    // CHECK IF VALUES ARE DUPLICATED 
    const checkDuplicateValue=(valuesArray)=>{
      const removeSpaces = _values.optionValues.trim()
      const lowerCase = removeSpaces.toLocaleLowerCase()
      let duplicateFound = false
      const checkDuplicates = valuesArray.filter(option=>option.name === lowerCase)
      if (checkDuplicates.length > 0){
        duplicateFound = true
      }
      return duplicateFound
    }

    
    // CALCULATE OPTION TOTAL QUANTITY
    const calculateTotalOptionValues=()=>{
      let newArr=[...options]

      for(let i in newArr){
        const vl = newArr[i].values
        for(let j in vl){
              vl[j].quantityStock = 0
        }
      }

      let indexVal
      variants.map((_var,index)=>{
          if(_var.option1){
              indexVal =  newArr[0].values.findIndex((v) => v.name === _var.option1.value)
              if(indexVal !== -1 ){
                  newArr[0].values[indexVal].quantityStock += Number(_var.quantityStock)
              }
          }
          if(_var.option2){
              indexVal =  newArr[1].values.findIndex((v) => v.name === _var.option2.value)
              if(indexVal !== -1 ){
              newArr[1].values[indexVal].quantityStock += Number(_var.quantityStock)
              }
          }
      })
      setOptions(newArr)
    }

    console.log('toggleDelete',toggleDelete)
    
    
    // REMOVE VALUE FROM VALUES ARRAY
    const spliceOptionValue=(optionId,valueName,index)=>{
      const newArr = findOption(optionId)
      if(newArr !== -1){
        newArr.values.splice(index,1)
        spliceVariantsByOptionValue(valueName)
        setToggleDelete(!toggleDelete)
      }   

    }

    // DELETE ALL VARIANTES RELATED TO DELETED OPTION VALUE
    const spliceVariantsByOptionValue=(value)=>{
      const newArr = [...deleteVariantsId]
      const dbVariantsId=[...deleteVariantsId]

      let arr = []
        for(let i in variants){
          if(variants[i].option1 !== null && variants[i].option1.value === value){
            arr.push(variants[i]._id)
            newArr.push(variants[i]._id)
            if(!variants[i].new){
              dbVariantsId.push(variants[i]._id)
            }
          }
          if(variants[i].option2 !== null && variants[i].option2.value === value){
            arr.push(variants[i]._id)
            newArr.push(variants[i]._id)
            if(!variants[i].new){
              dbVariantsId.push(variants[i]._id)
            }
          }
          if(variants[i].option3 !== null && variants[i].option3.value === value){
            arr.push(variants[i]._id)
            newArr.push(variants[i]._id)
            if(!variants[i].new){
              dbVariantsId.push(variants[i]._id)
            }
          }
      }
      setDeletedVariantsId(dbVariantsId)
      filterVriantsByOption(variants,arr)
    }
    
    // FILTER VARIANTS LIST BY OPTION
    const filterVriantsByOption = (variants, valuesArray) => {
      let res = [];
      res = variants.filter(el => !valuesArray.find(element => element === el._id));
      setVariants(res)
   }

   // REMOVE OPTION
    const spliceOptions=(_id,index)=>{
      
      let deletedArr = [...deleteOptionsId]
      deletedArr.push(_id)
      setDeletedOptionId(deletedArr)

      let arr = [...options]
      arr.splice(index,1)

      for(let i in arr){
        const vl = arr[i].values
        for(let j in vl){
              vl[j].quantityStock = 0
        }
      }

      variantsArray(arr)

    }

   // STRUCTURE VARIANT ARRAY
   const variantsArray=(optionsArray)=>{
        const _variants = [];
        const generateVariant = (currentOption, currentVariant) => {
          if (currentOption === optionsArray.length) {
          _variants.push(currentVariant);
          return;
        }
        for (let i = 0; i < optionsArray[currentOption].values.length; i++) {
        const newVariant = { ...currentVariant };
        switch (currentOption) {
            case 0:
            newVariant.option1 = { optionId: '', value: optionsArray[currentOption].values[i].name };
            break;
            case 1:
            newVariant.option2 = { optionId: '', value: optionsArray[currentOption].values[i].name };
            break;
            case 2:
            newVariant.option3 = { optionId: '', value: optionsArray[currentOption].values[i].name };
            break;
        }
          generateVariant(currentOption + 1, newVariant);
        }

      };
    
        generateVariant(0, {
            id: 0,
            option1: null,
            option2: null,
            option3: null,
            reference: 'null',
            quantityStock: 0,
            minOrderQuantity: 1,
            new:true,
            position:0,
            priceProduct: 0,
            discountPrice: 0
        });
        
        setOptions(optionsArray)
        pushIdToVariantrs(_variants);
    }

    // PUSH UUID TO VARIANTS
    const pushIdToVariantrs=(vrts) => {
      let newArr=[...vrts]
      for(let variant in vrts){
         newArr[variant]._id = uuidv4()
      }
      setVariants(newArr)
  }

    // FIND OPTION BY ID
    const findOption=(optionId)=>{
      const newArr = options.find(opt => opt._id === optionId)
      return newArr 
    }

    // PUSH COLOR VALUE TO VALUES ARRAY
    const addValueOption=(optionId)=>{
      const removeSpaces = _values.optionValues.trim()
      const lowerCase = removeSpaces.toLocaleLowerCase()
      const val = { name: lowerCase, quantityStock: 0 }
      const newArr = findOption(optionId)
      if(newArr !== -1){
        if(!checkDuplicateValue(newArr.values)){
          newArr.values.push(val)
          setValues({..._values,optionValues:''})
          setSelectedColor(null)
          compareArrays()
        }
      }
    }
    
    // PUSH VALUE TO VALUES ARRAY
    const pushNewValues=(optionId)=>{
      const removeSpaces = _values.optionValues.trim()
      const lowerCase = removeSpaces.toLocaleLowerCase()
      const val = { name: lowerCase, quantityStock: 0 }
      const newArr = findOption(optionId)
      if(newArr !== -1){
        if(!checkDuplicateValue(newArr.values)){
          newArr.values.push(val)
          setValues({..._values,optionValues:''})
          compareArrays()
        }
      }
    }

    const compareArrays=()=>{
      const newVariants = generateVariants(options)
      const arr = []
      const oldV = []

      function isEqual(obj1, obj2) {
        return obj1?.value === obj2?.value
      }

      for(let i in newVariants){
        let op1, op2, op3
        let match = false
        for(let j in variants){
            op1 = isEqual(newVariants[i].option1 , variants[j].option1)
            op2 = isEqual(newVariants[i].option2 , variants[j].option2)
            op3 = isEqual(newVariants[i].option3 , variants[j].option3)
            if(op1 && op2 && op3){
              match = true
            }
        }
        if(!match){
          arr.push(newVariants[i])
        }else{
          oldV.push(variants[i])
        }
      }
      
      const totalArray = [...variants, ...arr]
      const arr2 = [...generatedVariants, ...arr]
      setGeneratedVariants(arr2)
      setVariants(totalArray)
    }

    // GENERATE NEW VARIANTS ARRAY WHEN NEW VALUE IN ADDED TO OPTION
    const generateVariants=(optionsArray)=>{
      const _variants = [];
      const generateVariant = (currentOption, currentVariant) => {
        if (currentOption === optionsArray.length) {
        _variants.push(currentVariant);
        return;
      }
      for (let i = 0; i < optionsArray[currentOption].values.length; i++) {
      const newVariant = { ...currentVariant };
      newVariant._id = uuidv4()
      switch (currentOption) {
          case 0:
          newVariant.option1 = { optionId: '', value: optionsArray[currentOption].values[i].name };
          break;
          case 1:
          newVariant.option2 = { optionId: '', value: optionsArray[currentOption].values[i].name };
          break;
          case 2:
          newVariant.option3 = { optionId: '', value: optionsArray[currentOption].values[i].name };
          break;
      }
        generateVariant(currentOption + 1, newVariant);
      }

    };
  
      generateVariant(0, {
          id: 0,
          option1: null,
          option2: null,
          option3: null,
          reference: 'null',
          quantityStock: 0,
          minOrderQuantity: 1,
          new:true,
          position:0,
          priceProduct: 0,
          discountPrice: 0
      });
      
      
      return _variants
      
    }
  
    // SHOW EDIT VALUES FORM
    const toggleValuesEdit=(id,nameOption)=>{
      setAddValuesToggle(id)
    }

    const handleColorChange=(e)=>{
      setValues({
        ..._values,
        optionValues: e.target.value.hex,
      })
      setSelectedColor(e.value)
    }

    // DROPDOWN SELECTED VALUE TEMPLATE
    const selectedColorTemplate=(option,props)=>{
        if (option) {
          return (
              <div className='flex'>
                <div className='mr-3' style={{height:20,width:20,borderRadius:10,backgroundColor:option.hex}}></div>
                  <div>{option.name}</div>
              </div>
          );
      }
      return (
          <span>
            {props.placeholder}
          </span>
      );
      
    }
  
  // COLOR DROPDOWN TEMPLATE
    const itemTemplate = (item) => {
      return (
          <div className="country-item flex">
            <div style={{height:20,width:20,backgroundColor:item.hex,borderRadius:10}} className='mr-2'/>
            <div>{item.name}</div>
          </div>
      );
    }

  if(optionsLoading){
    return <LoadingComponent options={'Options'}/>
  }

  return (
    <>
    <div className='card col-12'>
    <p className='font-bold mb-4'>Options</p>

    {hasVariant
      &&<>
      {/* LIST OPTION ARRAY */}
      { 
        options.map(({_id,nameOption,values},index)=>{
          const checkHex = values.length > 0 && values[0].name.charAt(0)
           return( 
          <div key={_id} >
           {values.length > 0
           && 
           <div 
              style={{border:'1px solid #eee',borderRadius:'5px'}} 
              className='p-2 mb-2 flex align-items-center'>
              
              <div  className='flex flex-column w-full'>
              <div className='flex justify-content-between align-items-start'>
              <div><p className='font-bold'>{nameOption}</p></div>
                <div className='flex' >
                {/* {options.length > 1 
                &&<Button 
                onClick={()=>spliceOptions(_id,index)}
                  icon="pi pi-trash" 
                  className="p-button-rounded p-button-secondary p-button-text" 
                  aria-label="filter" />} */}
                <Button 
                  onClick={()=>toggleValuesEdit(_id,nameOption)}
                  icon="pi pi-pencil" 
                  className="p-button-rounded p-button-secondary p-button-text" 
                  aria-label="filter" />
                </div>
              </div>
              

              <div className='flex flex-wrap'>
              {
                values.map(({name},index)=>(
                  <div  key={index.toString()} className='mr-1'> 
                    { checkHex === '#'
                    ? 
                    <div style={{backgroundColor:'#DEE2E6',borderRadius:'20px'}} 
                    className='flex align-items-center justify-content-center mr-2 mb-2 px-2 py-2'>
                    
                    <div style={{backgroundColor:name,height:20,width:20,borderRadius:10}} />        
                    
                    {values.length > 1
                    &&<div className='ml-3' style={{cursor:'pointer'}} onClick={()=>spliceOptionValue(_id,name,index)} >
                      <i className='pi pi-times-circle' style={{color:'#838584',fontSize:'18px'}}></i>
                    </div>}

                    </div>
                    : 
                    <div style={{backgroundColor:'#DEE2E6',borderRadius:'20px'}} 
                    className='flex align-items-center justify-content-center mr-2 mb-2 px-2 py-2'>
                    <div>
                      <p className='font-normal text-sm' style={{color:'#1f1f1f'}}>{name}</p>
                    </div>

                    {values.length > 1
                    &&<div className='ml-3' style={{cursor:'pointer'}} onClick={()=>spliceOptionValue(_id,name,index)} >
                      <i className='pi pi-times-circle' style={{color:'#838584',fontSize:'18px'}}></i>
                    </div>}

                    </div>
                    } 
                  </div>
                ))
              }

              </div>

            {/* ADD OPTION VALUES */}
            { addValuesToggle === _id
              &&<div className='mt-2 flex flex-column'>
                <p className='mb-2'>ajouter nouveau valeur</p>
                <div className='flex w-full' >
                  <div className='flex w-full flex-column'>
                    {
                    nameOption === 'couleur'
                    ? 
                    <div className='flex'>
                    <Dropdown 
                    value={selectedColor} 
                    onChange={handleColorChange}
                    itemTemplate={itemTemplate}
                    valueTemplate={selectedColorTemplate}
                    options={colors} 
                    name='optionValues'
                    optionLabel="name" 
                    className='w-full'
                    placeholder="selectionnez un couleur" />
                    
                    <Button
                        disabled={_values.optionValues === ''} 
                        onClick={()=>addValueOption(_id)}
                        icon="pi pi-plus" 
                        className='ml-2' 
                        aria-label="filter" />
                    
                    </div>
                    :
                    <div className='flex'>
                    <InputText 
                    onChange={handleInputChange}
                    name='optionValues'
                    value={_values.optionValues}
                    placeholder='valeur'
                    className={`w-full ${classNames({ 'p-invalid':  checkDuplicateValue(values)})}`} />
                    
                    <div>
                    <Button
                        disabled={_values.optionValues === ""} 
                        onClick={()=>pushNewValues(_id)}
                        icon="pi pi-plus" 
                        className='ml-2' 
                        aria-label="filter" />
                    </div>
                    </div>
                    
                    }
                    
                    { checkDuplicateValue(values) 
                    && <div className='mt-2 flex align-items-center justify-content-start'>
                      <i className='mr-1 pi pi-info-circle' style={{color:'#f00'}}></i>
                      <p style={{color:'#f00'}}>{`vous avez déjà utilisé la valeur (${_values.optionValues}) pour cette option`}</p>
                    </div>}

                  </div>
                
                </div>
            </div>}
            {/* END ADD VALUES */}

            </div>   

            </div>
            }
          </div>
          )
          
        }) 
      }
      </>
    }

    </div>
    <VariantsBlock 
    toast={toast} 
    productId={productId} 
    variants={variants} 
    setVariants={setVariants} 
    options={options}
    setOptions={setOptions} />
    </>
  )
}

export default memo(OptionsBlock)