import React, { useEffect, useRef, useState } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { FamilleService } from '../service/FamilleService';
import NoData from '../components/NoData';
import UpdateFamily from '../components/family/UpdateFamily';
import AddFamily from '../components/family/AddFamily';
import OptionsMenu from '../components/OptionsMenu';
import SingleDelete from '../components/SingleDelete';

const DiscountCustomer = () => {

    const toast = useRef(null);
    const dt = useRef(null);
    const [data,setData] = useState({types:[],loading:true});
    const [toggleMenu, setToggleMenu] = useState(null); // toggle menu state
    const familleService = new FamilleService();

    const getData=async()=>{
        const res = await familleService.getFamilyTypes()
        if(res.data){
            setData({types:res.data,loading:false});
            setToggleMenu(null)
        }else{
            setData({loading:false});
            console.log(res.error)
        }
    }

    useEffect(()=>{
        getData()
    },[])


    // UPDATE FAMILY
    const updateFamily=async(id,data)=>{
        const res = await familleService.updateFamilyType(id,data)
        if(res.data){
            getData()
        }else{
            console.log(res.error)
        }
    }

    // CREAT FAMILY
    const createFamily=async(data)=>{
        const res = await familleService.createFamilyType(data)
        if(res.data){
            getData()
        }else{
            console.log(res.error)
        }
    }

    // DELETE FAMILY
    const deleteFamily=async(id)=>{
        const res = await familleService.deleteFamilyTypes(id)
        if(res.data){
            getData()
        }else{
            console.log(res.error)
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <OptionsMenu
                rowData={rowData} 
                setToggleMenu={setToggleMenu} 
                toggleMenu={toggleMenu}>
            {
            rowData._id === toggleMenu 
              && <div className='pb-2 pt-2 options-menu'>
                    <UpdateFamily updateFamily={updateFamily} rowData={rowData} />
                    <SingleDelete id={rowData?._id} name={rowData?.nameFamily} deleteFunction={()=>deleteFamily(rowData._id)} setToggleMenu={setToggleMenu}/>
              </div>
            }
          </OptionsMenu>
        );
    }


    return (
        <div className="grid crud-demo">
            <Toast ref={toast} />
                <div className="col-12">
    
                <div>
    
                    <div className='mb-4 flex alignItems-center justify-content-between'>
                        <div className='flex align-items-center justify-content-center'>
                            <p className='text-2xl font-semibold'>Famille de remise</p>
                        </div>
                        <AddFamily createFamily={createFamily}/>
                    </div>
                    
                    <div className="card">
                        <DataTable 
                        size='small' 
                        stripedRows ref={dt} 
                        value={data.types} 
                        responsiveLayout="scroll"
                        loading={data.loading} 
                        rowHover 
                        dataKey="_id" 
                        rows={10} 
                        className="datatable-responsive"
                        emptyMessage={<NoData label='Aucune famille disponible' />}>
                                <Column field="nameFamily" header="nom famille"></Column>
                                <Column body={actionBodyTemplate}></Column>
                        </DataTable>
                    </div>  
                    </div>
                </div>
        </div>
      )
}

export default DiscountCustomer