import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { GiftService } from '../service/GiftService';
//components
import NoData from '../components/NoData';
import ImageTemplate from '../components/orders/ImageTemplate';
import AddGift from '../components/Gifts/AddGift';
import EditGift from '../components/Gifts/EditGift';
import PointsRules from '../components/Gifts/PointsRules';

const Gifts = () => {
    const giftService = new GiftService()
    const [gifts, setGifts] = useState(null);
    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const dt = useRef(null);


    async function getData(){
        setLoading(true)
        const response = await giftService.getGifts()
        if(response.data){
            setGifts(response.data)
        } else {
            console.log(response.error);
        }
        setLoading(false)

    }

    async function createGift(data){
        const response = await giftService.createGift(data)
        if(response.data){
            getData()
        } else {
            console.log(response.error);
        }
    }

    async function updateGift(id, data){
      const response = await giftService.updateGift(id, data)
      if(response.data){
          getData()
      } else {
          console.log(response.error);
      }
  }


    useEffect(() => {
        getData()
    }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Recherche" />
                </span>
            </div>
        );
    };

    const statusBodyTemplate = (rowData) => {
        const CustomBadge = (bg, status) => {
          return (
            <div
              style={{ backgroundColor: bg, borderRadius: 10 }}
              className="px-2 w-max"
            >
              <p className="text-sm">{status}</p>
            </div>
          );
        };
        return (
          <>
            {rowData.active ? (
              <>{CustomBadge("#A1D8C1", "Actif")}</>
            ) : (
              <>{CustomBadge("#d8a1a1", "Inactif")}</>
            )}
          </>
        );
      };


    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
               <EditGift updateGift={updateGift} giftData={rowData}/>
            </div>
        );
    }

    

    const header = renderHeader()

    return (
    <div className="grid crud-demo">
            <div className="col-12">
                <div>

                <div className='mb-4 flex alignItems-center justify-content-between'>
                    <div className='flex align-items-center justify-content-center'>
                        <p className='text-2xl font-semibold'>Cadeaux</p>
                    </div>
                    <PointsRules />
                    <AddGift createGift={createGift}  />
                </div>

                <div className='card'>
                    <DataTable size='small' 
                    header={header}
                    stripedRows 
                    ref={dt} 
                    value={gifts} 
                    responsiveLayout="scroll"
                    loading={loading} 
                    rowHover
                    paginator
                    dataKey="id" 
                    globalFilter={globalFilterValue} 
                    rows={25} 
                    rowsPerPageOptions={[5, 10, 25, 50]} 
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Afficher {first} à {last} de {totalRecords} cadeaux"
                    emptyMessage={<NoData label='Aucun cadeau disponible'/>}>
                            <Column sortable field="giftName" header="nom"/>
                            <Column sortable field="quantityStock" header="quantité"/>
                            <Column sortable field="pointRequirement" header="points d'exigence"/>
                            <Column field="photos" header="image" body={(rowData)=><ImageTemplate photos={[rowData?.photos[0]]}/>}></Column>
                            <Column sortable field="active" header="status" body={statusBodyTemplate}></Column>
                            <Column body={actionBodyTemplate} />
                    </DataTable>
                </div>  
                </div>
            </div>
        </div>
  )
}

export default Gifts