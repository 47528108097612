import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { signout } from "../utils/SignOut";
import config from "../config/app.config.json";
import { UserContext } from "../context";

export const AppTopbar = (props) => {
  const { userInfo } = useContext(UserContext);

  return (
    <div className="layout-topbar custom-top-bar">
      <button
        type="button"
        className="p-link  layout-menu-button layout-topbar-button"
        onClick={props.onToggleMenuClick}
      >
        <i className="pi pi-bars" />
      </button>

      <div className="layout-topbar-logo flex">
        <Link to="/">
          <img src={config.logo} alt="logo" />
        </Link>
        <div className="ml-3 flex flex-column">
          <span className="font-medium">{config.app_name}</span>
          <p className="text-base">{`${userInfo?.name}`}</p>
        </div>
      </div>

      <button
        type="button"
        className="p-link layout-topbar-menu-button layout-topbar-button"
        onClick={props.onMobileTopbarMenuClick}
      >
        <i className="pi pi-ellipsis-v" />
      </button>

      <ul
        className={classNames("layout-topbar-menu lg:flex origin-top", {
          "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive,
        })}
      >
        <li>
          <button className="p-link layout-topbar-button" onClick={signout}>
            <i className="pi pi-sign-out" />
            <span>se deconnecter</span>
          </button>
        </li>
      </ul>
    </div>
  );
};
