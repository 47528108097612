export const generateErrorMessage = (error) => {
    let errorMessage = ""
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        errorMessage = error.response.data.message;
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        errorMessage = "Internal server error"
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
        errorMessage = "Error sending request"
    }
    return errorMessage;
      
}