import React, { useState, useEffect, useRef } from 'react';
import AppLayout from './components/AppLayout';
import { UserProvider } from './context';
import { ToastContainer, toast } from 'react-toastify';
import { RecoilRoot } from "recoil";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import '../src/css/global.css'
import 'react-toastify/dist/ReactToastify.css';



const App = () => {
    return (
        <RecoilRoot>
            <UserProvider>
                <AppLayout/>
                <ToastContainer />
            </UserProvider>
        </RecoilRoot>
    );

}

export default App;
