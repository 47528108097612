import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils';
import { Formik } from 'formik';
import * as Yup from 'yup'
import AddButton from '../buttons/AddButton'
import RemoveButton from '../buttons/RemoveButton'

const Emails = ({emails, addEstablishmentInfo, deleteEstablishmentInfo}) => {
   
   
    const initialValues = {
        emailValue: ''
    }

    const validationSchema = Yup.object().shape({
        emailValue: Yup.string().email().required('champ obligatoire')
    })

    const onSubmit=async(values,actions)=>{
        // console.log(values.emailValue)
        await addEstablishmentInfo({email: values.emailValue})
        actions.resetForm()
        actions.setSubmitting(false)
    }

   const onRemoveClicked = async (email) => {
    await deleteEstablishmentInfo({email: email})
   }

  return (
    <div className="col-12 lg:col-6 xl:col-6">
        <div className="card p-fluid">
        <p className='mb-4 font-semibold'>e-mails pour la notification des commandes</p>
        {
        emails &&
        <div className='w-full'>
        {emails.map((email,index) => (
        <div key={index} className='flex mb-1 w-full align-items-center justify-content-between'>
            <div className='flex align-items-center'>
                <i className='pi pi-envelope'></i>
                <div className='ml-2'> <p>{email}</p> </div>
            </div>
            <RemoveButton onRemoveClicked={onRemoveClicked} email={email} />
        </div>
        ))}
        </div>
        }
        
        
        <Formik 
            initialValues={initialValues} 
            validationSchema={validationSchema} 
            onSubmit={onSubmit}>
            {({ handleChange,
                handleBlur, 
                handleSubmit,
                isSubmitting, 
                values, 
                errors, 
                touched 
            })=>{
               
                const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
                const getFormErrorMessage = (name) => {
                    return isFormFieldValid(name) && <small className="p-error">{errors[name]}</small>;
                };

               return (
               <div className='mt-2'>
                <div className='flex align-items-center'>
                    <InputText 
                    type={'email'}
                    placeholder="numéro de support" 
                    onChange={handleChange('emailValue')}
                    onBlur={handleBlur('emailValue')}
                    className={classNames({ 'p-invalid':  isFormFieldValid('emailValue')})}
                    />
                    <Button
                        icon="pi pi-plus" 
                        className="ml-2"
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                        />
                </div>
                {getFormErrorMessage('emailValue')}
                    {/* <AddButton onAddClicked={onAddClicked} setPhoneValue={setPhoneValue} /> */}
                </div>
                )}}
        </Formik>

        </div>
    </div>
  )
}

export default Emails