import { ModulesNames } from "./Consts";

export const sideMenu = [
  {
    label: "Acceuil",
    items: [
      {
        label: "Tableau de bord",
        module: ModulesNames.statistics,
        icon: "pi pi-fw pi-home",
        to: `/`,
      },
    ],
  },
  {
    label: "Produits",
    items: [
      {
        label: "Catégories",
        module: ModulesNames.categories,
        icon: "pi pi-fw pi-th-large",
        to: `/${ModulesNames.categories}`,
      },
      {
        label: "Marques",
        module: ModulesNames.brands,
        icon: "pi pi-fw pi-filter",
        to: `/${ModulesNames.brands}`,
      },
      {
        label: "Produits",
        module: ModulesNames.products,
        icon: "pi pi-fw pi-box",
        to: `/${ModulesNames.products}`,
      },
      {
        label: "Offres",
        module: ModulesNames.offers,
        icon: "pi pi-fw pi-bolt",
        to: `/${ModulesNames.offers}`,
      },
    ],
  },
  {
    label: "Fidelisation clients",
    items: [
      {
        label: "Cadeaux et points",
        module: ModulesNames.gifts,
        icon: "pi pi-fw pi-circle",
        to: `/${ModulesNames.gifts}`,
      },
      {
        label: "Cadeaux commandés",
        module: ModulesNames.redeemGifts,
        icon: "pi pi-fw pi-money-bill",
        to: `/${ModulesNames.redeemGifts}`,
      },
    ],
  },
  {
    label: "Commandes",
    items: [
      {
        label: "Commandes",
        module: ModulesNames.orders,
        icon: "pi pi-fw pi-money-bill",
        to: `/${ModulesNames.orders}`,
      },
      // {
      //   label: "Expéditions",
      //   module: ModulesNames.expeditions,
      //   icon: "pi pi-fw pi-truck",
      //   to: `/${ModulesNames.expeditions}`,
      // },
    ],
  },
  {
    label: "Livraison",
    items: [
      {
        label: "Livreurs",
        module: ModulesNames.deliveryMen,
        icon: "pi pi-fw pi-car",
        to: `/${ModulesNames.deliveryMen}`,
      },
    ],
  },
  {
    label: "Utilisateurs",
    items: [
      {
        label: "Utilisateurs",
        module: ModulesNames.customers,
        icon: "pi pi-fw pi-users",
        to: `/${ModulesNames.customers}`,
      },
      {
        label: "Types",
        module: ModulesNames.types,
        icon: "pi pi-fw pi-sitemap",
        to: `/${ModulesNames.types}`,
      },
      {
        label: "Famille client",
        module: ModulesNames.famillies,
        icon: "pi pi-fw pi-th-large",
        to: `/${ModulesNames.famillies}`,
      },
      {
        label: "propositions",
        module: ModulesNames.proposals,
        icon: "pi pi-fw pi-phone",
        to: `/${ModulesNames.proposals}`,
      },
    ],
  },
  {
    label: "Admin",
    items: [
      {
        label: "Admins",
        module: ModulesNames.admins,
        icon: "pi pi-fw pi-users",
        to: `/${ModulesNames.admins}`,
      },
    ],
  },
  {
    label: "paramètres",
    items: [
      {
        label: "notification",
        module: ModulesNames.notifications,
        icon: "pi pi-bell",
        to: `/${ModulesNames.notifications}`,
      },
      {
        label: "paramètres",
        module: ModulesNames.setting,
        icon: "pi pi-fw pi-cog",
        to: `/${ModulesNames.setting}`,
      },
    ],
  },
];
